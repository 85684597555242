import React, { useContext } from "react";
import "./incidentActivity.scss";
import IncidentActivityHeader from "./incidentActivityHeader";
import IncidentActivityTimeline from "./incidentActivityTimeline";
import IncidentActivityAddComments from "./incidentActivityAddComments";
import useIncidentActivityTimeline from "./useIncidentActivityTimeline";
import Tabs, { Tab } from "../../components/tabs/tabs";
import {
  timelineCommentsMapper,
  timelineEventsMapper
} from "../../mappers/incidentDetailsMappers";
import { IEvent } from "../../event/event.types";
import SessionContext from "../../contexts/session.context";

export default function IncidentActivity() {
  const {
    timeline,
    handleNewComment,
    handleDeleteComment,
    handleEditComment,
    handleNewEvent,
    loadEvents,
    loadComments,
    isLoadingEvents,
    isLoadingComments,
    endOfEventsPages,
    endOfCommentsPages
  } = useIncidentActivityTimeline();

  const onNewComment = (value: string) => handleNewComment(value);
  const onDeleteComment = (commentId: string) => handleDeleteComment(commentId);
  const onEditComment = (commentId: string, comment: string) =>
    handleEditComment(comment, commentId);
  const onNewEvent = (event: IEvent) => handleNewEvent(event);
  const { isMdrRole } = useContext(SessionContext);

  return (
    <div className="incidentActivity-container">
      <Tabs>
        <Tab title="Activity">
          {isMdrRole && <IncidentActivityHeader onAddEvent={onNewEvent} />}
          <IncidentActivityTimeline
            timeline={timelineEventsMapper(timeline)}
            loadData={loadEvents}
            isLoading={isLoadingEvents}
            endOfPages={endOfEventsPages}
          />
        </Tab>
        <Tab title="Comments">
          <IncidentActivityAddComments onNewComment={onNewComment} />
          <IncidentActivityTimeline
            timeline={timelineCommentsMapper(timeline)}
            deleteComment={onDeleteComment}
            editComment={onEditComment}
            loadData={loadComments}
            isLoading={isLoadingComments}
            endOfPages={endOfCommentsPages}
          />
        </Tab>
      </Tabs>
    </div>
  );
}
