import React from "react";
import "./loginAnimation.scss";
import { LOGO_ICON } from "../../constants/common.constants";
import Loader from "../loader/loader";

export default function LoginAnimation({
  statusMessage
}: {
  statusMessage: string;
}) {
  return (
    <div className="loginAnimationContainer">
      <img src={LOGO_ICON} alt="Soteria Defense MDR" />
      <section className="loginAnimationInfo">
        <p className="loginAnimationStatus">{statusMessage}</p>
        <div className="loader-container">
          <Loader />
        </div>
      </section>
    </div>
  );
}
