import { useDelete, useGet, usePatch, usePost } from "./api";

export function useGetComments() {
  const getApi = useGet();

  async function run(incidentId: string, nextPageToken: string) {
    const endpoint = `${
      process.env.REACT_APP_API_COMMENTS
    }/incidents/${incidentId}/comments${
      nextPageToken ? `?nextpagetoken=${nextPageToken}` : ""
    }`;
    getApi.setEndpoint(endpoint);
    return getApi.runReturnResponse(getApi.getOptions());
  }

  return run;
}

export function useAddComment() {
  const postApi = usePost();

  async function run(incidentId: string, comment: string) {
    postApi.setEndpoint(
      `${process.env.REACT_APP_API_COMMENTS}/incidents/${incidentId}/comments`
    );
    postApi.setBody(comment);
    return postApi.run(postApi.getOptions());
  }

  return run;
}

export function useEditComment() {
  const patchApi = usePatch();

  async function run(incidentId: string, commentId: string, comment: string) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_COMMENTS}/incidents/${incidentId}/comments/${commentId}`
    );
    patchApi.setBody({ comment });
    return patchApi.run(patchApi.getOptions());
  }

  return run;
}

export function useDeleteComment() {
  const deleteApi = useDelete();

  async function run(incidentId: string, commentId: string) {
    deleteApi.setEndpoint(
      `${process.env.REACT_APP_API_COMMENTS}/incidents/${incidentId}/comments/${commentId}`
    );
    return deleteApi.run(deleteApi.getOptions());
  }

  return run;
}
