import { useSelector } from "react-redux";
import {
  useDeleteAnalystNotes,
  useUpdateAnalystNotes,
  useUpdateEvent
} from "../api/events";

export default function useEvent(id: string) {
  const localUser = useSelector((state: any) => state.user.profile);
  const update = useUpdateEvent();
  const updateAnalystNotesAPI = useUpdateAnalystNotes();
  const deleteAnalystNotesAPI = useDeleteAnalystNotes();

  const bookmark = async (isRelevant: boolean) => {
    const body = {
      is_relevant: `${isRelevant}`
    };

    // eslint-disable-next-line no-return-await
    return await update(id, body);
  };

  const updateAnalystNotes = async (notes: string) => {
    const body = {
      note: notes,
      user_id: localUser.id
    };

    // eslint-disable-next-line no-return-await
    return await updateAnalystNotesAPI(id, body);
  };

  // eslint-disable-next-line no-return-await
  const deleteAnalystNotes = async () => await deleteAnalystNotesAPI(id);

  return {
    bookmark,
    updateAnalystNotes,
    deleteAnalystNotes
  };
}
