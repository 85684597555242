import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginAnimation from "../components/loginAnimation/loginAnimation";

export default function Logout() {
  const { logout } = useAuth0();

  useEffect(
    (): any => logout({ logoutParams: { returnTo: window.location.origin } }),
    [logout]
  );

  return <LoginAnimation statusMessage="" />;
}
