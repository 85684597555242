import { IMenuItem } from "../components/menu/menu";
import { CLIENT_WORD, PROVIDER_WORD } from "../constants/common.constants";
import { IAnyPropertyNameAndStringValue, IRole } from "../types/common.types";

/* eslint-disable import/prefer-default-export */

export const rolesSelectMapper = (
  roles: IAnyPropertyNameAndStringValue
): IMenuItem[] => {
  const result: IMenuItem[] = [];
  Object.keys(roles).forEach((key: string) =>
    result.push({ id: key, value: roles[key] })
  );
  return result;
};

export const rolesMapper = (
  roles: IRole[],
  firstUser: boolean
): IAnyPropertyNameAndStringValue => {
  const result: IAnyPropertyNameAndStringValue = {};
  roles
    .filter((role: IRole) => !firstUser || role.name.includes("Admin"))
    .forEach(({ id, name }) => {
      result[id] = name;
    });
  return result;
};

export const roleMapper = (role: string) =>
  role ? role.replace(PROVIDER_WORD, CLIENT_WORD) : "";
