/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from "react";
import "./input-login.scss";

type IProps = {
  value?: string | number;
  onChange?: any;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  name?: string;
  error?: string;
  touched?: boolean;
  field?: any;
  form?: any;
};

export default function InputLogin(props: IProps) {
  const {
    value,
    onChange,
    disabled,
    placeholder,
    type = "text",
    name,
    error = "",
    touched = false,
    field,
    form
  } = props;

  const hasError = useMemo(() => touched && !!error, [error, touched]);

  const inputContainerClass = useMemo(
    () => `input-container${hasError ? " error" : ""}`,
    [hasError]
  );

  return (
    <div className={inputContainerClass}>
      <input
        name={name || field?.name}
        type={type}
        value={value}
        className={`input${!error ? " valid" : ""}`}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        autoComplete="username"
        onBlur={() => !!form && form.setFieldTouched(field?.name, true)}
      />
      <label className="placeholder">{placeholder}</label>
      <label className="error">{error}</label>
    </div>
  );
}
