import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteUser as useDeleteUserAPI } from "../../api/users";
import {
  DELETE_ICON,
  ERROR_ICON,
  MESSAGE
} from "../../constants/common.constants";
import ModalContext from "../../contexts/modal.context";
import SnackbarContext from "../../contexts/snackbar.context";
import { USERS_URL } from "../../constants/urls.constants";
import { buildUrl } from "../../utils/string.utils";

export default function useDeleteUser() {
  const deleteUser = useDeleteUserAPI();
  const { closeModal, setAsyncLoading } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const onDelete = (
    id: string,
    name: string,
    orgId: string,
    reloadUsers: any = null
  ) => {
    setAsyncLoading(true);

    deleteUser(id, orgId)
      .then(() => {
        console.log(`User ${name} deleted`);
        showSnackbar({
          text: `User ${name} deleted`,
          type: MESSAGE.info,
          icon: DELETE_ICON
        });
        setAsyncLoading(false);
        closeModal();
        if (reloadUsers) {
          reloadUsers();
        } else {
          navigate(buildUrl(USERS_URL));
        }
      })
      .catch((error) => {
        console.error(
          `Error deleting user ${name}. id: ${id}. Status ${error.status}. ${error}`
        );
        showSnackbar({
          text: `Error deleting user ${name}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        setAsyncLoading(false);
        closeModal();
      });
  };

  return onDelete;
}
