/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import Button from "../../components/button/button";
import { SKELETON_VARIANT, VARIANT } from "../../constants/common.constants";
import { IOrganizationUsersProps } from "../organization.types";
import SkeletonLoader from "../../components/skeleton/skeleton-loader";

export default function OrganizationUsers({
  count,
  onManage,
  disabled,
  isLoading = false
}: IOrganizationUsersProps) {
  const usersClassRoot = "organizationContainer__users__info";

  return (
    <section className="organizationContainer__users">
      <h3 className="organizationContainer__users__title">Users</h3>
      <div className={usersClassRoot}>
        <div className={`${usersClassRoot}__text`}>
          <label>Total</label>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.text}
          >
            <p className={`${usersClassRoot}__text__value`}>{count}</p>
          </SkeletonLoader>
        </div>
        <div className={`${usersClassRoot}__button`}>
          <Button
            text="Manage"
            onClick={onManage}
            variant={VARIANT.primary}
            disabled={disabled}
          />
        </div>
      </div>
    </section>
  );
}
