import React, { useContext, useEffect } from "react";
import LoginAnimation from "../components/loginAnimation/loginAnimation";
import LoginPrompt from "./loginPrompt";
import LoginContext from "../contexts/session.context";
import ModalContext from "../contexts/modal.context";
import { ISessionContext } from "../contexts/types/login.types";
import { IModalContext } from "../contexts/types/modal.types";

export default function Login() {
  const { closeModal }: IModalContext = useContext(ModalContext);
  const {
    requestError,
    isOrgLoading,
    isLogging,
    sendLoginRequest,
    statusMessage
  }: ISessionContext = useContext(LoginContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => closeModal(), []);

  return isLogging ? (
    <LoginAnimation statusMessage={statusMessage} />
  ) : (
    <LoginPrompt
      sendLoginRequest={sendLoginRequest}
      requestError={requestError}
      isOrgLoading={isOrgLoading}
    />
  );
}
