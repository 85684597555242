import { useGet } from "./api";

export default function useGetRoles() {
  const getApi = useGet();

  async function run() {
    getApi.setEndpoint(`${process.env.REACT_APP_API_ROLES}`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}
