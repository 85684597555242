/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  CHEVRON_RIGHT_ICON,
  MENU_DEFAULT_WIDTH,
  MENU_MAX_HEIGHT
} from "../../constants/common.constants";
import Avatar from "../avatar/avatar";
import "./menu.scss";

export type IMenuItem = {
  id: string;
  value?: string | undefined;
  subValue?: string;
  initials?: string;
  icon?: string;
  subItems?: IMenuItem[];
  hide?: boolean;
};

type IMenuItemProps = {
  data: IMenuItem;
  clickAction: any;
  iconColor?: boolean;
};

type IMenuProps = {
  children: React.JSX.Element;
  items: IMenuItem[];
  clickAction: any;
  onClickOutside?: any;
  disableTogglerClose?: boolean;
  disabled?: boolean;
  top?: boolean;
  left?: boolean;
  select?: boolean;
  iconColor?: boolean;
  subMenu?: boolean;
};

function MenuItem({
  data: { id, value, subValue, initials, icon, subItems = [] },
  clickAction,
  iconColor
}: IMenuItemProps) {
  const baseClassName = "menu-container__item";

  const doClick = useCallback(() => {
    if (!subItems.length) clickAction(id);
  }, [clickAction, id, subItems]);

  const doSubItemClick = useCallback(
    (subItemId: string) => {
      clickAction(subItemId);
    },
    [clickAction]
  );

  const itemStructure = useMemo(
    () => (
      <li className={`${baseClassName}`} onClick={doClick}>
        <div className={`${baseClassName}__content`}>
          {icon && (
            <img
              className={`${baseClassName}__icon${
                !iconColor ? ` ${baseClassName}__icon--default-color` : ""
              }`}
              src={icon}
              alt=""
            />
          )}
          {initials && <Avatar text={initials} secondary />}
          <div className={`${baseClassName}__content__text`}>
            <span className={`${baseClassName}__content__text-value`}>
              {value}
            </span>
            {subValue && (
              <span className={`${baseClassName}__content__text-subvalue`}>
                {subValue}
              </span>
            )}
          </div>
        </div>
        {!!subItems.length && (
          <img
            className={`${baseClassName}__chevron`}
            src={CHEVRON_RIGHT_ICON}
            alt=""
          />
        )}
      </li>
    ),
    [doClick, icon, subItems, value, iconColor, initials, subValue]
  );

  return subItems.length ? (
    <Menu items={subItems} clickAction={doSubItemClick} subMenu>
      {itemStructure}
    </Menu>
  ) : (
    <>{itemStructure}</>
  );
}

export default function Menu({
  children,
  items = [],
  clickAction = () => {},
  onClickOutside = () => {},
  disableTogglerClose = false,
  disabled,
  top,
  left,
  select,
  iconColor,
  subMenu
}: IMenuProps) {
  const baseClassName = "menu-container__list";

  const containerRef = createRef<any>();
  const menuRef = createRef<any>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [topDir, setTopDir] = useState<boolean>(top || false);
  const [leftDir, setLeftDir] = useState<boolean>(left || false);
  const [width, setWidth] = useState<string | number>(
    select ? "0px" : MENU_DEFAULT_WIDTH
  );
  const [scroll, setScroll] = useState<boolean>(false);
  const heightSW = useRef(false);

  const toggleOpen: () => void = useCallback(
    () => !disabled && setIsOpen(!isOpen),
    [disabled, isOpen]
  );

  const menuClassName: string = useMemo(
    () =>
      `${baseClassName}${topDir ? ` ${baseClassName}--top` : ""}${
        leftDir ? ` ${baseClassName}--left` : ""
      }${scroll ? ` ${baseClassName}--scroll` : ""}`,
    [leftDir, topDir, scroll]
  );

  const doClickAction: (id: string) => void = useCallback(
    (id: string) => {
      setIsOpen(false);
      clickAction(id);
    },
    [clickAction]
  );

  const doOnClickOutside: () => void = useCallback(() => {
    if (onClickOutside) onClickOutside();
    toggleOpen();
  }, [onClickOutside, toggleOpen]);

  const onMouseOver: () => void = useCallback(() => {
    if (subMenu) setIsOpen(true);
  }, [subMenu]);

  const onMouseLeave: () => void = useCallback(() => {
    if (subMenu) setIsOpen(false);
  }, [subMenu]);

  useEffect(() => {
    if (menuRef.current) {
      const { height } = menuRef.current.getBoundingClientRect();
      if (height > MENU_MAX_HEIGHT && heightSW.current) setScroll(true);
      heightSW.current = true;
    }
  }, [menuRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (menuRef.current && containerRef.current) {
      const container = containerRef.current.getBoundingClientRect();
      // eslint-disable-next-line no-shadow
      const { bottom, top, right, left, height } =
        menuRef.current.getBoundingClientRect();
      const topDirRef =
        container.bottom +
          (height <= MENU_MAX_HEIGHT ? bottom - top : MENU_MAX_HEIGHT) >
        window.innerHeight;
      const leftDirRef = container.left + (right - left) > window.innerWidth;
      if (topDirRef !== topDir) setTopDir(topDirRef);
      if (leftDirRef !== leftDir) setLeftDir(leftDirRef);

      if (select) {
        const widthRef = container.right - container.left;
        if (widthRef !== width) setWidth(widthRef);
      }
    }
  }, [menuRef, containerRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="menu-container"
      ref={containerRef}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {isOpen && (
        <>
          {!subMenu && (
            <div className="click-outside" onClick={doOnClickOutside} />
          )}
          <ul className={menuClassName} ref={menuRef} style={{ width }}>
            {items
              .filter(
                ({ hide, subItems }: IMenuItem) =>
                  !hide && !(subItems && !subItems.length)
              )
              .map((data: IMenuItem) =>
                data.id === "__divider" ? (
                  <div
                    key={`divider-${Math.random()}`}
                    className={`${baseClassName}__divider`}
                  />
                ) : (
                  <MenuItem
                    key={data.id}
                    data={data}
                    clickAction={doClickAction}
                    iconColor={iconColor}
                  />
                )
              )}
          </ul>
        </>
      )}
      {(!!items.filter(
        ({ hide, subItems }: IMenuItem) =>
          !hide && !(subItems && !subItems.length)
      ).length ||
        select) && (
        <div
          onClick={
            subMenu || (disableTogglerClose && isOpen) ? () => {} : toggleOpen
          }
          className="menu-container__toggler"
        >
          {children}
        </div>
      )}
    </div>
  );
}
