export default function useSVG() {
  const get = async (file: string) => {
    const errorMessage = `Error downloading ${file}`;

    try {
      const fileUrl = `${process.env.REACT_APP_CDN_IMAGES}/${file}`;

      const response = await fetch(fileUrl);

      if (!response.ok) {
        const error = await response.text();
        throw Error(`${errorMessage} ${error}`);
      }

      return await response.text();
    } catch (error: any) {
      throw Error(error);
    }
  };

  return get;
}
