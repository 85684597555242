/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { AsYouType } from "libphonenumber-js";
import Input from "../../components/input/input";
import { WIDTH } from "../../constants/common.constants";
import TextArea from "../../components/textArea/textArea";
import Select from "../../components/select/select";
import {
  connectionsMapper,
  residencyRegionMapper,
  timezonesMapper
} from "../../mappers/organizationMappers";
import { handlersMapper } from "../../mappers/incidentListMappers";
import { IOrganizationDetailsProps } from "../organization.types";
import Permissions from "../../permissions/permissions";

export default function Details(props: IOrganizationDetailsProps) {
  const formId = "organizationContainer__details__info";

  const {
    formik: { values, errors, touched, handleChange, setFieldValue },
    isLoadingData,
    isLoadingCatalog,
    isCreateMode,
    isClientUser,
    isMDRUser,
    residencyRegions,
    timeZones,
    connectionTypes,
    incidentHandlers
  } = props;

  const { Organization } = Permissions();
  const localUser = useSelector((state: any) => state.user.profile);

  return (
    <>
      <div className={`${formId}__name`}>
        <Field
          label="Name"
          name="name"
          error={errors.name}
          onChange={handleChange}
          value={values.name}
          width={WIDTH.max}
          touched={touched.name}
          isLoading={isLoadingData}
          readOnly={
            !isCreateMode ||
            !Organization.sections.details.name.canUpdate(localUser.role)
          }
          component={Input}
        />
      </div>
      {isMDRUser && (
        <div className={`${formId}__description`}>
          <Field
            label="Description"
            name="description"
            error={errors.description}
            onChange={handleChange}
            value={values.description}
            width={WIDTH.max}
            touched={touched.description}
            isLoading={isLoadingData}
            readOnly={
              !Organization.sections.details.description.canUpdate(
                localUser.role
              )
            }
            component={TextArea}
          />
        </div>
      )}
      {!isClientUser && (
        <div className={`${formId}__timezone`}>
          <label>Timezone</label>
          <Field
            name="timezone"
            value={timeZones[values.timezone]}
            onChange={(value: string) => setFieldValue("timezone", value)}
            items={timezonesMapper(timeZones)}
            isLoading={isLoadingData || isLoadingCatalog}
            isValid={touched.timezone && !errors.timezone}
            readOnly={
              !Organization.sections.details.timeZone.canUpdate(
                localUser.role
              ) || !Object.keys(timeZones).length
            }
            withSearch
            component={Select}
          />
        </div>
      )}
      {!isClientUser && isCreateMode && (
        <div className={`${formId}__region`}>
          <label>Residency region</label>
          <Field
            name="residency_region"
            value={residencyRegions[values.residency_region]}
            onChange={(value: string) =>
              setFieldValue("residency_region", value)
            }
            items={residencyRegionMapper(residencyRegions)}
            isLoading={isLoadingData || isLoadingCatalog}
            isValid={touched.residency_region && !errors.residency_region}
            readOnly={
              !Organization.sections.details.residencyRegion.canUpdate(
                localUser.role
              ) || !Object.keys(timeZones).length
            }
            withSearch
            component={Select}
          />
        </div>
      )}
      <div className={`${formId}__rules`}>
        <Field
          label="Rules of engagement"
          name="rules_engagement"
          error={errors.rules_engagement}
          onChange={handleChange}
          value={values.rules_engagement}
          width={WIDTH.max}
          touched={touched.rules_engagement}
          isLoading={isLoadingData}
          readOnly={
            !Organization.sections.details.rules.canUpdate(localUser.role)
          }
          component={TextArea}
        />
      </div>
      <div className={`${formId}__escalationName`}>
        <Field
          label="Escalation name"
          name="escalation_name"
          error={errors.escalation_name}
          onChange={handleChange}
          value={values.escalation_name}
          width={WIDTH.max}
          touched={touched.escalation_name}
          isLoading={isLoadingData}
          readOnly={
            !Organization.sections.details.escalationName.canUpdate(
              localUser.role
            )
          }
          component={Input}
        />
      </div>
      <div className={`${formId}__escalationEmail`}>
        <Field
          label="Escalation email"
          name="escalation_email"
          error={errors.escalation_email}
          onChange={handleChange}
          value={values.escalation_email}
          width={WIDTH.max}
          touched={touched.escalation_email}
          isLoading={isLoadingData}
          readOnly={
            !Organization.sections.details.escalationEmail.canUpdate(
              localUser.role
            )
          }
          component={Input}
        />
      </div>
      <div className={`${formId}__escalationPhone`}>
        <Field
          label="Escalation phone"
          name="escalation_phone"
          error={errors.escalation_phone}
          onChange={(e: any) =>
            setFieldValue(
              "escalation_phone",
              new AsYouType("US").input(e.target.value)
            )
          }
          value={values.escalation_phone}
          width={WIDTH.max}
          touched={touched.escalation_phone}
          isLoading={isLoadingData}
          readOnly={
            !Organization.sections.details.escalationPhone.canUpdate(
              localUser.role
            )
          }
          component={Input}
        />
      </div>
      {isMDRUser && (
        <div className={`${formId}__connection`}>
          <label>Connection type</label>
          <Field
            name="connection_type"
            value={connectionTypes[values.connection_type]}
            onChange={(value: string) =>
              setFieldValue("connection_type", value)
            }
            items={connectionsMapper(connectionTypes)}
            isLoading={isLoadingData || isLoadingCatalog}
            isValid={touched.connection_type && !errors.connection_type}
            readOnly={
              !Organization.sections.details.connection.canUpdate(
                localUser.role
              ) || !Object.keys(timeZones).length
            }
            component={Select}
          />
        </div>
      )}
      <div className={`${formId}__handler`}>
        <label>Incident handler</label>
        <Field
          name="incident_handler"
          value={incidentHandlers[values.incident_handler]}
          onChange={(value: string) => setFieldValue("incident_handler", value)}
          items={handlersMapper(incidentHandlers)}
          isLoading={isLoadingData || isLoadingCatalog}
          isValid={touched.incident_handler && !errors.incident_handler}
          readOnly={
            !Organization.sections.details.incidentHandler.canUpdate(
              localUser.role
            ) || !Object.keys(timeZones).length
          }
          component={Select}
        />
      </div>
      {!isCreateMode && (
        <>
          <div className={`${formId}__handlerEmail`}>
            <Field
              label="Handler email"
              name="handler_email"
              type="email"
              error={errors.handler_email}
              onChange={handleChange}
              value={values.handler_email}
              width={WIDTH.max}
              touched={touched.handler_email}
              isLoading={isLoadingData}
              readOnly
              component={Input}
            />
          </div>
          <div className={`${formId}__handlerPhone`}>
            <Field
              label="Handler phone"
              name="handler_phone"
              error={errors.handler_phone}
              onChange={(e: any) =>
                setFieldValue(
                  "handler_phone",
                  new AsYouType("US").input(e.target.value)
                )
              }
              value={values.handler_phone}
              width={WIDTH.max}
              touched={touched.handler_phone}
              isLoading={isLoadingData}
              readOnly
              component={Input}
            />
          </div>
        </>
      )}
    </>
  );
}
