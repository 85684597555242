import React from "react";
import "./users.scss";
import {
  DELETE_ICON,
  DIVIDER_ICON,
  EDIT_ICON,
  VARIANT
} from "../constants/common.constants";
import Button from "../components/button/button";
import Tooltip, { tooltipTypes } from "../components/tooltip/tooltip";
import { IUsersActionsProps } from "./users.types";

export default function UsersActions(props: IUsersActionsProps) {
  const {
    row: {
      original: { id, name }
    },
    showEditAction,
    showDeleteAction,
    onDelete,
    onEdit
  } = props;

  const onEditAction = () => onEdit(id);

  const onDeleteAction = () => onDelete(id, name);

  return (
    <div className="usersActionsContainer">
      {showEditAction && (
        <div className="has-tooltip usersActionsContainer__edit">
          <Button variant={VARIANT.secondary} onClick={onEditAction}>
            <img src={EDIT_ICON} alt="Edit" />
          </Button>
          <Tooltip type={tooltipTypes.info} text="Edit" />
        </div>
      )}
      {showEditAction && showDeleteAction && (
        <img
          className="usersActionsContainer__divider"
          src={DIVIDER_ICON}
          alt=""
        />
      )}
      {showDeleteAction && (
        <div className="has-tooltip usersActionsContainer__delete">
          <Button
            variant={VARIANT.secondary}
            onClick={onDeleteAction}
            iconColor
          >
            <img src={DELETE_ICON} alt="Delete" />
          </Button>
          <Tooltip type={tooltipTypes.info} text="Delete" />
        </div>
      )}
    </div>
  );
}
