import React from "react";
import useGuard from "./useGuard";
import { Guards } from "../../constants/common.constants";

export default function Guard({
  guards = [],
  children
}: {
  guards?: Guards[];
  children: React.JSX.Element;
}) {
  let resultElement = children;
  resultElement = useGuard(guards, children);
  return resultElement;
}
