import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoginAnimation from "../components/loginAnimation/loginAnimation";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function useProcessQueryParameters() {
  const [statusMessage, setStatusMessage] = useState<string>("");

  const query = useQuery();

  const navigate = useNavigate();

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const invitation = query.get("invitation");

    if (invitation) {
      const organization = query.get("organization");

      if (organization) {
        loginWithRedirect({
          authorizationParams: {
            invitation,
            organization
          }
        });
      } else {
        setStatusMessage("Organization ID not found on invitation");
      }
    } else {
      navigate("/");
    }
  }, [query, navigate, loginWithRedirect]);

  return statusMessage;
}

export default function Authorize() {
  const statusMessage = useProcessQueryParameters();

  return <LoginAnimation statusMessage={statusMessage} />;
}
