/* eslint-disable no-restricted-syntax */
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGetRoles from "../api/roles";
import { setRoles } from "../settings/userSlice";
import {
  ADMIN_ROLES,
  ROLES,
  SOTERIA_ROLES,
  CLIENT_ROLES
} from "../constants/common.constants";
import { IRole } from "../types/common.types";

export default function useRoles() {
  const profileRole = useSelector((state: any) => state.user.profile.role);
  const roles = useSelector((state: any) => state.user.roles);

  const dispatch = useDispatch();
  const getRoles = useGetRoles();

  const getRolesInProgress = useRef<boolean>(false);

  useEffect(() => {
    if (roles || getRolesInProgress.current) {
      return;
    }

    getRolesInProgress.current = true;
    getRoles()
      .then((rolesData: IRole[]) => {
        dispatch(setRoles(rolesData));
        getRolesInProgress.current = false;
      })
      .catch((error) => {
        console.error(`Error getting roles. Status ${error.status}. ${error}`);
        getRolesInProgress.current = false;
      });
  }, [dispatch, getRoles, roles]);

  const soteriaRoles = useMemo(() => {
    if (profileRole !== ROLES.mdrAdmin || !roles?.length) {
      return [];
    }

    return roles.filter(({ name }: any) => SOTERIA_ROLES.includes(name));
  }, [profileRole, roles]);

  const clientRoles = useMemo(() => {
    if (!ADMIN_ROLES.includes(profileRole) || !roles?.length) {
      return [];
    }

    return roles.filter(({ name }: any) => CLIENT_ROLES.includes(name));
  }, [profileRole, roles]);

  return {
    soteriaRoles,
    clientRoles
  };
}
