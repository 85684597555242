/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect } from "react";
import "./addUser.scss";
import { Field } from "formik";
import Select from "../../components/select/select";
import { rolesSelectMapper } from "../../mappers/userMappers";
import Input from "../../components/input/input";
import { WIDTH } from "../../constants/common.constants";
import ModalContext from "../../contexts/modal.context";

export default function AddUserForm({
  formik: {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    isValid,
    touched
  },
  roles
}: any) {
  const { setActionDisabled } = useContext(ModalContext);
  useEffect(() => {
    setActionDisabled(!isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  return (
    <form id="addUserForm" className="addUserForm" onSubmit={handleSubmit}>
      <p>Add new user email to send invite</p>
      <label className="addUserEmailLabel">Email</label>
      <Field
        name="email"
        type="email"
        error={errors.email}
        onChange={handleChange}
        value={values.email}
        width={WIDTH.max}
        touched={touched.email}
        component={Input}
      />
      <label className="addUserRoleLabel">Role</label>
      <Field
        name="role"
        value={roles[values.role]}
        onChange={(value: string) => setFieldValue("role", value)}
        disabled={!!errors.email}
        items={rolesSelectMapper(roles)}
        component={Select}
      />
    </form>
  );
}
