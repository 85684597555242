/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import LogRocket from "logrocket";
import setupLogRocket from "logrocket-react";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import App from "./App";

LogRocket.init(`${process.env.REACT_APP_LOG_ROCKET_PROJECT}`);
setupLogRocket(LogRocket);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(() => {});
