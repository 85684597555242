import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetOrganization } from "../api/organizations";
import { useGetUsers } from "../api/users";
import Permissions from "../permissions/permissions";
import { useGetUserInvitations } from "../api/invitation";
import { IInvitation, IUserData } from "./users.types";
import SnackbarContext from "../contexts/snackbar.context";
import { ERROR_ICON } from "../constants/common.constants";
import useIsMounted from "../hooks/useIsMounted";
import { buildUrl } from "../utils/string.utils";
import {
  DIFFERENT_ORGANIZATION_USER_DETAILS_URL,
  INCIDENTS_URL,
  OWN_ORGANIZATION_USER_DETAILS_URL,
  SETTINGS_URL,
  USERS_URL
} from "../constants/urls.constants";
import SessionContext from "../contexts/session.context";

export default function useUsers() {
  const isMounted = useIsMounted();
  const localUser = useSelector((state: any) => state.user.profile);
  const localOrg = useSelector((state: any) => state.user.orgId);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isLoadingInvitations, setIsLoadingInvitations] =
    useState<boolean>(false);
  const { isAdmin } = useContext(SessionContext);
  const { Users } = Permissions();
  const { showSnackbar } = useContext(SnackbarContext);

  const showAddAction: boolean = Users.sections.actionBar.canAdd(
    localUser.role
  );
  const showSearchAction: boolean = Users.sections.actionBar.canSearch(
    localUser.role
  );
  const showEditAction: boolean = Users.sections.info.canUpdate(localUser.role);
  const deleteActionAvailable: boolean = Users.sections.info.canDelete(
    localUser.role
  );

  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useParams();
  const getUsers = useGetUsers();
  const getInvitations = useGetUserInvitations();
  const getOrganization = useGetOrganization();

  const localOrgUsers =
    location.pathname === buildUrl(USERS_URL) ||
    location.pathname === `${buildUrl(USERS_URL)}/`;

  const globalOrgRegEx = /^\/organizations\/\w+\/users/;
  const globalOrgUsers = globalOrgRegEx.test(location.pathname);

  const orgId: string = localOrgUsers ? localOrg : urlParams.id;

  const [users, setUsers] = useState<IUserData[]>([]);
  const retrieveUsers = useCallback(async () => {
    setIsLoadingUsers(true);
    try {
      const response: IUserData[] = await getUsers(orgId);
      if (!isMounted.current) return;
      if (!response.length) {
        console.log(`The organization ${orgId} doesn't have users on it`);
      }
      setUsers(response);
      setIsLoadingUsers(false);
    } catch (error: any) {
      console.error(`Error getting users. Status ${error.status}. ${error}`);
      setIsLoadingUsers(false);
      showSnackbar({
        text: `Unable to retrieve users list. ${error}`,
        type: "error",
        icon: ERROR_ICON
      });
      navigate(buildUrl(INCIDENTS_URL));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [invitations, setInvitations] = useState<IInvitation[]>([]);
  const retrieveInvitations = useCallback(async () => {
    setIsLoadingInvitations(true);
    try {
      const response: IInvitation[] = await getInvitations(orgId);
      if (!isMounted.current) return;
      if (!response.length) {
        console.log(`The organization ${orgId} doesn't have invitations on it`);
      }
      setInvitations(response);
      setIsLoadingInvitations(false);
    } catch (error: any) {
      console.error(
        `Error getting invitations. Status ${error.status}. ${error}`
      );
      setIsLoadingInvitations(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    retrieveUsers();
    if (isAdmin) retrieveInvitations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const orgRequestInProgress = useRef<boolean>(false);
  const [orgName, setOrgName] = useState<string>("");
  const getOrgName = useCallback(async () => {
    try {
      orgRequestInProgress.current = true;
      const organization = await getOrganization(orgId);
      setOrgName(organization.name);
      orgRequestInProgress.current = false;
    } catch (error: any) {
      orgRequestInProgress.current = false;
      console.error(
        `Error getting organization ${orgId}. Status ${error.status}. ${error}`
      );
    }
  }, [getOrganization, orgId]);

  useEffect(() => {
    if (orgRequestInProgress.current) {
      return;
    }

    if (orgName) {
      return;
    }
    getOrgName();
  }, [orgName, getOrgName, orgId]);

  const handleEditUser = (id: string) => {
    if (localUser.id === id) {
      navigate(buildUrl(SETTINGS_URL));
      return;
    }

    if (localOrgUsers) {
      navigate(buildUrl(OWN_ORGANIZATION_USER_DETAILS_URL, id));
      return;
    }

    if (globalOrgUsers) {
      navigate(buildUrl(DIFFERENT_ORGANIZATION_USER_DETAILS_URL, orgId, id));
    }
  };

  const handleUserDeleted = (id: string) => {
    const newUsers = users.filter((user: any) => id !== user.id);

    setUsers(newUsers);
  };

  return {
    localUserId: localUser.id,
    orgId,
    orgName,
    showAddAction,
    deleteActionAvailable,
    showEditAction,
    showSearchAction,
    users,
    invitations,
    isLoadingUsers,
    isLoadingInvitations,
    handleEditUser,
    handleUserDeleted,
    retrieveUsers,
    retrieveInvitations
  };
}
