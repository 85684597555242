import { ACTIONS, DATA, ROLES, SCOPE } from "../constants/common.constants";
import { IPermissions } from "./permissions.types";

export default function Permissions(): IPermissions {
  const isActionAllowed = (rolesAllowed: string[], role: string) =>
    rolesAllowed.includes(role);

  const Pages = {
    organizations: {
      actions: {
        [ACTIONS.navigate]: [
          ROLES.mdrAdmin,
          ROLES.mdrUser,
          ROLES.providerAdmin,
          ROLES.providerUser
        ]
      },
      canNavigate: (role: string) =>
        isActionAllowed(Pages.organizations.actions[ACTIONS.navigate], role)
    },
    users: {
      actions: {
        [ACTIONS.navigate]: [
          ROLES.clientAdmin,
          ROLES.mdrUser,
          ROLES.mdrAdmin,
          ROLES.providerAdmin
        ]
      },
      canNavigate: (role: string) =>
        isActionAllowed(Pages.users.actions[ACTIONS.navigate], role)
    }
  };

  const Incidents = {
    sections: {
      actionBar: {
        close: {
          actions: {
            [ACTIONS.show]: [ROLES.mdrAdmin, ROLES.mdrUser]
          },
          canSee: (role: string) =>
            isActionAllowed(
              Incidents.sections.actionBar.close.actions[ACTIONS.show],
              role
            )
        }
      },
      info: {
        data: {
          [DATA.all]: [ROLES.mdrAdmin, ROLES.mdrUser]
        },
        canSeeAll: (role: string) =>
          isActionAllowed(Incidents.sections.info.data[DATA.all], role),
        header: {
          actions: {
            [ACTIONS.show]: [ROLES.mdrAdmin, ROLES.mdrUser]
          },
          canSee: (role: string) =>
            isActionAllowed(
              Incidents.sections.info.header.actions[ACTIONS.show],
              role
            )
        },
        assigned: {
          actions: {
            [ACTIONS.show]: [ROLES.mdrAdmin, ROLES.mdrUser]
          },
          canSee: (role: string) =>
            isActionAllowed(
              Incidents.sections.info.assigned.actions[ACTIONS.show],
              role
            )
        },
        closeContextMenu: {
          actions: {
            [ACTIONS.show]: [ROLES.mdrAdmin, ROLES.mdrUser]
          },
          canSee: (role: string) =>
            isActionAllowed(
              Incidents.sections.info.closeContextMenu.actions[ACTIONS.show],
              role
            )
        }
      },
      filters: {
        client: {
          actions: {
            [ACTIONS.show]: [
              ROLES.mdrAdmin,
              ROLES.mdrUser,
              ROLES.providerAdmin,
              ROLES.providerUser
            ]
          },
          canSee: (role: string) =>
            isActionAllowed(
              Incidents.sections.filters.client.actions[ACTIONS.show],
              role
            )
        }
      }
    }
  };

  const sensors = {
    sections: {
      actionBar: {
        selectOrg: {
          actions: {
            [ACTIONS.show]: [ROLES.mdrAdmin, ROLES.mdrUser, ROLES.providerAdmin]
          },
          canSee: (role: string) =>
            isActionAllowed(
              sensors.sections.actionBar.selectOrg.actions[ACTIONS.show],
              role
            )
        }
      }
    }
  };

  const Organizations = {
    sections: {
      actionBar: {
        actions: {
          [ACTIONS.create]: [ROLES.mdrAdmin]
        },
        canCreate: (role: string) =>
          isActionAllowed(
            Organizations.sections.actionBar.actions[ACTIONS.create],
            role
          )
      },
      list: {
        actions: {
          [ACTIONS.delete]: [ROLES.mdrAdmin]
        },
        canDelete: (role: string) =>
          isActionAllowed(
            Organizations.sections.list.actions[ACTIONS.delete],
            role
          )
      }
    }
  };

  const Organization = {
    sections: {
      actionBar: {
        actions: {
          [ACTIONS.submit]: [
            ROLES.mdrAdmin,
            ROLES.clientAdmin,
            ROLES.providerAdmin
          ]
        },
        canSubmit: (role: string) =>
          isActionAllowed(
            Organization.sections.actionBar.actions[ACTIONS.submit],
            role
          )
      },
      details: {
        name: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.name.actions[ACTIONS.update],
              role
            )
        },
        description: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.description.actions[ACTIONS.update],
              role
            )
        },
        timeZone: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.timeZone.actions[ACTIONS.update],
              role
            )
        },
        residencyRegion: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.residencyRegion.actions[
                ACTIONS.update
              ],
              role
            )
        },
        rules: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.rules.actions[ACTIONS.update],
              role
            )
        },
        escalationName: {
          actions: {
            [ACTIONS.update]: [
              ROLES.mdrAdmin,
              ROLES.clientAdmin,
              ROLES.providerAdmin
            ]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.escalationName.actions[
                ACTIONS.update
              ],
              role
            )
        },
        escalationEmail: {
          actions: {
            [ACTIONS.update]: [
              ROLES.mdrAdmin,
              ROLES.clientAdmin,
              ROLES.providerAdmin
            ]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.escalationEmail.actions[
                ACTIONS.update
              ],
              role
            )
        },
        escalationPhone: {
          actions: {
            [ACTIONS.update]: [
              ROLES.mdrAdmin,
              ROLES.clientAdmin,
              ROLES.providerAdmin
            ]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.escalationPhone.actions[
                ACTIONS.update
              ],
              role
            )
        },
        connection: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.connection.actions[ACTIONS.update],
              role
            )
        },
        incidentHandler: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.details.incidentHandler.actions[
                ACTIONS.update
              ],
              role
            )
        }
      },
      configuration: {
        edr: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.configuration.edr.actions[ACTIONS.update],
              role
            )
        },
        quota: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.configuration.quota.actions[ACTIONS.update],
              role
            )
        },
        provider: {
          actions: {
            [ACTIONS.update]: [ROLES.mdrAdmin]
          },
          canUpdate: (role: string) =>
            isActionAllowed(
              Organization.sections.configuration.provider.actions[
                ACTIONS.update
              ],
              role
            )
        }
      },
      users: {
        actions: {
          [ACTIONS.navigate]: [
            ROLES.mdrAdmin,
            ROLES.mdrUser,
            ROLES.providerAdmin
          ]
        },
        canNavigate: (role: string) =>
          isActionAllowed(
            Organization.sections.users.actions[ACTIONS.navigate],
            role
          )
      }
    }
  };

  const Settings = {
    sections: {
      settings: {
        actions: {
          [ACTIONS.delete]: [
            ROLES.clientAdmin,
            ROLES.mdrAdmin,
            ROLES.providerAdmin
          ],
          [ACTIONS.save]: [
            [ROLES.clientAdmin, ROLES.mdrAdmin, ROLES.providerAdmin]
          ]
        },
        canDelete: (role: string) =>
          isActionAllowed(
            Settings.sections.settings.actions[ACTIONS.delete],
            role
          )
      },
      profile: {
        name: {
          actions: {
            [ACTIONS.update]: {
              scope: {
                [SCOPE.global]: [
                  ROLES.mdrAdmin,
                  ROLES.clientAdmin,
                  ROLES.providerAdmin
                ],
                [SCOPE.local]: [
                  ROLES.mdrAdmin,
                  ROLES.mdrUser,
                  ROLES.clientAdmin,
                  ROLES.providerAdmin,
                  ROLES.clientUser,
                  ROLES.providerUser
                ]
              }
            }
          },
          canUpdate: (
            role: string,
            scope: typeof SCOPE.global | typeof SCOPE.local
          ) =>
            isActionAllowed(
              Settings.sections.profile.name.actions[ACTIONS.update].scope[
                scope
              ],
              role
            )
        },
        phone: {
          actions: {
            [ACTIONS.update]: {
              scope: {
                [SCOPE.global]: [
                  ROLES.mdrAdmin,
                  ROLES.clientAdmin,
                  ROLES.providerAdmin
                ],
                [SCOPE.local]: [
                  ROLES.mdrAdmin,
                  ROLES.mdrUser,
                  ROLES.clientAdmin,
                  ROLES.clientUser,
                  ROLES.providerAdmin,
                  ROLES.providerUser
                ]
              }
            }
          },
          canUpdate: (
            role: string,
            scope: typeof SCOPE.global | typeof SCOPE.local
          ) =>
            isActionAllowed(
              Settings.sections.profile.phone.actions[ACTIONS.update].scope[
                scope
              ],
              role
            )
        },
        email: {
          actions: {
            [ACTIONS.update]: {
              scope: {
                [SCOPE.global]: [ROLES.mdrAdmin],
                [SCOPE.local]: [ROLES.mdrAdmin]
              }
            }
          },
          canUpdate: (
            role: string,
            scope: typeof SCOPE.global | typeof SCOPE.local
          ) =>
            isActionAllowed(
              Settings.sections.profile.email.actions[ACTIONS.update].scope[
                scope
              ],
              role
            )
        },
        role: {
          actions: {
            [ACTIONS.update]: {
              scope: {
                [SCOPE.global]: [ROLES.mdrAdmin],
                [SCOPE.local]: [ROLES.mdrAdmin]
              }
            }
          },
          canUpdate: (
            role: string,
            scope: typeof SCOPE.global | typeof SCOPE.local
          ) =>
            isActionAllowed(
              Settings.sections.profile.role.actions[ACTIONS.update].scope[
                scope
              ],
              role
            )
        }
      }
    }
  };

  const Users = {
    sections: {
      actionBar: {
        actions: {
          [ACTIONS.add]: [
            ROLES.mdrAdmin,
            ROLES.clientAdmin,
            ROLES.providerAdmin
          ],
          [ACTIONS.search]: [
            ROLES.mdrAdmin,
            ROLES.mdrUser,
            ROLES.clientAdmin,
            ROLES.providerAdmin
          ]
        },
        canAdd: (role: string) =>
          isActionAllowed(Users.sections.actionBar.actions[ACTIONS.add], role),
        canSearch: (role: string) =>
          isActionAllowed(
            Users.sections.actionBar.actions[ACTIONS.search],
            role
          )
      },
      info: {
        actions: {
          [ACTIONS.update]: [
            ROLES.mdrAdmin,
            ROLES.clientAdmin,
            ROLES.providerAdmin
          ],
          [ACTIONS.delete]: [
            ROLES.mdrAdmin,
            ROLES.clientAdmin,
            ROLES.providerAdmin
          ]
        },
        canUpdate: (role: string) =>
          isActionAllowed(Users.sections.info.actions[ACTIONS.update], role),
        canDelete: (role: string) =>
          isActionAllowed(Users.sections.info.actions[ACTIONS.delete], role)
      }
    }
  };

  return {
    Incidents,
    sensors,
    Organization,
    Organizations,
    Pages,
    Settings,
    Users
  };
}
