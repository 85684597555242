import React from "react";
import "./pagination.scss";
import SVG from "../../svg/svg";
import Select from "../../select/select";
import { paginationMapper } from "../../../mappers/tableMappers";

type IProps = {
  pages: {
    current: number;
    total: number;
  };
  onGoTo: any;
  onNext: any;
  onPrevious: any;
  rows: {
    start: number;
    finish: number;
    total?: number;
  };
  onlyArrows?: boolean;
  forceDisablePrevious?: boolean;
  forceDisableNext?: boolean;
};

export default function Pagination({
  pages,
  onGoTo,
  onNext,
  onPrevious,
  rows,
  onlyArrows,
  forceDisablePrevious,
  forceDisableNext
}: IProps) {
  const previousDisabled = forceDisablePrevious || pages?.current === 1;

  const nextDisabled = forceDisableNext || pages?.current >= pages?.total;

  const previousClass = previousDisabled
    ? "paginationPrevious paginationPrevious--disabled"
    : "paginationPrevious";

  const nextClass = nextDisabled
    ? "paginationNext paginationNext--disabled"
    : "paginationNext";

  const onOptionSelected = (page: number) => onGoTo(page);

  const onNextAction = () => !nextDisabled && onNext();

  const onPreviousAction = () => !previousDisabled && onPrevious();

  const divider = `${process.env.REACT_APP_CDN_IMAGES}/divider.svg`;

  return (
    <div className="paginationContainer">
      {!onlyArrows && (
        <>
          <p>
            {rows?.start} - {rows?.finish} of {rows?.total}
          </p>
          <div className="paginationSelect">
            <Select
              value={pages?.current.toString()}
              onChange={onOptionSelected}
              items={paginationMapper(pages?.total)}
              noBackground
            />
          </div>
          <img className="paginationDivider" src={divider} alt="" />
        </>
      )}
      <div className={previousClass}>
        <SVG file="arrowLeft.svg" onClick={onPreviousAction} />
      </div>
      <div className={nextClass}>
        <SVG file="arrowRight.svg" onClick={onNextAction} />
      </div>
    </div>
  );
}
