import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  useCallback
} from "react";
import "./profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import {
  SCOPE,
  ERROR_ICON,
  EMAIL_REGEX,
  MESSAGE,
  USER_ICON,
  SETTINGS_ICON,
  SKELETON_VARIANT,
  LOCK_ICON
} from "../../constants/common.constants";
import {
  useDeleteUser,
  useGetUser,
  useResetMFA,
  useResetPassword,
  useUpdateUser
} from "../../api/users";
import getInitials, { buildUrl } from "../../utils/string.utils";
import { IUseProfile } from "./profile.types";
import SnackbarContext from "../../contexts/snackbar.context";
import { INCIDENTS_URL } from "../../constants/urls.constants";
import ModalContext from "../../contexts/modal.context";
import Permissions from "../../permissions/permissions";
import useRoles from "../../hooks/useRoles";
import { rolesMapper } from "../../mappers/userMappers";
import { updateProfile } from "../userSlice";
import Icon from "../../components/icon/icon";
import SkeletonLoader from "../../components/skeleton/skeleton-loader";

function useProfile(): IUseProfile {
  const { profile, orgId } = useSelector((state: any) => state.user);
  const [name, setName] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [initials, setInitials] = useState("");
  const [isMFAReset, setIsMFAReset] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    phone_number_1: "",
    phone_number_2: "",
    email: "",
    role_id: ""
  });

  const getProfileInProgress = useRef(false);
  const needsToRedirect = useRef(
    !profile.first_name || !profile.last_name || !profile.phone_number_1
  );

  const { showModal, setAsyncLoading, closeModal } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const getUser = useGetUser();
  const updateUser = useUpdateUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Settings } = Permissions();
  const sendResetMFARequest = useResetMFA();
  const sendResetPasswordRequest = useResetPassword();
  const { soteriaRoles, clientRoles } = useRoles();
  const onDelete = useDeleteUser();
  const urlParams = useParams();

  const userId = useMemo(
    () => urlParams.userId || profile.id,
    [profile.id, urlParams.userId]
  );

  const currentOrgId = useMemo(
    () => urlParams.orgId || orgId,
    [orgId, urlParams.orgId]
  );

  const scope = useMemo(
    () => (urlParams.userId ? SCOPE.global : SCOPE.local),
    [urlParams.userId]
  );

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    phone_number_1: Yup.string()
      .required("Phone number is required")
      .test("wrong-number-format", "Invalid Phone Number", (value) =>
        isValidPhoneNumber(value, "US")
      ),
    phone_number_2: Yup.string().test(
      "wrong-number-format",
      "Invalid Phone Number",
      (value) => !value || isValidPhoneNumber(`${value}`, "US")
    ),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email")
      .matches(EMAIL_REGEX, "Invalid email"),
    role_id: Yup.string().required("Role is required")
  });

  const roles = useMemo(
    () =>
      currentOrgId === process.env.REACT_APP_SOTERIA_ORG_ID
        ? rolesMapper(soteriaRoles, false)
        : rolesMapper(clientRoles, false),
    [clientRoles, soteriaRoles, currentOrgId]
  );

  const title = useMemo(
    () => (scope === SCOPE.local ? "Settings" : name),
    [scope, name]
  );

  const enableDeleteAction = useMemo(
    () =>
      Settings.sections.settings.canDelete(profile.role) &&
      scope !== SCOPE.local,
    [Settings.sections.settings, profile.role, scope]
  );

  const mfaText = useMemo(
    () => (isMFAReset ? "2FA already reset" : "Send 2FA reset"),
    [isMFAReset]
  );
  const paswordText = isPasswordReset
    ? "Password reset link sent"
    : scope === SCOPE.local
    ? "Send password reset"
    : "Send password reset";

  const icon = useMemo(
    () =>
      scope === SCOPE.local ? (
        <Icon image={SETTINGS_ICON} alt={title} />
      ) : (
        <SkeletonLoader
          loadingVar={isLoadingData}
          variant={SKELETON_VARIANT.avatar}
        >
          <Icon alt={initials} />
        </SkeletonLoader>
      ),
    [initials, isLoadingData, scope, title]
  );

  const nameFieldEnabled = useMemo(
    () => Settings.sections.profile.name.canUpdate(profile.role, scope),
    [Settings.sections.profile.name, profile.role, scope]
  );

  const phoneFieldEnabled = useMemo(
    () => Settings.sections.profile.phone.canUpdate(profile.role, scope),
    [Settings.sections.profile.phone, profile.role, scope]
  );

  const emailFieldEnabled = useMemo(
    () => Settings.sections.profile.email.canUpdate(profile.role, scope),
    [Settings.sections.profile.email, profile.role, scope]
  );

  const roleFieldEnabled = useMemo(
    () => Settings.sections.profile.role.canUpdate(profile.role, scope),
    [Settings.sections.profile.role, profile.role, scope]
  );

  const onSubmitProfile = async (values: any) => {
    try {
      const user = {
        id: userId,
        role: roles[values.role],
        ...values
      };
      const response = await updateUser(user, currentOrgId);
      setInitials(getInitials(response.name));
      if (scope === SCOPE.local)
        setTimeout(() => dispatch(updateProfile(response)), 2000);
      showSnackbar({
        text: "Profile saved",
        type: MESSAGE.info,
        icon: USER_ICON
      });
      if (needsToRedirect.current) navigate(buildUrl(INCIDENTS_URL));
    } catch (error: any) {
      showSnackbar({
        text: `Error saving profile. ${error.message}`,
        type: MESSAGE.error,
        icon: ERROR_ICON
      });
      console.error(
        `Error saving profile of user ${profile.name}, id ${profile.id}. Status ${error.status}. ${error}`
      );
    }
  };

  const deleteUser = useCallback(
    () =>
      showModal({
        title: "Delete user",
        clickAction: () => onDelete(userId, currentOrgId),
        actionText: "Yes, delete",
        content: <p>Are you sure you want to delete the user {name}?</p>
      }),
    [showModal, name, onDelete, userId, currentOrgId]
  );

  const resetPassword = useCallback(() => {
    setAsyncLoading(true);

    sendResetPasswordRequest(userId, currentOrgId)
      .then(() => {
        showSnackbar({
          text: "Password reset link sent",
          type: MESSAGE.info,
          icon: LOCK_ICON
        });
        setIsPasswordReset(true);
        setAsyncLoading(false);
        closeModal();
      })
      .catch((error) => {
        console.error(`Error sending password reset request. ${error}`);
        showSnackbar({
          text: `Error sending password reset request. ${error}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        setAsyncLoading(false);
        closeModal();
      });
  }, [
    setAsyncLoading,
    sendResetPasswordRequest,
    userId,
    currentOrgId,
    showSnackbar,
    closeModal
  ]);

  const onPasswordChange = useCallback(
    () =>
      showModal({
        title: "Send password reset",
        clickAction: () => resetPassword(),
        actionText: "Yes, send",
        content: <p>Are you sure you want to reset password for {name}?</p>
      }),
    [showModal, name, resetPassword]
  );

  const resetMFA = useCallback(() => {
    setAsyncLoading(true);

    sendResetMFARequest(userId, currentOrgId)
      .then(() => {
        showSnackbar({
          text: "Two-Factor Authentication reset successfully",
          type: MESSAGE.info,
          icon: LOCK_ICON
        });
        setIsMFAReset(true);
        setAsyncLoading(false);
        closeModal();
      })
      .catch((error) => {
        console.error(`Error sending MFA reset request. ${error}`);
        showSnackbar({
          text: `Error sending MFA reset request. ${error}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        setAsyncLoading(false);
        closeModal();
      });
  }, [
    setAsyncLoading,
    sendResetMFARequest,
    userId,
    currentOrgId,
    showSnackbar,
    closeModal
  ]);

  const onMFAChange = useCallback(
    () =>
      showModal({
        title: "Send 2FA reset",
        clickAction: () => resetMFA(),
        actionText: "Yes, send",
        content: (
          <p>
            Are you sure you want to reset Two-Factor Authentication for {name}?
          </p>
        )
      }),
    [showModal, name, resetMFA]
  );

  useEffect(() => {
    if (
      getProfileInProgress.current ||
      !userId ||
      !currentOrgId ||
      initialValues.first_name
    ) {
      return;
    }

    setIsLoadingData(true);
    getProfileInProgress.current = true;
    getUser(userId, currentOrgId)
      .then((response) => {
        setInitialValues({
          first_name: response.first_name,
          last_name: response.last_name,
          phone_number_1: new AsYouType("US").input(response.phone_number_1),
          phone_number_2: new AsYouType("US").input(response.phone_number_2),
          email: response.email,
          role_id: response.role_id
        });
        setName(response.name);
        setInitials(getInitials(response.name));
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.error(
          `Error getting profile of user ${userId} from org ${currentOrgId}. Status ${error.status}. ${error}`
        );
        showSnackbar({
          text: `Unable to retrieve user data. ${error}`,
          type: "error",
          icon: ERROR_ICON
        });
        navigate(buildUrl(INCIDENTS_URL));
      });
  }, [
    profile,
    getUser,
    userId,
    currentOrgId,
    showSnackbar,
    navigate,
    initialValues.first_name
  ]);

  useEffect(() => {
    if (!profile?.first_name || !profile?.last_name || !profile?.phone_number_1)
      showSnackbar({
        text: "Complete your user profile (first name, last name, and phone number) to continue",
        type: MESSAGE.info,
        icon: USER_ICON
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    title,
    scope,
    isLoadingData,
    validationSchema,
    enableDeleteAction,
    mfaText,
    paswordText,
    icon,
    roles,
    nameFieldEnabled,
    phoneFieldEnabled,
    emailFieldEnabled,
    roleFieldEnabled,
    isPasswordReset,
    isMFAReset,
    initialValues,
    onSubmitProfile,
    deleteUser,
    onPasswordChange,
    onMFAChange
  };
}

export default useProfile;
