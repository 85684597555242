import React from "react";
import "./avatar.scss";
import { AVATAR_ADD } from "../../constants/common.constants";
import Icon from "../icon/icon";

type IProps = {
  active?: boolean;
  disabled?: boolean;
  text?: string;
  onClick?: any;
  onMouseOver?: any;
  onMouseOut?: any;
  secondary?: boolean;
};

export default function Avatar(props: IProps) {
  const {
    active,
    disabled,
    text,
    onClick,
    onMouseOver,
    onMouseOut,
    secondary
  } = props;

  const containerClassRoot = "avatarContainer";
  let containerClassName = containerClassRoot;
  containerClassName += disabled
    ? " avatarContainer--disabled"
    : " avatarContainer--enabled";
  // eslint-disable-next-line no-nested-ternary
  containerClassName += text
    ? active
      ? " avatarContainer--active"
      : ` avatarContainer--default${secondary ? "-secondary" : ""}`
    : "";

  const onClickContainer = () => !disabled && onClick && onClick();

  const onMouseOverContainer = () => !disabled && onMouseOver && onMouseOver();

  const onMouseOutContainer = () => !disabled && onMouseOut && onMouseOut();

  return (
    <div
      className={containerClassName}
      onClick={onClickContainer}
      onMouseOver={onMouseOverContainer}
      onMouseOut={onMouseOutContainer}
    >
      {text ? <p>{text}</p> : <Icon image={AVATAR_ADD} />}
    </div>
  );
}
