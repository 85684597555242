import React, { useState } from "react";
import "./useSensorsListTableColumns.scss";
import {
  ARROW_DOWN_SORT,
  LIMA_CHARLIE_LABEL,
  OS_ICON_MAP,
  SENSORS_STATUSES,
  SENSOR_TYPE_ICON_MAP,
  SKELETON_VARIANT,
  TABLE,
  UNKNOWN_TEXT,
  VARIANT
} from "../../constants/common.constants";
import Chip from "../../components/chip/chip";
import Tooltip, { tooltipTypes } from "../../components/tooltip/tooltip";
import { ISensorListColumnHeader } from "../sensors-list.types";
import { ITableColumn } from "../../types/common.types";

function Header({
  headerData: { name, accessor },
  sortFunction
}: ISensorListColumnHeader) {
  const [currentSort, setCurrentSort] = useState(-1);
  return (
    <div className="last-connection-header">
      {name}
      <img
        className={`last-connection-header__sort ${
          currentSort > 0 ? "last-connection-header__sort-inverted" : ""
        }`}
        onClick={() => {
          setCurrentSort(currentSort * -1);
          sortFunction(currentSort, accessor);
        }}
        src={ARROW_DOWN_SORT}
        alt=""
      />
    </div>
  );
}

export default function useSensorsTableColumns(
  toggleSort: any
): ITableColumn[] {
  const buildCell = (value = "") => <span>{`${value || ""}`}</span>;

  return [
    {
      Header: () => (
        <Header
          headerData={TABLE.SENSORS.CELLS.type}
          sortFunction={toggleSort}
        />
      ),
      accessor: TABLE.SENSORS.CELLS.type.accessor,
      visible: "true",
      type: SKELETON_VARIANT.avatar,
      Cell: ({ value }: any) => (
        <div className="has-tooltip typeField">
          <img
            src={SENSOR_TYPE_ICON_MAP[value] || SENSOR_TYPE_ICON_MAP.default}
            alt=""
          />
          <Tooltip type={tooltipTypes.info}>
            <p>{value || LIMA_CHARLIE_LABEL}</p>
          </Tooltip>
        </div>
      )
    },
    {
      Header: () => (
        <Header headerData={TABLE.SENSORS.CELLS.os} sortFunction={toggleSort} />
      ),
      accessor: TABLE.SENSORS.CELLS.os.accessor,
      visible: "true",
      type: SKELETON_VARIANT.avatar,
      Cell: ({ value }: any) =>
        value ? (
          <div className="has-tooltip osField">
            <img src={OS_ICON_MAP[value] || OS_ICON_MAP.default} alt="" />
            <Tooltip type={tooltipTypes.info}>
              <p className="tooltip__text">{value}</p>
            </Tooltip>
          </div>
        ) : (
          buildCell()
        )
    },
    {
      Header: () => TABLE.SENSORS.CELLS.organization.name,
      accessor: TABLE.SENSORS.CELLS.organization.accessor,
      visible: "true",
      type: SKELETON_VARIANT.text,
      Cell: ({ value }: any) => buildCell(value)
    },
    {
      Header: () => (
        <Header
          headerData={TABLE.SENSORS.CELLS.hostname}
          sortFunction={toggleSort}
        />
      ),
      accessor: TABLE.SENSORS.CELLS.hostname.accessor,
      visible: "true",
      type: SKELETON_VARIANT.text,
      Cell: ({ value }: any) => buildCell(value)
    },
    {
      Header: () => (
        <Header
          headerData={TABLE.SENSORS.CELLS.internalIp}
          sortFunction={toggleSort}
        />
      ),
      accessor: TABLE.SENSORS.CELLS.internalIp.accessor,
      visible: "true",
      type: SKELETON_VARIANT.text,
      Cell: ({ value }: any) => buildCell(value)
    },
    {
      Header: () => (
        <Header
          headerData={TABLE.SENSORS.CELLS.externalIp}
          sortFunction={toggleSort}
        />
      ),
      accessor: TABLE.SENSORS.CELLS.externalIp.accessor,
      visible: "true",
      type: SKELETON_VARIANT.text,
      Cell: ({ value }: any) => buildCell(value)
    },
    {
      Header: () => (
        <Header
          headerData={TABLE.SENSORS.CELLS.installed}
          sortFunction={toggleSort}
        />
      ),
      accessor: TABLE.SENSORS.CELLS.installed.accessor,
      visible: "true",
      type: SKELETON_VARIANT.text,
      Cell: ({ value }: any) => buildCell(value?.split(" ")[0])
    },
    {
      Header: () => (
        <Header
          headerData={TABLE.SENSORS.CELLS.lastConnection}
          sortFunction={toggleSort}
        />
      ),
      accessor: TABLE.SENSORS.CELLS.lastConnection.accessor,
      visible: "true",
      type: SKELETON_VARIANT.text,
      Cell: ({ value }: any) => buildCell(value?.split(" ")[0])
    },
    {
      Header: TABLE.SENSORS.CELLS.tags.name,
      accessor: TABLE.SENSORS.CELLS.tags.accessor,
      visible: "true",
      type: SKELETON_VARIANT.chip,
      Cell: ({ value }: any) => {
        const moreContent = value ? [...value.slice(1)] : [];
        return value?.length ? (
          <div className="has-tooltip tags">
            <Chip label={value[0]} variant={VARIANT.status} />
            {!!moreContent?.length && (
              <>
                <Chip
                  label={`+${moreContent.length}`}
                  variant={VARIANT.status}
                  status="More"
                />
                <Tooltip type={tooltipTypes.info}>
                  <ul className="tags__list">
                    {moreContent.map((tag, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li className="tags__list__item" key={index}>
                        {tag}
                      </li>
                    ))}
                  </ul>
                </Tooltip>
              </>
            )}
          </div>
        ) : (
          ""
        );
      }
    },
    {
      Header: () => (
        <Header
          headerData={TABLE.SENSORS.CELLS.status}
          sortFunction={toggleSort}
        />
      ),
      accessor: TABLE.SENSORS.CELLS.status.accessor,
      visible: "true",
      type: SKELETON_VARIANT.chip,
      Cell: ({ value }: any) => {
        const text = SENSORS_STATUSES[value] || UNKNOWN_TEXT;

        return <Chip label={text} variant={VARIANT.status} status={text} />;
      }
    }
  ];
}
