import { useCallback, useEffect, useState } from "react";
import { useGetCatalog } from "../../api/organizations";
import { IOrganizationCatalog } from "../organization.types";

export default function useCatalog() {
  const [catalog, setCatalog] = useState<IOrganizationCatalog>(
    {} as IOrganizationCatalog
  );
  const [isLoadingCatalog, setIsLoadingCatalog] = useState(false);
  const get = useGetCatalog();

  const load = useCallback(async () => {
    try {
      setIsLoadingCatalog(true);
      const res = await get();
      setCatalog(res);
      setIsLoadingCatalog(false);
    } catch (error: any) {
      console.error(
        `Error getting organizations catalog. Status ${error.status}. ${error}`
      );
      setIsLoadingCatalog(false);
    }
  }, [get]);

  useEffect(() => {
    if (Object.keys(catalog).length) {
      return;
    }
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { catalog, isLoadingCatalog };
}
