import { Location } from "react-router-dom";
import { IProfile } from "../userSlice";
import { REDIRECT_SESSION_STORAGE } from "../../constants/common.constants";
import {
  AUTHORIZE_URL,
  LOGOUT_URL,
  SETTINGS_URL
} from "../../constants/urls.constants";

const initDataGuard = (
  { first_name, last_name, phone_number_1 }: IProfile,
  location: Location<any>,
  loggedIn: boolean
) => {
  const pendingUrl: string | null = sessionStorage.getItem(
    REDIRECT_SESSION_STORAGE
  );

  if (
    !loggedIn &&
    ![`/${LOGOUT_URL}`, "/", `/${AUTHORIZE_URL}`].includes(location.pathname)
  )
    sessionStorage.setItem(REDIRECT_SESSION_STORAGE, location.pathname);

  if (loggedIn && pendingUrl === location.pathname)
    sessionStorage.removeItem(REDIRECT_SESSION_STORAGE);

  return (
    (first_name && last_name && phone_number_1) ||
    location.pathname === `/${SETTINGS_URL}`
  );
};
export default initDataGuard;
