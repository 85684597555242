export default function exportToCSV(data: any, fileName: string) {
  const isStringData = typeof data === "string";
  let csvContent = isStringData ? data : "";

  if (!isStringData)
    data.forEach((row: any) => {
      csvContent += `${row.join(",")}\r\n`;
    });

  const blob = new Blob([csvContent], {
    type: "text/csv;charset=utf-8;"
  });

  const link = document.createElement("a");

  const url = window.URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  link.click();
  window.URL.revokeObjectURL(url);
}
