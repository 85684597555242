/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from "react";
import "./filter.scss";
import Select from "../select/select";
import { filtersMapper } from "../../mappers/incidentListMappers";
import { IAnyPropertyNameAndStringValue } from "../../types/common.types";

type IProps = {
  filters: IAnyPropertyNameAndStringValue;
  label: string;
  value: string;
  onChange: any;
  withSearch?: boolean;
  clearOption: boolean;
};

export default function Filter({
  filters,
  label,
  value,
  onChange,
  withSearch,
  clearOption
}: IProps) {
  const formattedFilters = useMemo(() => filtersMapper(filters), [filters]);

  return (
    <section className="filterContainer">
      <label>{label}</label>
      <Select
        value={value}
        onChange={onChange}
        items={formattedFilters}
        withSearch={withSearch}
        clearOption={clearOption}
      />
    </section>
  );
}
