/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useMemo } from "react";
import "./event.scss";
import EventDefaultActionBar from "./actionBar/eventDefaultActionBar";
import AnalystNotes from "./analystNotes/analystNotes";
import FormatDate from "../formatDate/formatDate";
import Accordion from "../components/accordion/accordion";
import { IEventProps } from "./event.types";
import { GENERIC_EVENT } from "../constants/common.constants";
import Icon from "../components/icon/icon";
import DetailLine from "./detailLine/detailLine";
import SessionContext from "../contexts/session.context";

export default function Event({ data, title }: IEventProps) {
  const { isMdrRole } = useContext(SessionContext);

  const {
    created,
    event_id,
    analyst_note: analystNotes,
    is_relevant,
    url,
    detections,
    triggering_rule,
    details
  } = data;

  const eventTitle = useMemo(() => `${title} event`, [title]);
  const icon = useMemo(() => <Icon image={GENERIC_EVENT} />, []);

  const showAnalystNotes = useMemo(
    () =>
      isMdrRole ||
      (Reflect.has(analystNotes, "Note") && !!analystNotes.Note.length),
    [analystNotes, isMdrRole]
  );

  const timestamp = useMemo(
    () => new FormatDate(created, false).dateWithTime,
    [created]
  );

  const headerTitle = useMemo(
    () => (
      <div className="eventContainer__title">
        <p>{eventTitle}</p>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>{timestamp}</label>
      </div>
    ),
    [eventTitle, timestamp]
  );

  const actionBar = useMemo(
    () =>
      isMdrRole ? (
        <EventDefaultActionBar
          detectionId={detections ? detections[0] : ""}
          edrUrl={url}
          eventId={event_id}
          isRelevant={is_relevant}
        />
      ) : null,
    [detections, event_id, isMdrRole, is_relevant, url]
  );

  return (
    <Accordion icon={icon} title={headerTitle} actionBar={actionBar} compressed>
      <div className="eventContainer">
        <div className="eventContainerColumn" />
        <div className="eventContainer__details">
          <div className="eventDataContainer">
            <div className="genericEventDetailsContainer">
              {isMdrRole && (
                <>
                  <label>Triggering event rule</label>
                  <ul className="genericEventDetailsContainerList">
                    {triggering_rule.map((rule, index) => (
                      <li key={index}>{rule}</li>
                    ))}
                  </ul>
                </>
              )}
              <DetailLine data={details || {}} />
            </div>
          </div>
        </div>
        {showAnalystNotes && (
          <div className="eventContainer__analystNotes">
            <AnalystNotes data={analystNotes} eventId={event_id} />
          </div>
        )}
      </div>
    </Accordion>
  );
}
