import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowState,
  useRowSelect,
  useTable
} from "react-table";
import { useEffect, useMemo, useState } from "react";
import { DEFAULT_ROWS_PER_PAGE } from "../../constants/common.constants";

export default function useTableInstance(columns: any, rows: any) {
  const initialFilters = useMemo(() => [], []);
  const initialState: any = {
    filters: initialFilters,
    pageSize: DEFAULT_ROWS_PER_PAGE,
    pageIndex: 0
  };

  const instance: any = useTable(
    { columns, data: rows, initialState },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowState,
    useRowSelect
  );

  // eslint-disable-next-line no-restricted-syntax
  for (const row of instance.rows) {
    instance.prepareRow(row);
  }

  const setGlobalFilterDebounced = useAsyncDebounce(
    instance.setGlobalFilter,
    100
  );

  const [globalFilter, setGlobalFilter] = useState("");
  const updateGlobalFilter = (value: any) => {
    setGlobalFilter(value);
  };

  useEffect(() => {
    setGlobalFilterDebounced(globalFilter);
  }, [globalFilter, setGlobalFilterDebounced]);

  const updateFilter = (column: any, value: any) =>
    instance.setFilter(column, value);

  const updateStartTimeFilter = (column: any, date: any) => {
    if (!instance.state.filters.length) {
      instance.setFilter(column, [date, undefined]);
    } else {
      let filterUpdated = false;
      instance.state.filters.forEach((filter: any) => {
        if (filter.id === column) {
          instance.setFilter(column, [date, filter.value[1]]);
          filterUpdated = true;
        }
      });
      if (!filterUpdated) instance.setFilter(column, [date, undefined]);
    }
  };

  const updateEndTimeFilter = (column: any, date: any) => {
    if (!instance.state.filters.length) {
      instance.setFilter(column, [undefined, date]);
    } else {
      let filterUpdated = false;
      instance.state.filters.forEach((filter: any) => {
        if (filter.id === column) {
          instance.setFilter(column, [filter.value[0], date]);
          filterUpdated = true;
        }
      });
      if (!filterUpdated) instance.setFilter(column, [undefined, date]);
    }
  };

  const resetFilters = () => {
    instance.setAllFilters([]);
  };

  const setCellState = (rowIndex: number, cellIndex: number, state: any) => {
    if (!instance.rows[rowIndex]) {
      console.group("Error saving state to cell");
      console.warn(`Row with index ${rowIndex} not found in table rows`);
      console.warn(instance.rows);
      console.groupEnd();
      return;
    }
    instance.rows[rowIndex].cells[cellIndex].setState(state);
  };

  const getCellState = (rowIndex: number, cellIndex: number) =>
    instance.rows[rowIndex].cells[cellIndex].state;

  const getCellData = (rowIndex: number, columnId: string) =>
    instance.data[rowIndex][columnId];

  const selectRow = (index: number) => instance.toggleRowSelected(index, true);

  const unSelectRow = (index: number) =>
    instance.toggleRowSelected(index, false);

  const selectAllRows = () => instance.toggleAllPageRowsSelected(true);

  const unSelectAllRows = () => instance.toggleAllPageRowsSelected(false);

  return {
    instance,
    getCellData,
    getCellState,
    setCellState,
    updateGlobalFilter,
    updateFilter,
    updateStartTimeFilter,
    updateEndTimeFilter,
    resetFilters,
    selectRow,
    unSelectRow,
    selectAllRows,
    unSelectAllRows
  };
}
