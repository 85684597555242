import React from "react";
import { Navigate } from "react-router-dom";
import { HOME_URL } from "../../constants/urls.constants";
import { buildUrl } from "../../utils/string.utils";

export const permissionsGuard = (
  canSee: boolean,
  component: React.JSX.Element
) => (canSee ? component : <Navigate to={buildUrl(HOME_URL)} replace />);

export default permissionsGuard;
