import React, { useCallback, useContext, useMemo } from "react";
import "./incidentsContextMenu.scss";
import { MORE_ICON } from "../../constants/common.constants";
import Menu from "../../components/menu/menu";
import useIncidentsContextMenu from "./useIncidentsContextMenu";
import { closeReasonsMapper } from "../../mappers/incidentListMappers";
import IncidentListContext from "../../contexts/incident-list.context";
import { IIncidentsContextMenuProps } from "../incident-list.types";

export default function IncidentsContextMenu({
  row,
  id
}: IIncidentsContextMenuProps) {
  const instance = useIncidentsContextMenu();

  const { onClickClose, closeReasons } = useContext(IncidentListContext);

  const showClose = instance.showClose(row);

  const menuItems = useMemo(
    () => [
      {
        id: "close",
        value: "Close",
        hide: !showClose,
        subItems: closeReasonsMapper(closeReasons)
      }
    ],
    [showClose, closeReasons]
  );

  const onCloseReasonSelected = useCallback(
    (value: string) =>
      onClickClose({
        value,
        row: row.index,
        id
      }),
    [onClickClose, row, id]
  );
  return (
    <div className="incidentsContextMenuContainer">
      <Menu items={menuItems} clickAction={onCloseReasonSelected} left>
        <img
          className="incidentsContextMenuContainer__icon"
          src={MORE_ICON}
          alt="Menu"
        />
      </Menu>
    </div>
  );
}
