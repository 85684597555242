import React from "react";
import "./tooltip.scss";

// eslint-disable-next-line no-shadow
export enum tooltipTypes {
  info = "info",
  error = "error"
}

type IProps = {
  type?: tooltipTypes;
  top?: boolean;
  right?: boolean;
  alt?: string;
  text?: string;
  subtext?: string;
  children?: React.JSX.Element;
  icon?: string;
};

export default function Tooltip(props: IProps) {
  const {
    type = "info",
    top,
    right,
    alt,
    text,
    subtext,
    children,
    icon
  } = props;

  const classRoot = "tooltipContainer";
  let className = classRoot;
  className += ` ${classRoot}--${type}`;
  className += top ? ` ${classRoot}--top` : "";
  className += right ? ` ${classRoot}--right` : "";

  const iconAlt = alt || text;

  return (
    <div className={className}>
      {icon && <img src={icon} alt={iconAlt} />}
      <div className={`${classRoot}__text-container`}>
        {text?.toString() && <p className={`${classRoot}__text`}>{text}</p>}
        {subtext?.toString() && (
          <p className={`${classRoot}__subtext`}>{subtext}</p>
        )}
      </div>
      {children}
    </div>
  );
}
