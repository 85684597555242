import React from "react";
import "./chip.scss";
import { SHAPE, VARIANT } from "../../constants/common.constants";

type IProps = {
  shape?: "oval" | "circle";
  status?: string;
  label?: string;
  variant?: string;
};

export default function Chip({ shape, status, label, variant }: IProps) {
  const classRoot = "chipContainer";

  let className = classRoot;
  className += shape
    ? ` ${classRoot}--${shape}`
    : ` ${classRoot}--${SHAPE.oval}`;
  className +=
    variant === VARIANT.status
      ? ` ${classRoot}--status${status || "Default"}`
      : "";

  return <div className={className}>{label}</div>;
}
