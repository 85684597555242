import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { IAnyPropertyNameAndAnyValue, IState } from "../../types/common.types";
import initDataGuard from "./initDataGuard";
import { Guards, ROLES } from "../../constants/common.constants";
import Permissions from "../../permissions/permissions";
import { permissionsGuard } from "./permissionsGuard";
import { SETTINGS_URL } from "../../constants/urls.constants";
import { buildUrl } from "../../utils/string.utils";

const useGuard = (guards: Guards[], component: any) => {
  const location = useLocation();
  const { loggedIn } = useSelector(({ user }: any) => user);
  const { profile } = useSelector(({ user }: IState) => user);
  const { Pages, Organizations } = Permissions();
  const canSeeIncidentCompleteDetails =
    profile.role === ROLES.mdrAdmin || profile.role === ROLES.mdrUser;
  const canSeeUsers: boolean = Pages.users.canNavigate(profile.role);
  const canSeeOrgList: boolean = Pages.organizations.canNavigate(profile.role);
  const canCreateAnOrg = Organizations.sections.actionBar.canCreate(
    profile.role
  );

  const guardsList: IAnyPropertyNameAndAnyValue = {
    initData: () => initDataGuard(profile, location, loggedIn),
    incidentCompleteDetails: () =>
      permissionsGuard(canSeeIncidentCompleteDetails, component),
    organizationsList: () => permissionsGuard(canSeeOrgList, component),
    createOrganization: () => permissionsGuard(canCreateAnOrg, component),
    usersList: () => permissionsGuard(canSeeUsers, component),
    userDetails: () => permissionsGuard(canSeeUsers, component)
  };

  let finalComponent: React.JSX.Element = component;

  if (loggedIn && !guardsList.initData())
    return <Navigate to={buildUrl(SETTINGS_URL)} replace />;

  guards.forEach((guard: Guards) => {
    finalComponent = guardsList[guard]();
  });

  return finalComponent;
};

export default useGuard;
