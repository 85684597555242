import React, { useContext, useEffect } from "react";
import "./addEvent.scss";
import { Field } from "formik";
import { WIDTH, ADD_EVENT_FORM_ID } from "../../../constants/common.constants";
import ModalContext from "../../../contexts/modal.context";
import Input from "../../../components/input/input";
import { IAddEventProps } from "../incidentActivity.types";

export default function AddEvent({
  formik: { handleSubmit, valid, handleChange, values, errors, touched }
}: IAddEventProps) {
  const { setActionDisabled } = useContext(ModalContext);

  useEffect(() => setActionDisabled(valid), [setActionDisabled, valid]);

  return (
    <form onSubmit={handleSubmit} id={ADD_EVENT_FORM_ID}>
      <h5 className="addEventContainer__title">
        Add an event from LimaCharlie. Copy the value of{" "}
        <span className="code">routing/this</span> and paste into the text box
        below.
      </h5>
      <Field
        label="This"
        name="event_id"
        error={errors.event_id}
        onChange={handleChange}
        value={values.event_id}
        width={WIDTH.max}
        touched={touched.event_id}
        component={Input}
      />
    </form>
  );
}
