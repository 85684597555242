import React, {
  useCallback,
  createContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useContext
} from "react";
import { useSelector } from "react-redux";
import { SELECT_CLEAR_VALUE } from "../constants/common.constants";
import { useGetSensorsFilters } from "../api/sensors";
import { sensorsFiltersMapper } from "../mappers/sensorsMappers";
import OrganizationsContext from "./organizations.context";
import Permissions from "../permissions/permissions";
import {
  IAnyPropertyNameAndStringValue,
  IFilterOption,
  IProvider
} from "../types/common.types";
import {
  ISensorsAvailableFilters,
  ISensorsCurrentFilters,
  ISensorsListFiltersContext
} from "./types/sensors-list.types";
import useIsMounted from "../hooks/useIsMounted";

const SensorsListfiltersContext = createContext<ISensorsListFiltersContext>(
  {} as ISensorsListFiltersContext
);

export function SensorsListFiltersProvider({ children }: IProvider) {
  const isMounted = useIsMounted();
  const { mappedOrganizationsList } = useContext(OrganizationsContext);
  const getAvailableFilters = useGetSensorsFilters();
  const localUser = useSelector((state: any) => state.user.profile);
  const [searchText, setSearchText] = useState("");
  const orgId: string = useSelector((state: any) => state.user.orgId);
  const [organization, setOrganization] = useState<IFilterOption>({
    id: orgId,
    caption: mappedOrganizationsList[orgId]
  });
  const searchTextDebounce = useRef<any>(null);
  const firstTimeSwSearchText = useRef<boolean>(false);
  const currentFiltersInitialState: ISensorsCurrentFilters = useMemo(
    () => ({
      os: { id: null, caption: SELECT_CLEAR_VALUE },
      status: { id: null, caption: SELECT_CLEAR_VALUE },
      tags: { id: null, caption: SELECT_CLEAR_VALUE }
    }),
    []
  );
  const availableFiltersInitialState: ISensorsAvailableFilters = useMemo(
    () => ({
      tags: {},
      status: {}
    }),
    []
  );
  const { sensors: permissions } = Permissions();
  const allowOrgSelect: boolean = useMemo(
    () => permissions.sections.actionBar.selectOrg.canSee(localUser.role),
    [localUser.role, permissions.sections.actionBar.selectOrg]
  );
  const [availableFilters, setAvailableFilters] =
    useState<ISensorsAvailableFilters>(availableFiltersInitialState);
  const [availableOSs, setAvailableOSs] =
    useState<IAnyPropertyNameAndStringValue>({});
  const [currentFilters, setCurrentFilters] = useState<ISensorsCurrentFilters>(
    currentFiltersInitialState
  );
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const handleSearch = useCallback((text: string) => setSearchText(text), []);

  const updateFilters = useCallback(async () => {
    try {
      const filters = await getAvailableFilters(`${organization.id}`);
      if (!isMounted.current) return;
      setAvailableFilters(sensorsFiltersMapper(filters));
    } catch (error: any) {
      console.error(
        `Error getting incidents filters. Status ${error.status}. ${error}`
      );
    }
  }, [getAvailableFilters, isMounted, organization.id]);

  const toggleShowFilters = useCallback(
    () => setShowFilters(!showFilters),
    [showFilters]
  );

  const forceCloseFilters = useCallback(() => setShowFilters(false), []);

  const setOrganizationFilter = useCallback(
    (organizationId: string) => {
      setOrganization({
        id: organizationId,
        caption: mappedOrganizationsList[organizationId]
      });
    },
    [mappedOrganizationsList]
  );

  const setOSFilter = useCallback(
    (os: string) => {
      setCurrentFilters({
        ...currentFilters,
        os: {
          id: os,
          caption: os ? availableOSs[os] : SELECT_CLEAR_VALUE
        }
      });
    },
    [availableOSs, currentFilters]
  );

  const setTagsFilter = useCallback(
    (tag: string) => {
      setCurrentFilters({
        ...currentFilters,
        tags: {
          id: tag,
          caption: tag ? availableFilters?.tags[tag] : SELECT_CLEAR_VALUE
        }
      });
    },
    [availableFilters?.tags, currentFilters]
  );

  const setStatusFilter = useCallback(
    (status: string) => {
      setCurrentFilters({
        ...currentFilters,
        status: {
          id: status,
          caption: status
            ? availableFilters?.status[status]
            : SELECT_CLEAR_VALUE
        }
      });
    },
    [availableFilters, currentFilters]
  );

  const resetFilters = useCallback(() => {
    setCurrentFilters(currentFiltersInitialState);
    if (searchText) setSearchText("");
  }, [currentFiltersInitialState, searchText]);

  useEffect(() => {
    if (firstTimeSwSearchText.current)
      searchTextDebounce.current = setTimeout(() => {}, 500);
    else firstTimeSwSearchText.current = true;
    return () => clearTimeout(searchTextDebounce.current);
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setOrganizationFilter(orgId), [orgId, setOrganizationFilter]);

  const returnedInformation: ISensorsListFiltersContext = useMemo(
    () => ({
      availableFilters,
      currentFilters,
      showFilters,
      searchText,
      organization,
      mappedOrganizationsList,
      allowOrgSelect,
      availableOSs,
      setOrganizationFilter,
      setOSFilter,
      setTagsFilter,
      setStatusFilter,
      resetFilters,
      toggleShowFilters,
      forceCloseFilters,
      updateFilters,
      handleSearch,
      setAvailableOSs
    }),
    [
      availableFilters,
      currentFilters,
      showFilters,
      searchText,
      organization,
      mappedOrganizationsList,
      allowOrgSelect,
      availableOSs,
      setOrganizationFilter,
      setOSFilter,
      setTagsFilter,
      setStatusFilter,
      resetFilters,
      toggleShowFilters,
      forceCloseFilters,
      updateFilters,
      handleSearch
    ]
  );

  return (
    <SensorsListfiltersContext.Provider value={returnedInformation}>
      {children}
    </SensorsListfiltersContext.Provider>
  );
}

export default SensorsListfiltersContext;
