import React, { useContext, useRef } from "react";
import "./codeBlock.scss";
import { COPY_ICON } from "../../constants/common.constants";
import SnackbarContext from "../../contexts/snackbar.context";
import Icon from "../icon/icon";
import Tooltip from "../tooltip/tooltip";

type ICodeBlockProps = { data: string };

export default function CodeBlock({ data }: ICodeBlockProps) {
  const { showSnackbar } = useContext(SnackbarContext);

  const textRef = useRef<any>(null);

  const onClickCopy = () => {
    const text = textRef.current.innerText;

    navigator.clipboard
      .writeText(text)
      .then(() =>
        showSnackbar({
          text: `Copied to clipboard`,
          type: "info",
          icon: COPY_ICON
        })
      )
      .catch((reason) => {
        console.error(`Error copying line to clipboard.`, reason);
        showSnackbar({
          text: `Error copying to clipboard`,
          type: "error",
          icon: COPY_ICON
        });
      });
  };

  return (
    <div className="has-tooltip codeBlockContainer">
      <p className="codeBlockContainer__text" ref={textRef}>
        {data}
      </p>
      <div className="codeBlockContainer__copy">
        <Tooltip text="Copy to clipboard" right />
        <Icon image={COPY_ICON} alt="copy" onClick={onClickCopy} />
      </div>
    </div>
  );
}
