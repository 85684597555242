import React, { useContext, useEffect, useMemo, useRef } from "react";
import "./avatarMenu.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SETTINGS_WHITE_ICON,
  LOGOUT_ICON
} from "../../../constants/common.constants";
import Avatar from "../../avatar/avatar";
import { setOrgName } from "../../../settings/userSlice";
import Menu, { IMenuItem } from "../../menu/menu";
import { useGetOrganization } from "../../../api/organizations";
import LoginContext from "../../../contexts/session.context";
import getInitials, { buildUrl } from "../../../utils/string.utils";
import { SETTINGS_URL } from "../../../constants/urls.constants";

type IProps = { user: string };

export default function AvatarMenu({ user }: IProps) {
  const getOrganization = useGetOrganization();
  const getOrgNameRef = useRef(false);
  const { profile, orgId, orgName } = useSelector((state: any) => state.user);
  const { handleLogOut } = useContext(LoginContext);

  const menuItems: IMenuItem[] = useMemo(
    () => [
      {
        id: "avatar",
        value: profile.name || "",
        subValue: orgName,
        initials: getInitials(profile.name)
      },
      { id: "__divider" },
      { id: "settings", value: "Settings", icon: SETTINGS_WHITE_ICON },
      { id: "__divider" },
      { id: "logOut", value: "Log out", icon: LOGOUT_ICON }
    ],
    [orgName, profile]
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const actions: { [key: string]: any } = useMemo(
    () => ({
      avatar: () => navigate(buildUrl(SETTINGS_URL)),
      settings: () => navigate(buildUrl(SETTINGS_URL)),
      logOut: handleLogOut
    }),
    [handleLogOut, navigate]
  );

  const onMenuItemSelected = (menuItem: string) => actions[menuItem]();

  useEffect(() => {
    if (!getOrgNameRef.current && orgId && !orgName) {
      getOrgNameRef.current = true;
      getOrganization(orgId).then((organization) => {
        dispatch(setOrgName(organization.name));
        getOrgNameRef.current = false;
      });
    }
  }, [orgId, dispatch, orgName, getOrganization]);

  return (
    <nav className="avatarMenuContainer">
      <Menu items={menuItems} clickAction={onMenuItemSelected} left>
        <Avatar text={user} />
      </Menu>
    </nav>
  );
}
