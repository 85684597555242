import React, { useEffect, useMemo, useRef, useState } from "react";
import "./textArea.scss";
import {
  SKELETON_VARIANT,
  STATUS,
  WIDTH
} from "../../constants/common.constants";
import SkeletonLoader from "../skeleton/skeleton-loader";

type IProps = {
  width: string | number;
  rows?: undefined | number;
  label?: string;
  error?: string;
  focus?: boolean;
  placeholder?: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
  onChange: any;
  onKeyDown?: any;
  readOnly?: boolean;
  isLoading?: boolean;
  touched?: boolean;
  name?: string;
  field?: any;
  form?: any;
};

export default function TextArea(props: IProps) {
  const {
    width,
    rows,
    label,
    focus,
    placeholder,
    value,
    required,
    disabled,
    onChange,
    onKeyDown,
    readOnly,
    isLoading = false,
    error = "",
    touched = false,
    name,
    field,
    form
  } = props;

  const widthModifier =
    !width || width === WIDTH.exact ? `width${WIDTH.default}` : `width${width}`;

  const containerClassRoot = "textAreaContainer";
  const containerClassName = `${containerClassRoot} ${containerClassRoot}--${widthModifier}`;

  const textClassRoot = "textAreaContainer__text";
  let textClassName = textClassRoot;
  textClassName += ` ${textClassRoot}--${widthModifier}`;
  textClassName += rows ? "" : ` ${textClassRoot}--heightDefault`;

  const id = label ? crypto.randomUUID() : undefined;

  const [textAreaClass, setTextAreaClass] = useState<string>(textClassName);

  const hasError = useMemo(() => touched && !!error, [error, touched]);

  useEffect(() => {
    setTextAreaClass(
      hasError
        ? `${textClassName} ${textClassRoot}--${STATUS.error}`
        : textClassName
    );
  }, [hasError, textClassName]);

  const textRef = useRef<any>(null);

  useEffect(() => {
    if (focus) {
      textRef.current.focus();
    } else if (!readOnly) {
      textRef.current.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);

  return (
    <div className={containerClassName}>
      {label && <label htmlFor={id}>{label}</label>}
      <SkeletonLoader
        loadingVar={isLoading}
        variant={SKELETON_VARIANT.textArea}
      >
        {readOnly ? (
          <p className="read-only">{value}</p>
        ) : (
          <textarea
            className={textAreaClass}
            id={id}
            ref={textRef}
            placeholder={placeholder}
            rows={rows}
            value={value}
            required={required}
            disabled={disabled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            name={name || field?.name}
            onBlur={() => !!form && form.setFieldTouched(field?.name, true)}
          />
        )}
      </SkeletonLoader>
      {hasError && <p className={`${containerClassRoot}__error`}>{error}</p>}
    </div>
  );
}
