/* eslint-disable no-restricted-syntax */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { IncidentContext } from "../incident";
import { useGetEvents } from "../../api/events";
import {
  useAddComment,
  useDeleteComment,
  useGetComments,
  useEditComment
} from "../../api/comments";
import { INCIDENTS_EVENTS } from "../../constants/common.constants";
import FormatDate from "../../formatDate/formatDate";
import { IEvent } from "../../event/event.types";
import { IComment } from "./incidentActivity.types";

export default function useIncidentActivityTimeline() {
  const { id } = useContext(IncidentContext);
  const getEvents = useGetEvents();
  const getComments = useGetComments();
  const addComment = useAddComment();
  const deleteComment = useDeleteComment();
  const editComment = useEditComment();

  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [commentsApi, setCommentsApi] = useState<IComment[]>([]);
  const [eventsApi, setEventsApi] = useState<IEvent[]>([]);
  const [nextPageEventsToken, setNextPageEventsToken] = useState("");
  const [nextPageCommentsToken, setNextPageCommentsToken] = useState("");

  const loadEventsInProgress = useRef(false);
  const loadCommentsInProgress = useRef(false);

  const loadEvents = useCallback(
    async (reset = false) => {
      if (loadEventsInProgress.current) {
        return 0;
      }

      try {
        loadEventsInProgress.current = true;
        setIsLoadingEvents(true);
        const eventsResponse = await getEvents(
          id,
          reset ? "" : nextPageEventsToken
        );
        const eventsData = await eventsResponse.json();
        setNextPageEventsToken(
          eventsResponse.headers.get("next-page-token") || ""
        );
        setEventsApi(
          reset
            ? eventsData
            : [
                ...eventsApi,
                ...eventsData.filter(
                  ({ event_id }: IEvent) =>
                    !eventsApi.some(
                      (event: IEvent) => event.event_id === event_id
                    )
                )
              ]
        );
        setIsLoadingEvents(false);
        loadEventsInProgress.current = false;
      } catch (error: any) {
        console.error(
          `Error getting events of incident ${id}. Status ${error.status}. ${error}`
        );
        setIsLoadingEvents(false);
        loadEventsInProgress.current = false;
      }
      return 0;
    },
    [getEvents, id, nextPageEventsToken, eventsApi]
  );

  const loadComments = useCallback(
    async (reset = false) => {
      if (loadCommentsInProgress.current) {
        return 0;
      }

      try {
        loadCommentsInProgress.current = true;
        setIsLoadingComments(true);
        const commentsResponse = await getComments(
          id,
          reset ? "" : nextPageCommentsToken
        );
        const commentsData = await commentsResponse.json();
        setNextPageCommentsToken(
          commentsResponse.headers.get("next-page-token") || ""
        );
        setCommentsApi(
          reset
            ? commentsData
            : [
                ...commentsApi,
                ...commentsData.filter(
                  ({ comment_id }: IComment) =>
                    !commentsApi.some(
                      (comment) => comment.comment_id === comment_id
                    )
                )
              ]
        );
        setIsLoadingComments(false);
        loadCommentsInProgress.current = false;
      } catch (error: any) {
        console.error(
          `Error getting comments of incident ${id}. Status ${error.status}. ${error}`
        );
        setIsLoadingComments(false);
        loadCommentsInProgress.current = false;
      }
      return 0;
    },
    [getComments, id, nextPageCommentsToken, commentsApi]
  );

  const dataApi: any = useMemo(() => {
    if (commentsApi.length && eventsApi.length) {
      return [...commentsApi, ...eventsApi];
    }
    if (commentsApi.length) {
      return [...commentsApi];
    }
    if (eventsApi.length) {
      return [...eventsApi];
    }
    return [];
  }, [commentsApi, eventsApi]);

  const timeline = useMemo(() => {
    const todayTimestamp = new FormatDate();

    const todayDate = todayTimestamp.date;
    const data: any = {};

    for (const event of dataApi) {
      const isEvent = !!event.event_type;
      const timestamp = new FormatDate(event.created, !event.event_type);

      const { dayOfWeek, date, epochDate } = timestamp;

      const eventData = {
        type: isEvent ? event.event_type : INCIDENTS_EVENTS.COMMENTS.id,
        data: event
      };
      if (Reflect.has(data, epochDate)) {
        if (isEvent) {
          if (Reflect.has(data[epochDate], "events")) {
            data[epochDate].events.push(eventData);
          } else {
            data[epochDate].events = [eventData];
          }
        } else if (Reflect.has(data[epochDate], "comments")) {
          data[epochDate].comments.push(eventData);
        } else {
          data[epochDate].comments = [eventData];
        }
      } else {
        data[epochDate] = {
          day: todayDate === date ? "Today" : dayOfWeek,
          date
        };

        if (isEvent) {
          data[epochDate].events = [eventData];
        } else {
          data[epochDate].comments = [eventData];
        }
      }
    }
    return data;
  }, [dataApi]);

  const handleNewComment = async (comment: string) => {
    try {
      const newCommentData = await addComment(id, comment);
      setCommentsApi([...commentsApi, ...[newCommentData]]);
      return true;
    } catch (error: any) {
      console.error(
        `Error adding comment to incident ${id}. Status ${error.status}. ${error}`
      );
      return false;
    }
  };

  const handleEditComment = async (comment: string, commentId: string) => {
    try {
      await editComment(id, commentId, comment);
      setCommentsApi(
        commentsApi.map((item) =>
          item.comment_id === commentId ? { ...item, comment } : item
        )
      );
      return true;
    } catch (error: any) {
      console.error(
        `Error updating comment ${commentId} of incident ${id}. Status ${error.status}. ${error}`
      );
      return false;
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    try {
      await deleteComment(id, commentId);
      setCommentsApi(
        commentsApi.filter(({ comment_id }) => comment_id !== commentId)
      );
      return true;
    } catch (error: any) {
      console.error(
        `Error deleting comment ${commentId} of incident ${id}. Status ${error.status}. ${error}`
      );
      return false;
    }
  };

  const handleNewEvent = (event: IEvent) =>
    setEventsApi([...eventsApi, ...[event]]);

  useEffect(() => {
    loadEvents(true);
    loadComments(true);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    timeline,
    handleNewComment,
    handleDeleteComment,
    handleEditComment,
    handleNewEvent,
    loadEvents,
    loadComments,
    isLoadingEvents,
    isLoadingComments,
    endOfEventsPages: !nextPageEventsToken,
    endOfCommentsPages: !nextPageCommentsToken
  };
}
