/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from "react";
import "./incidentHeader.scss";
import { NavLink } from "react-router-dom";
import Header from "../../components/header/header";
import Icon from "../../components/icon/icon";
import IncidentHeaderActionBarMdrUser from "./incidentHeaderActionBarMdrUser";
import IncidentHeaderActionBarClientUser from "./incidentHeaderActionBarClientUser";
import { IIncidentHeaderActionBarMdrUserProps } from "./incidentHeader.types";
import { INCIDENTS_URL } from "../../constants/urls.constants";
import { buildUrl } from "../../utils/string.utils";
import SessionContext from "../../contexts/session.context";

export default function IncidentHeader(
  props: IIncidentHeaderActionBarMdrUserProps
) {
  const { isMdrRole } = useContext(SessionContext);

  const incidentsIcon = `${process.env.REACT_APP_CDN_IMAGES}/incidents.svg`;
  const headerIcon = <Icon image={incidentsIcon} alt="Incident" />;

  const getLinkClassName = ({ isActive }: any) =>
    isActive ? "navLink navLink--active" : "navLink navLink--inactive";

  return (
    <div className="incidentContainer__header">
      <Header icon={headerIcon} isLoading={props.isLoadingData}>
        <>
          <NavLink to={buildUrl(INCIDENTS_URL)} className={getLinkClassName}>
            Incidents
          </NavLink>
          <span className="incidentContainer__separator">{">"}</span>
          <h3>{props.title}</h3>
          {isMdrRole ? (
            <IncidentHeaderActionBarMdrUser {...props} />
          ) : (
            <IncidentHeaderActionBarClientUser {...props} />
          )}
        </>
      </Header>
    </div>
  );
}
