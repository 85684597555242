import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import useTableInstance from "../components/table/useTableInstance";
import SensorsListfiltersContext from "./sensors-list-filters.context";
import { useGetSensors } from "../api/sensors";
import useSensorsTableColumns from "../sensors-list/components/useSensorsListTableColumns";
import {
  sensorsListMapper,
  sensorsListParametersMapper
} from "../mappers/sensorsMappers";
import SnackbarContext from "./snackbar.context";
import {
  ERROR_ICON,
  LIMA_CHARLIE_LABEL,
  MESSAGE,
  TABLE
} from "../constants/common.constants";
import {
  IAnyPropertyNameAndStringValue,
  IProvider
} from "../types/common.types";
import { ISensor, ISensorsListContext } from "./types/sensors-list.types";
import useIsMounted from "../hooks/useIsMounted";

const SensorsListContext = createContext<ISensorsListContext>(
  {} as ISensorsListContext
);

const SENSORS_LIST_CURRENT_SORT = "sensorsListCurrentSort";
const SENSORS_LIST_CURRENT_SORT_COLUMN = "sensorsListCurrentSortColumn";

export function SensorsListProvider({ children }: IProvider) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [sensorsList, setSensorsList] = useState<ISensor[]>([]);
  const [filteredSensorsList, setFilteredSensorsList] = useState<ISensor[]>([]);
  const [currentSort, setCurrentSort] = useState({
    orientation: -1,
    column: TABLE.SENSORS.CELLS.lastConnection.accessor
  });
  const firstTimeSw = useRef(false);

  const { showSnackbar } = useContext(SnackbarContext);
  const get = useGetSensors();
  const toggleSort = useCallback(
    (currentValue: number, currentColumn: string) => {
      setCurrentSort({ orientation: currentValue * -1, column: currentColumn });
    },
    []
  );
  const columns = useSensorsTableColumns(toggleSort);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableColumns = useMemo(() => columns, []);
  const table = useTableInstance(tableColumns, filteredSensorsList);

  const {
    forceCloseFilters,
    updateFilters,
    organization,
    currentFilters,
    searchText,
    setAvailableOSs
  } = useContext(SensorsListfiltersContext);

  const sortFunctions = useCallback(() => {
    const sensorsListCurrentSort = parseInt(
      `${localStorage.getItem(SENSORS_LIST_CURRENT_SORT)}`,
      10
    );
    return {
      [TABLE.SENSORS.CELLS.lastConnection.accessor]: (
        a: ISensor,
        b: ISensor
      ) => {
        const dateA = new Date(a.last_connection).getTime();
        const dateB = new Date(b.last_connection).getTime();
        return (dateA - dateB) * sensorsListCurrentSort;
      },
      [TABLE.SENSORS.CELLS.type.accessor]: (a: ISensor, b: ISensor) => {
        const valueA = a.type || LIMA_CHARLIE_LABEL;
        const valueb = b.type || LIMA_CHARLIE_LABEL;
        return valueA < valueb
          ? sensorsListCurrentSort * -1
          : sensorsListCurrentSort * 1;
      },
      [TABLE.SENSORS.CELLS.os.accessor]: (a: ISensor, b: ISensor) =>
        a.os < b.os ? sensorsListCurrentSort * -1 : sensorsListCurrentSort * 1,
      [TABLE.SENSORS.CELLS.hostname.accessor]: (a: ISensor, b: ISensor) =>
        a.Hostname < b.Hostname
          ? sensorsListCurrentSort * -1
          : sensorsListCurrentSort * 1,
      [TABLE.SENSORS.CELLS.internalIp.accessor]: (a: ISensor, b: ISensor) => {
        const valueA = Number(
          a?.internal_ip
            .split(".")
            .map((subString) => `00${subString}`.slice(-3))
            .join("")
        );
        const valueB = Number(
          b?.internal_ip
            .split(".")
            .map((subString) => `00${subString}`.slice(-3))
            .join("")
        );
        return valueA < valueB
          ? sensorsListCurrentSort * -1
          : sensorsListCurrentSort * 1;
      },
      [TABLE.SENSORS.CELLS.externalIp.accessor]: (a: ISensor, b: ISensor) => {
        const valueA = Number(
          a?.external_ip
            .split(".")
            .map((subString) => `00${subString}`.slice(-3))
            .join("")
        );
        const valueB = Number(
          b?.external_ip
            .split(".")
            .map((subString) => `00${subString}`.slice(-3))
            .join("")
        );
        return valueA < valueB
          ? sensorsListCurrentSort * -1
          : sensorsListCurrentSort * 1;
      },
      [TABLE.SENSORS.CELLS.installed.accessor]: (a: ISensor, b: ISensor) => {
        const dateA = new Date(a.installed).getTime();
        const dateB = new Date(b.installed).getTime();
        return (dateA - dateB) * sensorsListCurrentSort;
      },
      [TABLE.SENSORS.CELLS.status.accessor]: (a: ISensor, b: ISensor) =>
        a.status < b.status
          ? sensorsListCurrentSort * -1
          : sensorsListCurrentSort * 1
    };
  }, []);

  const sortSensors = useCallback(
    (list: ISensor[]) => {
      const sensorsListCurrentSortColumn = `${localStorage.getItem(
        SENSORS_LIST_CURRENT_SORT_COLUMN
      )}`;
      return list.sort(sortFunctions()[sensorsListCurrentSortColumn]);
    },
    [sortFunctions]
  );

  const load = useCallback(
    async (token = "", currentList: ISensor[] = []) => {
      setLoading(true);

      try {
        const response = await get(
          `${organization.id}`,
          sensorsListParametersMapper(token)
        );
        if (!isMounted.current) return;
        const { headers } = response;
        const nextToken = headers.get("x-next-page");

        const jsonData = await response.json();
        if (!isMounted.current) return;
        const apiData = jsonData || [];
        const updatedList: ISensor[] = sortSensors([
          ...currentList,
          ...sensorsListMapper(apiData)
        ]);
        const osList: IAnyPropertyNameAndStringValue = {};
        updatedList.forEach(({ os_id, os }) => {
          if (os && !Object.hasOwn(osList, os_id)) osList[os_id] = os;
        });
        setAvailableOSs(osList);
        setSensorsList(updatedList);
        if (nextToken) load(nextToken, updatedList);
        else setLoading(false);
        firstTimeSw.current = true;
      } catch (error: any) {
        showSnackbar({
          text: `Error retrieving sensors`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        console.error(
          `Error retrieving sensors. Status ${error.status}. ${error}`
        );
        setLoading(false);
      }
    },
    [
      get,
      isMounted,
      organization.id,
      setAvailableOSs,
      showSnackbar,
      sortSensors
    ]
  );

  useEffect(() => {
    if (firstTimeSw.current) {
      setSensorsList([]);
      load();
      updateFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    localStorage.setItem(SENSORS_LIST_CURRENT_SORT, "-1");
    localStorage.setItem(
      SENSORS_LIST_CURRENT_SORT_COLUMN,
      TABLE.SENSORS.CELLS.lastConnection.accessor
    );
    forceCloseFilters();
    load();
    updateFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(
      SENSORS_LIST_CURRENT_SORT,
      currentSort.orientation.toString()
    );
    localStorage.setItem(SENSORS_LIST_CURRENT_SORT_COLUMN, currentSort.column);
    setSensorsList([...sortSensors(sensorsList)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSort]);

  useEffect(() => {
    setFilteredSensorsList(
      sensorsList.filter(
        ({ os_id, status, tags, Hostname, internal_ip, external_ip }) =>
          (currentFilters.os.id === null || os_id === currentFilters.os.id) &&
          (currentFilters.tags.id === null ||
            tags?.includes(currentFilters.tags.id)) &&
          (currentFilters.status.id === null ||
            status === currentFilters.status.id) &&
          (Hostname.includes(searchText) ||
            internal_ip.includes(searchText) ||
            external_ip.includes(searchText) ||
            tags?.some((tag) => tag.includes(searchText)))
      )
    );
  }, [currentFilters, sensorsList, searchText]);

  const returnedValue: ISensorsListContext = useMemo(
    () => ({
      columns,
      table,
      loading,
      sensorsList,
      filteredSensorsList,
      currentSort,
      load
    }),
    [
      columns,
      table,
      loading,
      sensorsList,
      filteredSensorsList,
      currentSort,
      load
    ]
  );

  return (
    <SensorsListContext.Provider value={returnedValue}>
      {children}
    </SensorsListContext.Provider>
  );
}

export default SensorsListContext;
