import React, {
  useMemo,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LogRocket from "logrocket";
import { useGetUser } from "../api/users";
import { useGetOrgIdFromUsername } from "../api/organizations";
import SnackbarContext from "./snackbar.context";
import {
  ADMIN_ROLES,
  MESSAGE,
  REDIRECT_SESSION_STORAGE,
  ROLES,
  USER_ICON
} from "../constants/common.constants";
import { logIn, logOut, setOrgId, setToken } from "../settings/userSlice";
import { IProvider } from "../types/common.types";
import { ISessionContext } from "./types/login.types";
import { ISnackbarContext } from "./types/snackbar.types";
import { LOGOUT_URL } from "../constants/urls.constants";
import getInitials, { buildUrl } from "../utils/string.utils";

const SessionContext = createContext<ISessionContext>({} as ISessionContext);

export function SessionProvider({ children }: IProvider) {
  const audience: string = process.env.REACT_APP_AUTH0_MDR_AUDIENCE || "";
  const location = useLocation();
  const loginRef = useRef<boolean>(false);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [isOrgLoading, setIsOrgLoading] = useState<boolean>(false);
  const [isLoggingInProcess, setIsLoggingInProcess] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string>("");

  const { token, profile } = useSelector((state: any) => state.user);

  const getUserProfile = useGetUser();
  const getOrgIdFromUsername = useGetOrgIdFromUsername();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const code: string = useSearchParams()[0].get("code") || "";
  const {
    loginWithRedirect,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    isLoading
  } = useAuth0();

  const initials = useMemo(() => getInitials(profile.name), [profile.name]);

  const { showSnackbar }: ISnackbarContext = useContext(SnackbarContext);

  const isLogging: boolean = useMemo(
    () => isLoading || isLoggingInProcess,
    [isLoading, isLoggingInProcess]
  );

  // It is safe to continue with the token and Redux update once the user is authenticated and the React SDK is fully loaded
  const authenticationComplete: boolean = useMemo(
    () => isAuthenticated && !isLoading,
    [isAuthenticated, isLoading]
  );

  const isMdrRole = useMemo(
    () => profile.role === ROLES.mdrAdmin || profile.role === ROLES.mdrUser,
    [profile.role]
  );

  const isProviderRole = useMemo(
    () =>
      profile.role === ROLES.providerAdmin ||
      profile.role === ROLES.providerUser,
    [profile.role]
  );

  const isMdrAdmin = useMemo(
    () => profile.role === ROLES.mdrAdmin,
    [profile.role]
  );

  const isClientAdmin = useMemo(
    () => profile.role === ROLES.clientAdmin,
    [profile.role]
  );

  const isProviderAdmin = useMemo(
    () => profile.role === ROLES.providerAdmin,
    [profile.role]
  );

  const isAdmin = useMemo(
    () => ADMIN_ROLES.includes(profile.role),
    [profile.role]
  );

  const sendLoginRequest: (login_hint: string) => void = useCallback(
    async (login_hint: string) => {
      try {
        setIsOrgLoading(true);
        const org = await getOrgIdFromUsername(login_hint);
        loginWithRedirect({
          authorizationParams: {
            login_hint,
            organization: org.id
          }
        });
      } catch (error: any) {
        setIsOrgLoading(false);
        if (error.status === 400 || error.status === 404) {
          setRequestError(true);
        } else {
          showSnackbar({
            text: "Error retrieving organization",
            type: MESSAGE.error,
            icon: USER_ICON
          });
        }
      }
    },
    [getOrgIdFromUsername, loginWithRedirect, showSnackbar]
  );

  const handleLogOut: () => void = useCallback(() => {
    sessionStorage.setItem(REDIRECT_SESSION_STORAGE, location.pathname);
    dispatch(logOut());
    navigate(buildUrl(LOGOUT_URL));
  }, [dispatch, location.pathname, navigate]);

  const handleLogin: () => void = useCallback(async () => {
    try {
      setIsLoggingInProcess(true);
      dispatch(setOrgId(user?.org_id));
      LogRocket.identify(user?.org_id, {
        name: user?.name || "",
        email: user?.email || ""
      });
      const appToken = await getAccessTokenSilently({
        authorizationParams: { audience }
      });
      dispatch(setToken(appToken));
      const userData = await getUserProfile(
        user?.sub || "",
        user?.org_id,
        appToken
      );
      dispatch(logIn(userData));
      setIsLoggingInProcess(false);
    } catch (e: any) {
      console.error("Error getting token.", e.message);
      setStatusMessage("An error was found during login");
    }
  }, [
    audience,
    dispatch,
    getAccessTokenSilently,
    getUserProfile,
    user?.email,
    user?.name,
    user?.org_id,
    user?.sub
  ]);

  useEffect(() => {
    if (authenticationComplete && loginRef.current) handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationComplete]);

  useEffect(() => {
    if (code) loginRef.current = true;
  }, [code]);

  const returnedValue: ISessionContext = useMemo(
    () => ({
      requestError,
      isOrgLoading,
      token,
      user,
      isAuthenticated,
      isLogging,
      statusMessage,
      initials,
      isMdrRole,
      isAdmin,
      isMdrAdmin,
      isProviderAdmin,
      isProviderRole,
      isClientAdmin,
      getAccessTokenSilently,
      getUserProfile,
      sendLoginRequest,
      handleLogOut
    }),
    [
      requestError,
      isOrgLoading,
      token,
      user,
      isAuthenticated,
      isLogging,
      statusMessage,
      initials,
      isMdrRole,
      isAdmin,
      isMdrAdmin,
      isProviderAdmin,
      isProviderRole,
      isClientAdmin,
      getAccessTokenSilently,
      getUserProfile,
      sendLoginRequest,
      handleLogOut
    ]
  );

  return (
    <SessionContext.Provider value={returnedValue}>
      {children}
    </SessionContext.Provider>
  );
}

export default SessionContext;
