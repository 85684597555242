import React, { useCallback, useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import useSendInvitation from "../../api/invitation";
import {
  EMAIL_REGEX,
  ERROR_ICON,
  MESSAGE,
  USER_ICON
} from "../../constants/common.constants";
import ModalContext from "../../contexts/modal.context";
import SnackbarContext from "../../contexts/snackbar.context";
import AddUserForm from "./addUser";
import { IInvitation } from "../users.types";
import { rolesMapper } from "../../mappers/userMappers";
import useRoles from "../../hooks/useRoles";

export default function useAddUser() {
  const { showModal, closeModal, setAsyncLoading } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const sendInvitation = useSendInvitation();
  const { clientRoles, soteriaRoles } = useRoles();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email")
      .matches(EMAIL_REGEX, "Invalid email"),
    role: Yup.string().required("Role is required")
  });

  const onSubmit = useCallback(
    async ({ email, role }: any, orgId: string, reloadUsers: any) => {
      try {
        const invitation: IInvitation = {
          client_id: `${process.env.REACT_APP_AUTH0_MDR_APP_CLIENT_ID}`,
          email,
          org_id: orgId,
          role_id: role
        };
        setAsyncLoading(true);
        const message = await sendInvitation(invitation);
        setAsyncLoading(false);
        closeModal();
        showSnackbar({ text: message, type: MESSAGE.info, icon: USER_ICON });
        reloadUsers();
      } catch (error: any) {
        console.error(
          `Error sending invitation to ${email}. Status ${error.status}. ${error}`
        );
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: `Error sending invitation to ${email}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
      }
    },
    [closeModal, sendInvitation, setAsyncLoading, showSnackbar]
  );

  const showAddUserModal = useCallback(
    (orgId: string, firstUser: boolean, reloadUsers: any) => {
      const roles =
        orgId === process.env.REACT_APP_SOTERIA_ORG_ID
          ? rolesMapper(soteriaRoles, firstUser)
          : rolesMapper(clientRoles, firstUser);

      showModal({
        title: "Add user",
        actionText: "Send invite",
        isActionDisabled: true,
        content: (
          <Formik
            initialValues={{ email: "", role: "" }}
            onSubmit={(values: any) => onSubmit(values, orgId, reloadUsers)}
            validationSchema={validationSchema}
            validateOnMount
          >
            {(formik) => <AddUserForm roles={roles} formik={formik} />}
          </Formik>
        ),
        formId: "addUserForm"
      });
    },
    [showModal, validationSchema, soteriaRoles, clientRoles, onSubmit]
  );

  return showAddUserModal;
}
