import React, { useContext } from "react";
import "./modal.scss";
import Button from "../button/button";
import { VARIANT } from "../../constants/common.constants";
import ModalContext from "../../contexts/modal.context";
import { IModalFooter } from "./modal.types";
import { IModalContext } from "../../contexts/types/modal.types";
import { IProvider } from "../../types/common.types";

export function ModalInfo({ children }: IProvider) {
  return <div className="modalInfo">{children}</div>;
}

export function ModalFooter({
  onCancel,
  actionDisabled,
  formId,
  actionText,
  clickAction,
  hideCancel,
  asyncLoading
}: IModalFooter) {
  return (
    <footer className="modalFooter">
      {!hideCancel && (
        <button className="modalFooterCancel" onClick={onCancel}>
          Cancel
        </button>
      )}
      {formId ? (
        <Button
          variant={VARIANT.primary}
          disabled={actionDisabled}
          formId={formId}
          loading={asyncLoading}
        >
          {actionText}
        </Button>
      ) : (
        <Button
          variant={VARIANT.primary}
          disabled={actionDisabled}
          onClick={clickAction}
          loading={asyncLoading}
        >
          {actionText}
        </Button>
      )}
    </footer>
  );
}

export default function Modal() {
  const {
    modalData: { title, clickAction, actionText, formId, hideCancel, content },
    onClose,
    canShow,
    asyncLoading,
    actionDisabled
  }: IModalContext = useContext(ModalContext);

  return canShow ? (
    <div className="modalContainer">
      <div className="modalContainer_content">
        <header className="modalHeader">
          <h4>{title}</h4>
          <button className="modalClose" onClick={onClose.run}>
            X
          </button>
        </header>
        <ModalInfo>
          <>
            <section>{content}</section>
            <ModalFooter
              onCancel={onClose.run}
              actionText={`${actionText}`}
              actionDisabled={actionDisabled}
              clickAction={clickAction}
              formId={formId}
              hideCancel={hideCancel}
              asyncLoading={asyncLoading}
            />
          </>
        </ModalInfo>
      </div>
    </div>
  ) : (
    <div />
  );
}
