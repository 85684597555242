import React from "react";
import { useSelector } from "react-redux";
import { SnackbarProvider } from "./snackbar.context";
import { IncidentListFiltersProvider } from "./incident-list-filters.context";
import { ModalProvider } from "./modal.context";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { IncidentListProvider } from "./incident-list.context";
import { SessionProvider } from "./session.context";
import { OrganizationsProvider } from "./organizations.context";
import { IUser } from "../settings/userSlice";

export function CommonProvider({ children }: any) {
  const { loggedIn } = useSelector(({ user }: { user: IUser }) => user);
  return (
    <SnackbarProvider>
      <ModalProvider>
        <SessionProvider>
          {loggedIn ? (
            <OrganizationsProvider>
              <IncidentListFiltersProvider>
                <IncidentListProvider>{children}</IncidentListProvider>
              </IncidentListFiltersProvider>
            </OrganizationsProvider>
          ) : (
            children
          )}
        </SessionProvider>
      </ModalProvider>
    </SnackbarProvider>
  );
}

export default CommonProvider;
