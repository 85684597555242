/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";
import "./profile.scss";
import { Field, Formik } from "formik";
import { AsYouType } from "libphonenumber-js";
import {
  DELETE_ICON,
  DIVIDER_ICON,
  SCOPE,
  VARIANT,
  WIDTH
} from "../../constants/common.constants";
import Header from "../../components/header/header";
import ActionBar from "../../components/actionBar/actionBar";
import Button from "../../components/button/button";
import useProfile from "./useProfile";
import Input from "../../components/input/input";
import { roleMapper, rolesSelectMapper } from "../../mappers/userMappers";
import Select from "../../components/select/select";
import SessionContext from "../../contexts/session.context";

export default function Profile() {
  const {
    title,
    scope,
    isLoadingData,
    validationSchema,
    enableDeleteAction,
    mfaText,
    paswordText,
    icon,
    nameFieldEnabled,
    phoneFieldEnabled,
    emailFieldEnabled,
    roleFieldEnabled,
    roles,
    isPasswordReset,
    isMFAReset,
    initialValues,
    onSubmitProfile,
    deleteUser,
    onPasswordChange,
    onMFAChange
  } = useProfile();

  const { isAdmin } = useContext(SessionContext);

  return (
    <div className="settingsContainer">
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => onSubmitProfile(values)}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          touched,
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          handleChange,
          setFieldValue
        }) => (
          <>
            <Header icon={icon} title={title} isLoading={isLoadingData}>
              <ActionBar>
                <>
                  <Button
                    variant={VARIANT.secondary}
                    formId="profileInfo"
                    disabled={!isValid}
                    loading={isSubmitting}
                  >
                    Save
                  </Button>
                  {enableDeleteAction && (
                    <>
                      <img
                        className="settingsActionsDivider"
                        src={DIVIDER_ICON}
                        alt=""
                      />
                      <img
                        className="settingsDelete"
                        src={DELETE_ICON}
                        alt="Delete"
                        onClick={deleteUser}
                      />
                    </>
                  )}
                </>
              </ActionBar>
            </Header>
            <section className="profileContainer">
              <h3 className="containerTitle">Profile</h3>
              <form
                className="containerInfo"
                id="profileInfo"
                onSubmit={handleSubmit}
              >
                <div className="profileFirstNameInput">
                  <Field
                    label="First name"
                    name="first_name"
                    value={values.first_name}
                    readOnly={!nameFieldEnabled}
                    width={WIDTH.max}
                    onChange={handleChange}
                    isLoading={isLoadingData}
                    error={errors.first_name}
                    touched={touched.first_name}
                    component={Input}
                  />
                </div>
                <div className="profileLastNameInput">
                  <Field
                    label="Last name"
                    name="last_name"
                    value={values.last_name}
                    readOnly={!nameFieldEnabled}
                    width={WIDTH.max}
                    onChange={handleChange}
                    isLoading={isLoadingData}
                    error={errors.last_name}
                    touched={touched.last_name}
                    component={Input}
                  />
                </div>
                <div className="profilePhoneNumber1Input">
                  <Field
                    label="Phone number"
                    name="phone_number_1"
                    value={values.phone_number_1}
                    readOnly={!phoneFieldEnabled}
                    width={WIDTH.max}
                    onChange={(e: any) =>
                      setFieldValue(
                        "phone_number_1",
                        new AsYouType("US").input(e.target.value)
                      )
                    }
                    isLoading={isLoadingData}
                    error={errors.phone_number_1}
                    touched={touched.phone_number_1}
                    component={Input}
                  />
                </div>
                <div className="profilePhoneNumber2Input">
                  <Field
                    label="Secondary phone number"
                    name="phone_number_2"
                    value={values.phone_number_2}
                    readOnly={!phoneFieldEnabled}
                    width={WIDTH.max}
                    onChange={(e: any) =>
                      setFieldValue(
                        "phone_number_2",
                        new AsYouType("US").input(e.target.value)
                      )
                    }
                    isLoading={isLoadingData}
                    error={errors.phone_number_2}
                    touched={touched.phone_number_2}
                    component={Input}
                  />
                </div>
                <div className="profileEmailInput">
                  <Field
                    label="Email"
                    name="email"
                    type="email"
                    error={errors.email}
                    onChange={handleChange}
                    readOnly={!emailFieldEnabled}
                    value={values.email}
                    width={WIDTH.max}
                    isLoading={isLoadingData}
                    touched={touched.email}
                    component={Input}
                  />
                </div>
                <div className="containerDivider" />
                <div className="profileRole">
                  <label>Role</label>
                  <Field
                    name="role_id"
                    value={roleMapper(roles[values.role_id])}
                    onChange={(value: string) =>
                      setFieldValue("role_id", value)
                    }
                    isLoading={isLoadingData}
                    items={rolesSelectMapper(roles)}
                    readOnly={!roleFieldEnabled}
                    component={Select}
                  />
                </div>
              </form>
            </section>

            {(isAdmin || scope === SCOPE.local) && (
              <section className="securityContainer">
                <h3 className="containerTitle">Security</h3>
                <div className="containerInfo">
                  {isAdmin && (
                    <>
                      <div className="securityOption">
                        <label>Login password</label>
                        <Button
                          onClick={onPasswordChange}
                          disabled={isPasswordReset}
                        >
                          {paswordText}
                        </Button>
                      </div>
                      <div className="containerDivider" />
                    </>
                  )}
                  <div className="securityOption">
                    <label>Two-Factor Authentication</label>
                    <Button onClick={onMFAChange} disabled={isMFAReset}>
                      {mfaText}
                    </Button>
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </Formik>
    </div>
  );
}
