import "./sass/base.scss";
import React from "react";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";
import { useSelector } from "react-redux";
import Auth0ProviderWithHistory from "./components/auth0/auth0ProviderWithHistory";
import SnackbarList from "./components/snackbar/snackbar";
import CommonProvider from "./contexts/common.provider";
import Modal from "./components/modal/modal";
import useMdrRouting from "./settings/guards/mdrRouting";
import NavBar from "./components/navBar/navBar";
import { LOGIN_URL, WILDCARD_URL } from "./constants/urls.constants";
import NotFound from "./components/notFound/notFound";

function App() {
  const { loggedIn } = useSelector(({ user }: any) => user);
  const routes = useMdrRouting(loggedIn);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={
            <Auth0ProviderWithHistory>
              <CommonProvider>
                <SnackbarList />
                <Modal />
                {loggedIn && <NavBar />}
                <Outlet />
              </CommonProvider>
            </Auth0ProviderWithHistory>
          }
        >
          {routes}
        </Route>
        <Route
          path={WILDCARD_URL}
          element={loggedIn ? <NotFound /> : <Navigate to={LOGIN_URL} />}
        />
      </>
    )
  );
  return <RouterProvider router={router} />;
}

export default App;
