import React from "react";
import "./notFound.scss";
import { useNavigate } from "react-router-dom";
import Button from "../button/button";
import { INFORMATION_ICON } from "../../constants/common.constants";
import { INCIDENTS_URL } from "../../constants/urls.constants";
import { buildUrl } from "../../utils/string.utils";

export default function NotFound() {
  const navigate = useNavigate();

  const goToIncidentList = () => navigate(buildUrl(INCIDENTS_URL));

  return (
    <div className="not-found-container">
      <div className="not-found-info">
        <img className="not-found-info__image" src={INFORMATION_ICON} alt="" />
        <h5 className="not-found-info__title">Error 404</h5>
        <p className="not-found-info__description">
          We cannot find the page you are looking for.
        </p>
        <Button onClick={goToIncidentList}>Go to Incidents</Button>
      </div>
    </div>
  );
}
