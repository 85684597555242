import React from "react";
import "./header.scss";
import SkeletonLoader from "../skeleton/skeleton-loader";
import { SKELETON_VARIANT } from "../../constants/common.constants";

type IProps = {
  icon: React.JSX.Element;
  subTitle?: string;
  title?: string | React.JSX.Element;
  isLoading?: boolean;
  children?: React.JSX.Element;
};

export default function Header({
  icon,
  subTitle,
  title,
  isLoading = false,
  children
}: IProps) {
  return (
    <header className="headerContainer">
      {icon}
      <SkeletonLoader
        loadingVar={isLoading}
        variant={SKELETON_VARIANT.title}
        indent
      >
        <>
          <section className="headerContainer__text">
            <h3>{title}</h3>
            {subTitle && (
              <h5 className="headerContainer__text__subTitle">{subTitle}</h5>
            )}
          </section>

          {children || null}
        </>
      </SkeletonLoader>
    </header>
  );
}
