import { IOrganization } from "../contexts/types/organization.types";
import { Request, useGet, usePatch, usePost } from "./api";

export function useGetOrganizations() {
  const getApi: Request = useGet();

  async function run() {
    getApi.setEndpoint(`${process.env.REACT_APP_API_ORGANIZATIONS}`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useGetCatalog() {
  const getApi: Request = useGet();

  async function run() {
    getApi.setEndpoint(`${process.env.REACT_APP_API_ORGANIZATIONS_CATALOG}`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useCreateOrganization() {
  const postApi: Request = usePost();

  async function run(organization: IOrganization) {
    postApi.setEndpoint(`${process.env.REACT_APP_API_ORGANIZATIONS}`);
    postApi.setBody(organization);
    return postApi.run(postApi.getOptions());
  }
  return run;
}

export function useGetOrganization() {
  const getApi: Request = useGet();

  async function run(id: string) {
    getApi.setEndpoint(`${process.env.REACT_APP_API_ORGANIZATIONS}/${id}`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useUpdateOrganization() {
  const patchApi: Request = usePatch();

  async function run(id: string, organization: IOrganization) {
    patchApi.setEndpoint(`${process.env.REACT_APP_API_ORGANIZATIONS}/${id}`);
    patchApi.setBody(organization);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useGetOrgIdFromUsername() {
  const getApi: Request = useGet();

  async function run(username: string) {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_USER_ORG}?username=${encodeURIComponent(
        username
      )}`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}
