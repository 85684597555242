import React, { useContext } from "react";
import "./incidentsFilters.scss";
import Filter from "../../components/filter/filter";
import { VARIANT, WIDTH } from "../../constants/common.constants";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import FormatDate from "../../formatDate/formatDate";
import IncidentListfiltersContext from "../../contexts/incident-list-filters.context";

export default function IncidentsFilters() {
  const {
    currentFilters: {
      client,
      hostname,
      status,
      category,
      rules,
      escalated,
      creationStartDate,
      creationEndDate
    },
    availableFilters,
    showClient,
    toggleShowFilters,
    resetFilters,
    setClientFilter,
    showFilters,
    setHostnameFilter,
    setStatusFilter,
    setCategoryFilter,
    setRulesFilter,
    setEscalatedFilter,
    setCreationStartDateFilter,
    setCreationEndDateFilter
  } = useContext(IncidentListfiltersContext);

  const today = new FormatDate(undefined, false).date;

  const containerClassRoot = "incidentsfilters__container";

  const containerClassName = `${containerClassRoot} ${containerClassRoot}${
    showFilters ? "--show" : "--hide"
  }`;

  const onFilterKeyDown = (event: any) => event.preventDefault();

  const onCreateStartDateFilterSelected = ({ target }: any) => {
    const date = new FormatDate(target.value, false);
    setCreationStartDateFilter(date.epochDate, date.date);
  };
  const onCreateEndDateFilterSelected = ({ target }: any) => {
    const date = new FormatDate(target.value, false);
    setCreationEndDateFilter(date.epochDate, date.date);
  };

  return (
    <section className={containerClassName}>
      <div className="filters">
        <div className="filterRow">
          {showClient && (
            <section className="clientFilter">
              <Filter
                label="Organization"
                value={client?.caption}
                filters={availableFilters?.client}
                onChange={(value: string) => setClientFilter(value)}
                clearOption
              />
            </section>
          )}
          <section className="hostnameFilter">
            <Filter
              label="Hostname"
              value={hostname.caption}
              filters={availableFilters?.hostname}
              onChange={(value: string) => setHostnameFilter(value)}
              clearOption
            />
          </section>
          <section className="categoryFilter">
            <Filter
              label="Category"
              value={category.caption}
              filters={availableFilters?.category}
              onChange={(value: string) => setCategoryFilter(value)}
              clearOption
            />
          </section>
          <section className="escalatedFilter">
            <Filter
              label="Escalated"
              value={escalated.caption}
              filters={availableFilters?.escalated}
              onChange={(value: string) => setEscalatedFilter(value)}
              clearOption
            />
          </section>
          <section className="statusFilter">
            <Filter
              label="Status"
              value={status.caption}
              filters={availableFilters?.status}
              onChange={(value: string) => setStatusFilter(value)}
              clearOption
            />
          </section>
        </div>
        <div className="filterRow">
          <div className="dateFilters">
            <section className="createStartDateFilter">
              <Input
                type="date"
                label="Created start date"
                value={creationStartDate?.caption}
                width={WIDTH.max}
                onChange={onCreateStartDateFilterSelected}
                onKeyDown={onFilterKeyDown}
                max={creationEndDate?.caption}
              />
            </section>
            <div className="dateFilters__divider">
              <div className="dateFilters__divider-symbol" />
            </div>
            <section className="createEndDateFilter">
              <Input
                type="date"
                label="Created end date"
                value={creationEndDate?.caption}
                width={WIDTH.max}
                onChange={onCreateEndDateFilterSelected}
                onKeyDown={onFilterKeyDown}
                min={creationStartDate?.caption}
                max={today}
              />
            </section>
          </div>
          <section className="ruleFilter">
            <Filter
              label="Rule"
              value={rules.caption}
              filters={availableFilters?.rules}
              onChange={(value: string) => setRulesFilter(value)}
              withSearch
              clearOption
            />
          </section>
        </div>
      </div>
      <section className="filterActions">
        <Button variant={VARIANT.secondary} onClick={resetFilters}>
          Reset
        </Button>
        <Button variant={VARIANT.primary} onClick={toggleShowFilters}>
          Close
        </Button>
      </section>
    </section>
  );
}
