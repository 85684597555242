/* eslint-disable no-return-await */
/* eslint-disable no-restricted-syntax */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import * as Yup from "yup";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ERROR_ICON,
  MESSAGE,
  NEXT_TEXT,
  PREVIOUS_TEXT,
  STATUS,
  SUCCESS_ICON,
  TEXT_WHITE_ICON,
  USER_ICON
} from "../constants/common.constants";
import {
  useAssignIncident,
  useGetIncident,
  useGetNextIncidentStatus,
  useUpdateIncident
} from "../api/incidents";
import SnackbarContext from "../contexts/snackbar.context";
import IncidentListContext from "../contexts/incident-list.context";
import { IIncident } from "../contexts/types/incident.list.types";
import { INCIDENTS_URL, INCIDENT_URL } from "../constants/urls.constants";
import getInitials, { buildUrl } from "../utils/string.utils";
import SessionContext from "../contexts/session.context";
import ModalContext from "../contexts/modal.context";
import { IAnyPropertyNameAndStringValue } from "../types/common.types";

export default function useIncident() {
  const [data, setData] = useState<IIncident>({} as IIncident);
  const [loadingNewPage, setLoadingNewPage] = useState("");
  const [nextStatus, setNextStatus] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(Boolean);
  const [isAssigning, setIsAssigning] = useState(Boolean);
  const [initialValues, setInitialValues] = useState({
    category: "",
    severity: "",
    username: "",
    title: "",
    description: ""
  });

  const localUser = useSelector((state: any) => state.user.profile);

  const { showSnackbar, killSnackbar } = useContext(SnackbarContext);
  const { isMdrRole } = useContext(SessionContext);
  const { closeModal, setAsyncLoading } = useContext(ModalContext);

  const {
    incidentCatalog,
    incidents,
    load,
    pages,
    loading,
    handlePreviousPage,
    handleNextPage
  } = useContext(IncidentListContext);

  const { id } = useParams();
  const assign = useAssignIncident();
  const get = useGetIncident();
  const getNextStatus = useGetNextIncidentStatus();
  const update = useUpdateIncident();
  const nav = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();

  const viewed = useRef(false);

  const handlerName = useMemo(
    () =>
      incidentCatalog?.handler
        ? incidentCatalog?.handler[data?.handler_id]
        : "",
    [data.handler_id, incidentCatalog.handler]
  );

  const initials = useMemo(() => getInitials(handlerName), [handlerName]);

  const assignAllowed = useMemo(
    () => Reflect.has(nextStatus, STATUS.claimed),
    [nextStatus]
  );
  const sendToReviewAllowed = useMemo(
    () => Reflect.has(nextStatus, STATUS.internalReview),
    [nextStatus]
  );
  const sendToClientAllowed = useMemo(
    () => Reflect.has(nextStatus, STATUS.clientEscalation),
    [nextStatus]
  );
  const closeAllowed = useMemo(
    () => Reflect.has(nextStatus, STATUS.closed),
    [nextStatus]
  );
  const reassignAllowed = useMemo(
    () =>
      Reflect.has(data, "status") &&
      data.status !== STATUS.closed &&
      data.status !== STATUS.new,
    [data]
  );

  const notesDisabled = useMemo(
    () =>
      !isMdrRole ||
      (Reflect.has(data, "status") && data.status === STATUS.closed),
    [isMdrRole, data]
  );

  const headerTitle = useMemo(() => {
    if (Reflect.has(data, "title")) {
      return data.title;
    }
    if (Reflect.has(data, "rules")) {
      return data.rules.length ? data.rules[0] : "";
    }

    return "";
  }, [data]);

  const categories = useMemo(() => {
    if (!incidentCatalog?.risk_category) return {};

    const returnData: IAnyPropertyNameAndStringValue = {};

    Object.keys(incidentCatalog.risk_category).forEach((risk: any) => {
      Object.keys(incidentCatalog.risk_category[risk].category).forEach(
        (category) => {
          returnData[category] =
            incidentCatalog.risk_category[risk].category[category];
        }
      );
    });
    return returnData;
  }, [incidentCatalog]);

  const severities = useMemo(() => {
    if (!Object.keys(incidentCatalog).length) {
      return {};
    }
    return incidentCatalog.severity;
  }, [incidentCatalog]);

  const index = useMemo(() => {
    let value = -1;

    if (!incidents) {
      return value;
    }

    for (const [idx, incident] of incidents.entries()) {
      if (incident.id !== id) {
        // eslint-disable-next-line no-continue
        continue;
      }
      value = idx;
    }
    return value;
  }, [incidents, id]);

  const previousDisabled = useMemo(
    () =>
      index === -1 || loadingNewPage
        ? true
        : index === 0 && pages.current === 1,
    [index, loadingNewPage, pages]
  );

  const nextDisabled = useMemo(
    () =>
      index === -1 || loadingNewPage
        ? true
        : index === incidents.length - 1 && pages.current === pages.total,
    [incidents.length, index, loadingNewPage, pages]
  );

  const updateNextStatus = useCallback(async () => {
    try {
      const nextStatusResponse = await getNextStatus(`${id}`);
      setNextStatus(nextStatusResponse.status);
    } catch (error: any) {
      console.error(
        `Error getting the next status of incident ${id}. Status ${error.status}. ${error}`
      );
    }
  }, [getNextStatus, id]);

  const handleAssign = useCallback(
    async (handlerId: string, status = "") => {
      setIsAssigning(true);
      const body = {
        ...(data.status === STATUS.new || status === STATUS.new
          ? { status: STATUS.claimed }
          : {}),
        handler_id: handlerId
      };

      try {
        const updateResponse = await assign(`${id}`, body);
        setData(updateResponse);
        await updateNextStatus();
        load();
        if (typeof updateResponse !== "undefined") {
          const name = incidentCatalog.handler[handlerId];
          console.info(`Incident ${updateResponse.id} assigned to ${name}`);
          showSnackbar({
            text: `Incident assigned to ${name}`,
            type: MESSAGE.info,
            icon: USER_ICON
          });
          setIsAssigning(false);
        }
      } catch (error: any) {
        showSnackbar({
          text: "Error assigning incident",
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        console.error(
          `Error assigning incident ${id} to ${handlerId}. Status ${error.status}. ${error}`
        );
        setIsAssigning(false);
        throw error;
      }
    },
    [
      assign,
      data.status,
      id,
      incidentCatalog.handler,
      load,
      showSnackbar,
      updateNextStatus
    ]
  );

  const loadData = useCallback(
    (apiResponse: any) => {
      for (const element of apiResponse) {
        if (element.status === STATUS.fulfilled) {
          if (Reflect.has(element.value, "id")) {
            if (!element?.value?.handler_id && isMdrRole) {
              handleAssign(localUser.id, element?.value?.status);
            } else {
              setData(element.value);
              setInitialValues({
                category: element.value.category,
                severity: element.value.severity,
                username: element.value.user_name,
                title: element.value.title,
                description: element.value.description
              });
            }
          } else if (Reflect.has(element.value, "status"))
            setNextStatus(() => element.value.status);
          // eslint-disable-next-line no-continue
          continue;
        }
        if (element.status === STATUS.rejected) {
          console.error(
            `Error loading incident ${id}. Status ${element.status}. ${element.reason}`
          );
          showSnackbar({
            text: `Unable to retrieve incident data. ${element.reason}`,
            type: "error",
            icon: ERROR_ICON
          });
          nav(buildUrl(INCIDENTS_URL));
          break;
        }
      }
    },
    [isMdrRole, handleAssign, localUser.id, id, showSnackbar, nav]
  );

  const getStatusText = useCallback((status: string) => {
    const text: any = {
      [STATUS.new]: STATUS.new,
      [STATUS.claimed]: "Assigned",
      [STATUS.closed]: STATUS.closed,
      [STATUS.internalReview]: "Review",
      [STATUS.clientEscalation]: "Sent",
      [STATUS.clientViewed]: "Viewed",
      default: "Unknown"
    };
    return text[status] || text.default;
  }, []);

  // eslint-disable-next-line consistent-return
  const setAsViewed = useCallback(async () => {
    const body = {
      status: STATUS.clientViewed
    };
    try {
      const updateResponse = await update(`${id}`, body);
      setData(updateResponse);
      await updateNextStatus();
      return updateResponse;
    } catch (error) {
      // TODO:This should be raised to the View component
      console.error(`Error updating incident ${id} as viewed ${error}`);
    }
  }, [id, update, updateNextStatus]);

  const getData = useCallback(() => {
    setIsLoadingData(true);
    Promise.allSettled([get(`${id}`), getNextStatus(`${id}`)]).then(
      (response) => {
        loadData(response);
        setIsLoadingData(false);
      }
    );
  }, [get, getNextStatus, id, loadData]);

  const handleUpdate = useCallback(
    async (body: any) => {
      const updateResponse = await update(`${id}`, body);
      setData(updateResponse);
      await updateNextStatus();
    },
    [id, update, updateNextStatus]
  );

  const handleSendToReview = useCallback(
    async (category: string, severity: string) => {
      try {
        const body = {
          status: STATUS.internalReview,
          risk: category?.split("-")[0],
          category,
          severity
        };
        await handleUpdate(body);
        showSnackbar({
          text: "Incident sent to review",
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
        load();
      } catch (error: any) {
        console.error(
          `Error sending incident ${id} to review. Status ${error.status}. ${error}`
        );
        showSnackbar({
          text: `Error sending incident to review. ${error}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
      }
    },
    [handleUpdate, id, load, showSnackbar]
  );

  const handleSendToClient = useCallback(
    async (category: string, severity: string) => {
      try {
        setAsyncLoading(true);
        const body = {
          status: STATUS.clientEscalation,
          risk: category?.split("-")[0],
          category,
          severity
        };
        await handleUpdate(body);
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: "Incident sent to client",
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
        load();
      } catch (error: any) {
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: `Error sending incident to the client. ${error}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        console.error(
          `Error sending incident ${id} to client. Status ${error.status}. ${error}`
        );
      }
    },
    [closeModal, handleUpdate, id, load, setAsyncLoading, showSnackbar]
  );

  const handleClose = useCallback(
    async (reason: string) => {
      try {
        setAsyncLoading(true);
        const body = {
          status: STATUS.closed,
          close_reason: reason
        };
        await handleUpdate(body);
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: `Incident closed as ${incidentCatalog?.close_reason[reason]}`,
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
        load();
      } catch (error: any) {
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: `Error closing incident as ${incidentCatalog?.close_reason[reason]}, ${error}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        console.error(
          `Error closing incident ${id} with reason ${reason}. Status ${error.status}. ${error}`
        );
      }
    },
    [
      closeModal,
      handleUpdate,
      id,
      incidentCatalog?.close_reason,
      load,
      setAsyncLoading,
      showSnackbar
    ]
  );

  const handlePrevious = () => {
    if (previousDisabled) {
      return;
    }
    if (index !== 0) {
      const previousId = incidents[index - 1].id;
      navigate(buildUrl(INCIDENT_URL, previousId));
    } else {
      setLoadingNewPage(PREVIOUS_TEXT);
      handlePreviousPage();
    }
  };

  const handleNext = () => {
    if (nextDisabled) {
      return;
    }
    if (index !== incidents.length - 1) {
      const nextId = incidents[index + 1].id;
      navigate(buildUrl(INCIDENT_URL, nextId));
    } else {
      setLoadingNewPage(NEXT_TEXT);
      handleNextPage();
    }
  };

  const handleCategorySelected = async (value: string) => {
    const snackbarKey = showSnackbar({
      text: "Saving category...",
      type: MESSAGE.info,
      icon: TEXT_WHITE_ICON
    });
    try {
      const body = {
        risk: value?.split("-")[0],
        category: value
      };
      await handleUpdate(body);
      killSnackbar(snackbarKey);
      showSnackbar({
        text: "Category changed successfully",
        type: MESSAGE.info,
        icon: SUCCESS_ICON
      });
      load();
    } catch (error: any) {
      console.error(
        `Error assigning category ${value} to incident ${id}. Status ${error.status}. ${error}`
      );
      killSnackbar(snackbarKey);
      showSnackbar({
        text: "Error assigning category",
        type: MESSAGE.error,
        icon: ERROR_ICON
      });
    }
  };

  const handleSeveritySelected = async (value: string) => {
    const snackbarKey = showSnackbar({
      text: "Saving severity...",
      type: MESSAGE.info,
      icon: TEXT_WHITE_ICON
    });
    try {
      const body = {
        severity: value
      };
      await handleUpdate(body);
      killSnackbar(snackbarKey);
      showSnackbar({
        text: "Severity changed successfully",
        type: MESSAGE.info,
        icon: SUCCESS_ICON
      });
      load();
    } catch (error: any) {
      killSnackbar(snackbarKey);
      console.error(
        `Error assigning severity ${value} to incident ${id}. Status ${error.status}. ${error}`
      );
      showSnackbar({
        text: "Error assigning severity",
        type: MESSAGE.error,
        icon: ERROR_ICON
      });
    }
  };

  const handleSaveUsername = async (value: string) => {
    const snackbarKey = showSnackbar({
      text: "Saving username...",
      type: MESSAGE.info,
      icon: USER_ICON
    });
    try {
      const body = {
        user_name: value
      };
      await handleUpdate(body);
      showSnackbar({
        text: "Username saved",
        type: MESSAGE.info,
        icon: USER_ICON
      });
      killSnackbar(snackbarKey);
    } catch (error: any) {
      showSnackbar({
        text: "Error saving username",
        type: MESSAGE.error,
        icon: ERROR_ICON
      });
      console.error(
        `Error saving username in incident ${id}. Status ${error.status}. ${error}`
      );
      killSnackbar(snackbarKey);
    }
  };

  const handleSaveTitle = async (value: string) => {
    const snackbarKey = showSnackbar({
      text: "Saving title...",
      type: MESSAGE.info,
      icon: TEXT_WHITE_ICON
    });
    try {
      const body = {
        title: value
      };
      await handleUpdate(body);
      killSnackbar(snackbarKey);
      showSnackbar({
        text: "Title saved",
        type: MESSAGE.info,
        icon: TEXT_WHITE_ICON
      });
    } catch (error: any) {
      killSnackbar(snackbarKey);
      console.error(
        `Error saving title in incident ${id}. Status ${error.status}. ${error}`
      );
      showSnackbar({
        text: "Error saving title",
        type: MESSAGE.error,
        icon: ERROR_ICON
      });
    }
  };

  const handleSaveDescription = async (value: string) => {
    const snackbarKey = showSnackbar({
      text: "Saving description...",
      type: MESSAGE.info,
      icon: TEXT_WHITE_ICON
    });
    try {
      const body = {
        description: value
      };
      await handleUpdate(body);
      killSnackbar(snackbarKey);
      showSnackbar({
        text: "Description saved",
        type: MESSAGE.info,
        icon: TEXT_WHITE_ICON
      });
    } catch (error: any) {
      killSnackbar(snackbarKey);
      console.error(
        `Error saving description in incident ${id}. Status ${error.status}. ${error}`
      );
      showSnackbar({
        text: "Error saving description",
        type: MESSAGE.error,
        icon: ERROR_ICON
      });
    }
  };

  useEffect(() => {
    if (!loading && loadingNewPage) {
      const incidentId =
        incidents[loadingNewPage === PREVIOUS_TEXT ? incidents.length - 1 : 0]
          .id;
      setLoadingNewPage("");
      navigate(buildUrl(INCIDENT_URL, incidentId));
    }
  }, [incidents, loading, loadingNewPage, navigate]);

  useEffect(() => {
    if (!Reflect.has(data, "status")) {
      return;
    }

    if (viewed.current) {
      return;
    }

    if (!isMdrRole && data.status === STATUS.clientEscalation) {
      setAsViewed();
      viewed.current = true;
    }
  }, [data, isMdrRole, setAsViewed]);

  useEffect(() => {
    getData();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const validationSchema = Yup.object().shape({
    category: Yup.string(),
    severity: Yup.string(),
    username: Yup.string(),
    title: Yup.string(),
    description: Yup.string()
  });

  return {
    id,
    incidentCatalog,
    categories,
    created: data.created,
    handlerId: data.handler_id,
    headerTitle,
    hostname: data.hostname,
    severities,
    status: getStatusText(data.status),
    orgName: data.org_name,
    loadingNewPage,
    isExempt: data?.is_exempt,
    tags: data?.tags,
    isLoadingData,
    nextStatus,
    nextDisabled,
    previousDisabled,
    assignAllowed,
    sendToReviewAllowed,
    sendToClientAllowed,
    closeAllowed,
    reassignAllowed,
    initials,
    handlerName,
    notesDisabled,
    initialValues,
    validationSchema,
    isAssigning,
    handleAssign,
    handleClose,
    handleSendToClient,
    handleSendToReview,
    handleNext,
    handlePrevious,
    handleSaveDescription,
    handleSaveTitle,
    handleSaveUsername,
    handleCategorySelected,
    handleSeveritySelected
  };
}
