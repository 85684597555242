import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import IdleManager from "../settings/idleManager/IdleManager";
import ModalContext from "../contexts/modal.context";
import LoginContext from "../contexts/session.context";
import { CONFIRMATION_TIME_REF } from "../constants/common.constants";

export default function useIdle() {
  const [isTimeout, setIsTimeOut] = useState(false);
  const { handleLogOut } = useContext(LoginContext);

  const { showModal, closeModal } = useContext(ModalContext);

  const timer = useMemo(
    () =>
      new IdleManager({
        timeout: process.env.REACT_APP_AUTH0_MDR_APP_IDLE_TIME,
        onTimeout: () => {
          setIsTimeOut(true);
        },
        confirmTime: process.env.REACT_APP_AUTH0_MDR_APP_IDLE_CONFIRM_TIME,
        onNoConfirmation: () => {
          closeModal();
          handleLogOut();
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const checkUserActivity = useCallback(() => {
    localStorage.removeItem(CONFIRMATION_TIME_REF);
    timer.startTracker();
    setIsTimeOut(false);
    closeModal();
  }, [timer, closeModal]);

  useEffect(
    () => () => {
      timer.cleanUp();
    },
    [timer]
  );

  useEffect(() => {
    if (isTimeout)
      showModal({
        title: "Are you there?",
        clickAction: checkUserActivity,
        actionText: "Stay signed in",
        content: (
          <p>{`Your session is about to expire due to inactivity. You will automatically be signed out in ${process.env.REACT_APP_AUTH0_MDR_APP_IDLE_CONFIRM_TIME} seconds. Click the button to stay signed in.`}</p>
        )
      });
  }, [isTimeout, checkUserActivity, showModal]);

  return checkUserActivity;
}
