import React, { useContext, useState } from "react";
import "./incidentActivityAddComments.scss";
import { useSelector } from "react-redux";
import Avatar from "../../components/avatar/avatar";
import {
  ERROR_ICON,
  MESSAGE,
  SUCCESS_ICON,
  WIDTH,
  VARIANT
} from "../../constants/common.constants";
import Button from "../../components/button/button";

import TextArea from "../../components/textArea/textArea";
import SnackbarContext from "../../contexts/snackbar.context";
import useIsMounted from "../../hooks/useIsMounted";
import SessionContext from "../../contexts/session.context";

export default function IncidentActivityAddComments({ onNewComment }: any) {
  const { initials } = useContext(SessionContext);
  const isMounted = useIsMounted();
  const localUser = useSelector((state: any) => state.user.profile);

  const [comment, setComment] = useState("");
  const [commentDisabled, setCommentDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [rows, setRows] = useState(1);

  const { showSnackbar } = useContext(SnackbarContext);

  const save = async (text: string) => {
    const formattedComment = {
      comment: text,
      created: `${Math.floor(new Date().getTime() / 1000.0)}`,
      username: localUser.name,
      user_id: localUser.id
    };

    // eslint-disable-next-line no-return-await
    return await onNewComment(formattedComment);
  };

  const onKeyDown = async (event: any) => {
    if (event.key === "Enter" && event.target.value.length && !isLoading) {
      setIsLoading(true);
      setCommentDisabled(true);

      const result = await save(event.target.value);
      if (!isMounted.current) return;
      if (result) {
        setComment("");
        setRows(1);
        showSnackbar({
          text: "Comment added",
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
      } else
        showSnackbar({
          text: "Error creating comment",
          type: MESSAGE.error,
          icon: ERROR_ICON
        });

      setIsLoading(false);
      setCommentDisabled(false);
    }
  };

  const onCommentChange = (event: any) => {
    const {
      target: { value, scrollHeight, clientHeight }
    } = event;

    setComment(value);

    if (value === "") {
      setRows(1);
      setIsLoading(false);
      return;
    }

    if (rows < 2 && scrollHeight > clientHeight) {
      setRows((prevState) => prevState + 1);
    }
  };

  return (
    <section className="incidentContainer__activity__addComments">
      <div className="incidentContainer__activity__addComments__avatar">
        <Avatar text={initials} />
      </div>
      <TextArea
        value={comment}
        placeholder="Add comment (Tap Enter key to send)"
        width={WIDTH.max}
        rows={rows}
        disabled={commentDisabled || isLoading}
        onChange={onCommentChange}
        onKeyDown={onKeyDown}
      />
      <div className="incidentContainer__activity__addComments__loading">
        <Button variant={VARIANT.transparent} loading={isLoading} />
      </div>
    </section>
  );
}
