/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useGetOrganizations } from "../api/organizations";
import alphabeticalOrderMapper from "../mappers/arrayMappers";
import { organizationsListMapper } from "../mappers/organizationMappers";
import {
  IAnyPropertyNameAndStringValue,
  IProvider
} from "../types/common.types";
import {
  IOrganization,
  IOrganizationContext
} from "./types/organization.types";
import SessionContext from "./session.context";

const OrganizationsContext = createContext<IOrganizationContext>(
  {} as IOrganizationContext
);

export function OrganizationsProvider({ children }: IProvider) {
  const [organizationsList, setOrganizationsList] = useState<IOrganization[]>(
    []
  );
  const [mappedOrganizationsList, setMappedOrganizationsList] =
    useState<IAnyPropertyNameAndStringValue>({});
  const [loading, setLoading] = useState<boolean>(false);
  const get = useGetOrganizations();
  const { isMdrRole, isProviderRole } = useContext(SessionContext);

  const load = useCallback(async () => {
    setLoading(true);
    get()
      .then((response) => {
        setOrganizationsList(alphabeticalOrderMapper(response, "name"));
        setMappedOrganizationsList(
          organizationsListMapper(alphabeticalOrderMapper(response, "name"))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          `Error getting organizations. Status ${error.status}. ${error}`
        );
        setLoading(false);
      });
  }, [get]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isMdrRole || isProviderRole) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnedValue: IOrganizationContext = useMemo(
    () => ({
      organizationsList,
      mappedOrganizationsList,
      loading,
      load
    }),
    [organizationsList, mappedOrganizationsList, loading, load]
  );

  return (
    <OrganizationsContext.Provider value={returnedValue}>
      {children}
    </OrganizationsContext.Provider>
  );
}

export default OrganizationsContext;
