/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import React, { useCallback, useContext, useMemo } from "react";
import "./sensors-list.scss";
import Table from "../components/table/table";
import Header from "../components/header/header";
import Icon from "../components/icon/icon";
import ActionBar from "../components/actionBar/actionBar";
import {
  DOWNLOAD_ICON,
  FILTER_ICON,
  SEARCH_ICON,
  SENSORS_ICON,
  VARIANT,
  WIDTH,
  sensorsCVSFieldsHeaders,
  sensorsCVSFieldsOrder
} from "../constants/common.constants";
import Select from "../components/select/select";
import Button from "../components/button/button";
import Tooltip, { tooltipTypes } from "../components/tooltip/tooltip";
import SensorsListContext from "../contexts/sensors-list.context";
import SensorsListfiltersContext from "../contexts/sensors-list-filters.context";
import exportToCSV from "../utils/exportToCSV";
import { organizationListDropdownMapper } from "../mappers/organizationMappers";
import Input from "../components/input/input";
import SensorsListFilters from "./components/sensorsListFilters";
import Loader from "../components/loader/loader";
import Menu from "../components/menu/menu";

export default function SensorsList() {
  const { table, sensorsList, filteredSensorsList, loading } =
    useContext(SensorsListContext);
  const {
    toggleShowFilters,
    showFilters,
    organization,
    mappedOrganizationsList,
    setOrganizationFilter,
    allowOrgSelect,
    searchText,
    handleSearch
  } = useContext(SensorsListfiltersContext);

  // const onSearchChange = (event) => handleSearch(event.target.value);

  const handleDownload = useCallback(
    (option: string) => {
      const downloadData: any[] = [];

      const header = sensorsCVSFieldsHeaders;
      const body = option === "all" ? sensorsList : filteredSensorsList;
      [...header, ...body].forEach((row: any) => {
        const orderedRow: any[] = [];
        sensorsCVSFieldsOrder.forEach((accessor) => {
          orderedRow.push(
            Array.isArray(row[accessor])
              ? row[accessor].join("/")
              : row[accessor]
          );
        });
        downloadData.push(orderedRow);
      });

      exportToCSV(downloadData, "sensors.csv");
    },
    [filteredSensorsList, sensorsList]
  );

  const downloadOptions = useMemo(
    () => [
      {
        id: "filtered",
        value: "CSV with filtered sensors ",
        icon: DOWNLOAD_ICON
      },
      { id: "__divider" },
      { id: "all", value: "CSV with all sensors ", icon: DOWNLOAD_ICON }
    ],
    []
  );

  return (
    <div className="sensorsListContainer">
      <Header
        icon={<Icon image={SENSORS_ICON} alt="Sensors" />}
        title={
          <div className="sensorsListContainer__header-container">
            Sensors
            <span className="sensors-count">
              &nbsp;|&nbsp;
              {filteredSensorsList.length}
            </span>
          </div>
        }
      >
        <ActionBar>
          {loading && (
            <>
              <span className="sensorsListContainer__header-container__loading-text">
                Loading sensors
              </span>
              <div className="sensorsListContainer__header-container__spinner">
                <Loader />
              </div>
            </>
          )}
        </ActionBar>
      </Header>
      <section className="sensorsListContainer__actionBar">
        <div className="sensorsListContainer__actionBar__org">
          {allowOrgSelect && (
            <Select
              value={organization.caption}
              onChange={(orgId: string) => setOrganizationFilter(orgId)}
              items={organizationListDropdownMapper(mappedOrganizationsList)}
              withSearch
            />
          )}
        </div>
        <ActionBar>
          <>
            <Input
              icon={SEARCH_ICON}
              placeholder="Search hostname, IP or tag"
              width={WIDTH.default}
              onChange={({ target }: any) => handleSearch(target.value)}
              value={searchText}
            />
            <div className="has-tooltip sensorsFilters">
              <Button
                onClick={toggleShowFilters}
                variant={VARIANT.secondary}
                active={showFilters}
              >
                <img src={FILTER_ICON} alt="Filters" />
              </Button>
              <Tooltip type={tooltipTypes.info} text="Apply Filters" right />
            </div>
            <div className="sensorsDownload">
              <Menu items={downloadOptions} clickAction={handleDownload} left>
                <div className="has-tooltip">
                  <Button variant={VARIANT.secondary}>
                    <img src={DOWNLOAD_ICON} alt="Download" />
                  </Button>
                  <Tooltip
                    type={tooltipTypes.info}
                    text="Download sensors CSV file"
                    right
                  />
                </div>
              </Menu>
            </div>
          </>
        </ActionBar>
      </section>
      <SensorsListFilters />
      <section className="sensorsListContainer__info">
        <Table instance={table.instance} isLoadingData={loading} />
      </section>
    </div>
  );
}
