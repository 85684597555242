import React, { useCallback, createContext, useState, useMemo } from "react";
import { IModalContext, IModalData } from "./types/modal.types";
import { IProvider } from "../types/common.types";

const ModalContext = createContext<IModalContext>({} as IModalContext);

export function ModalProvider({ children }: IProvider) {
  const [modalData, setData] = useState<IModalData>({
    title: "",
    clickAction: () => {},
    actionText: "Save",
    isForm: false,
    hideCancel: false,
    content: null,
    formId: ""
  });

  const [canShow, setCanShow] = useState<boolean>(false);
  const [actionDisabled, setActionDisabled] = useState<boolean>(false);
  const [asyncLoading, setAsyncLoading] = useState<boolean>(false);
  const [onClose, setDoOnClose] = useState({ run: () => {} });

  const closeModal = useCallback(() => setCanShow(false), []);

  const setOnClose = useCallback(
    (doOnClose: any) =>
      setDoOnClose({
        run: () => {
          closeModal();
          doOnClose();
        }
      }),
    [closeModal]
  );

  const showModal = useCallback(
    ({
      title = "",
      closeAction = () => {},
      clickAction = () => {},
      actionText = "Save",
      formId = "",
      hideCancel = false,
      isActionDisabled = false,
      content = null
    }: IModalData) => {
      setData({ title, clickAction, actionText, formId, hideCancel, content });
      setActionDisabled(isActionDisabled);
      setAsyncLoading(false);
      setOnClose(closeAction);
      setCanShow(true);
    },
    [setOnClose]
  );

  const returnedInformation: IModalContext = useMemo(
    () => ({
      modalData,
      canShow,
      showModal,
      closeModal,
      actionDisabled,
      setActionDisabled,
      asyncLoading,
      setAsyncLoading,
      onClose,
      setOnClose
    }),
    [
      modalData,
      canShow,
      showModal,
      closeModal,
      actionDisabled,
      setActionDisabled,
      asyncLoading,
      setAsyncLoading,
      onClose,
      setOnClose
    ]
  );

  return (
    <ModalContext.Provider value={returnedInformation}>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
