const alphabeticalOrderMapper = (
  array: any,
  comparationParamName: string
): [] =>
  array.sort((paramA: any, paramB: any) => {
    const a = comparationParamName
      ? paramA[comparationParamName].toLowerCase()
      : paramA;
    const b = comparationParamName
      ? paramB[comparationParamName].toLowerCase()
      : paramB;
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

export default alphabeticalOrderMapper;
