/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-restricted-syntax */
import React from "react";
import "./incidentActivityTimeline.scss";
import Accordion from "../../components/accordion/accordion";
import IncidentActivityComments from "./incidentActivityComments";
import { SKELETON_VARIANT } from "../../constants/common.constants";
import FormatDate from "../../formatDate/formatDate";
import Button from "../../components/button/button";
import {
  IComment,
  IIncidentActivityTimelineProps
} from "./incidentActivity.types";
import { IEvent } from "../../event/event.types";
import SkeletonLoader from "../../components/skeleton/skeleton-loader";
import Event from "../../event/event";

export default function IncidentActivityTimeline({
  timeline,
  deleteComment,
  editComment,
  loadData,
  isLoading,
  endOfPages
}: IIncidentActivityTimelineProps) {
  const dateIcon = (
    <div className="incidentContainer__activity__accordion__icon" />
  );

  return (
    <div className="incidentContainer__activity__timeline">
      {Object.keys(timeline).map((date) => {
        const headerDate = new FormatDate(timeline[date].date, false).date;
        const title = (
          <div className="incidentContainer__activity__accordion__title">
            <h5>{timeline[date].day}</h5>
            <span>{headerDate}</span>
          </div>
        );

        return (
          <div
            key={date}
            className="incidentContainer__activity__timeline__date"
          >
            <Accordion key={date} icon={dateIcon} title={title} compressed>
              <>
                {editComment &&
                  timeline[date] &&
                  timeline[date]?.comments.map(
                    (comment: { data: IComment }) => (
                      <div
                        key={comment.data.comment_id}
                        className="incidentContainer__activity__timeline__comment"
                      >
                        <IncidentActivityComments
                          data={comment.data}
                          deleteComment={deleteComment}
                          editComment={editComment}
                        />
                      </div>
                    )
                  )}
                {!editComment &&
                  timeline[date] &&
                  timeline[date]?.events.map(
                    (event: { data: IEvent; type: string }) => (
                      <div
                        key={event.data.event_id}
                        className="incidentContainer__activity__timeline__event"
                      >
                        <Event title={event.type} data={event.data} />
                      </div>
                    )
                  )}
              </>
            </Accordion>
          </div>
        );
      })}
      {(!endOfPages || isLoading) && (
        <div className="incidentContainer__activity__timeline__load-more">
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.event}
            marginTop
          >
            <div className="incidentContainer__activity__timeline__load-more__button">
              <Button onClick={() => loadData()} loading={isLoading}>
                Load More
              </Button>
            </div>
          </SkeletonLoader>
        </div>
      )}
    </div>
  );
}
