/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useCallback, useContext, useState } from "react";
import "./users.scss";
import { useSelector } from "react-redux";
import UsersActions from "./usersActions";
import Table from "../components/table/table";
import useDeleteUser from "./deleteUser/deleteUser";
import Header from "../components/header/header";
import ActionBar from "../components/actionBar/actionBar";
import {
  ADD_ICON,
  DELETE_ICON,
  DIVIDER_ICON,
  ERROR_ICON,
  INVITATION_STATUS,
  MESSAGE,
  SEARCH_ICON,
  USERS_ICON,
  VARIANT,
  WIDTH
} from "../constants/common.constants";
import Button from "../components/button/button";
import Input from "../components/input/input";
import Icon from "../components/icon/icon";
import useTableInstance from "../components/table/useTableInstance";
import useUsers from "./useUsers";
import useAddUser from "./addUser/useAddUser";
import ModalContext from "../contexts/modal.context";
import { Tabs, Tab } from "../components/tabs/tabs";
import Avatar from "../components/avatar/avatar";
import getInitials, { buildUrl } from "../utils/string.utils";
import FormatDate from "../formatDate/formatDate";
import Chip from "../components/chip/chip";
import InvitationsActions from "./invitationsActions";
import { useRevokeUserInvitation } from "../api/invitation";
import SnackbarContext from "../contexts/snackbar.context";
import { OWN_ORGANIZATION_USER_DETAILS_URL } from "../constants/urls.constants";
import SessionContext from "../contexts/session.context";

export default function Users() {
  const { showModal, setAsyncLoading, closeModal } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const [currentTab, setCurrentTab] = useState("tab-0");

  const showAddUserModal = useAddUser();
  const revokeUserInvitation = useRevokeUserInvitation();

  const { id: localUserId } = useSelector((state: any) => state.user.profile);

  const {
    orgId,
    orgName,
    users,
    invitations,
    showAddAction,
    deleteActionAvailable,
    showEditAction,
    showSearchAction,
    isLoadingUsers,
    isLoadingInvitations,
    handleEditUser,
    retrieveUsers,
    retrieveInvitations
  } = useUsers();

  const { isAdmin } = useContext(SessionContext);

  const buildCell = (row: any, value: string) => {
    const url = buildUrl(OWN_ORGANIZATION_USER_DETAILS_URL, row.original.id);
    return (
      <a target="_blank" href={url} rel="noreferrer" className="noDecoration">
        {value}
      </a>
    );
  };

  const onDelete = useDeleteUser();

  const onShowAddUserModal = useCallback(
    (firstUser = false) =>
      showAddUserModal(orgId, firstUser, () => {
        setCurrentTab("tab-1");
        retrieveInvitations();
      }),
    [orgId, retrieveInvitations, showAddUserModal]
  );

  const onEditUser = useCallback(
    (id: string) => {
      handleEditUser(id);
    },
    [handleEditUser]
  );

  const deleteUser = useCallback(
    (id: string, name: string) =>
      showModal({
        title: "Delete user",
        clickAction: () => onDelete(id, name, orgId, retrieveUsers),
        actionText: "Yes, delete",
        content: <p>Are you sure you want to delete the user {name}?</p>
      }),
    [onDelete, showModal, retrieveUsers, orgId]
  );

  const onRevoke = useCallback(
    (invitationId: string, invitee: string) => {
      revokeUserInvitation(orgId, invitationId)
        .then(() => {
          console.log(`Invitation for ${invitee} revoked`);
          showSnackbar({
            text: `Invitation for ${invitee} revoked`,
            type: MESSAGE.info,
            icon: DELETE_ICON
          });
          setAsyncLoading(false);
          closeModal();
          retrieveInvitations();
        })
        .catch((error) => {
          console.error(
            `Error revoking invitation of ${invitee}. id: ${invitationId}. Status ${error.status}. ${error}`
          );
          showSnackbar({
            text: `Error revoking invitation of ${invitee}. ${error}`,
            type: MESSAGE.error,
            icon: ERROR_ICON
          });
          setAsyncLoading(false);
          closeModal();
        });
    },
    [
      orgId,
      revokeUserInvitation,
      closeModal,
      retrieveInvitations,
      setAsyncLoading,
      showSnackbar
    ]
  );

  const revokeInvitation = useCallback(
    (invitationId: string, invitee: string) =>
      showModal({
        title: "Revoke invitation",
        clickAction: () => onRevoke(invitationId, invitee),
        actionText: "Yes, revoke",
        content: (
          <p>Are you sure you want to revoke the invitation for {invitee}?</p>
        )
      }),
    [onRevoke, showModal]
  );

  const userTableColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        visible: "true",
        Cell: ({ row, value }: any) => {
          const url = buildUrl(
            OWN_ORGANIZATION_USER_DETAILS_URL,
            row.original.id
          );
          return (
            <a
              target="_blank"
              href={url}
              rel="noreferrer"
              className="noDecoration avatar-with-name"
            >
              <Avatar text={getInitials(value)} />
              <span>{value}</span>
            </a>
          );
        }
      },
      {
        Header: "Email",
        accessor: "email",
        visible: "true",
        Cell: ({ row, value }) => buildCell(row, value)
      },
      {
        Header: "Role",
        accessor: "role",
        visible: "true",
        Cell: ({ row, value }) => buildCell(row, value)
      },
      {
        Header: "Actions",
        accessor: "actions",
        visible: "onDemand",
        Cell: ({ row }) => {
          const userId = row.original.id;
          const showDelete = deleteActionAvailable && userId !== localUserId;

          return (
            <UsersActions
              row={row}
              showDeleteAction={showDelete}
              showEditAction={showEditAction}
              onEdit={onEditUser}
              onDelete={deleteUser}
            />
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const invitationsTableColumns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "invitee",
        visible: "true",
        Cell: ({ value }: any) => value
      },
      {
        Header: "Created by",
        accessor: "inviter",
        visible: "true",
        Cell: ({ value }) => (
          <div className="avatar-with-name">
            <Avatar text={getInitials(value)} />
            <span>{value}</span>
          </div>
        )
      },
      {
        Header: "Created",
        accessor: "created_at",
        visible: "true",
        Cell: ({ value }) => {
          const dateObj = new FormatDate(value, false);
          return dateObj.date;
        }
      },
      {
        Header: "Expires",
        accessor: "expires_at",
        visible: "true",
        Cell: ({ value }) => {
          const dateObj = new FormatDate(value, false);
          return dateObj.date;
        }
      },
      {
        Header: "Status",
        accessor: "status",
        visible: "true",
        Cell: ({ value }: { value: string }) => (
          <Chip
            variant={VARIANT.status}
            status={INVITATION_STATUS[value]}
            label={INVITATION_STATUS[value]}
          />
        )
      },
      {
        Header: "Actions",
        accessor: "actions",
        visible: "onDemand",
        Cell: ({ row }) => (
          <InvitationsActions row={row} onRevoke={revokeInvitation} />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const usersTable = useTableInstance(userTableColumns, users);
  const invitationsTable = useTableInstance(
    invitationsTableColumns,
    invitations
  );

  const onSearchChange = (event: any) => {
    usersTable.updateGlobalFilter(event.target.value);
    invitationsTable.updateGlobalFilter(event.target.value);
  };

  const onClickCell = (rowIndex: number, columnId: string) => {
    if (columnId !== "actions")
      handleEditUser(usersTable.instance.rows[rowIndex].original.id);

    return true;
  };

  return (
    <div className="usersContainer">
      <Header
        icon={<Icon image={USERS_ICON} alt="Users" />}
        title="Users management"
        subTitle={orgName}
      />
      <section className="usersContainer__actionBar">
        <ActionBar>
          <>
            {showSearchAction && (
              <Input
                icon={SEARCH_ICON}
                placeholder="Search"
                width={WIDTH.default}
                onChange={onSearchChange}
              />
            )}
            {showSearchAction && showAddAction && (
              <img
                className="usersContainer__actionBarContainer__divider"
                src={DIVIDER_ICON}
                alt=""
              />
            )}
            {showAddAction && (
              <Button
                onClick={() =>
                  onShowAddUserModal(!users.length && !invitations.length)
                }
                variant={VARIANT.primary}
              >
                <img src={ADD_ICON} alt="Create" />
              </Button>
            )}
          </>
        </ActionBar>
      </section>
      <div className="usersInfo">
        <section className="usersList">
          <Tabs currentTab={currentTab}>
            <Tab title="Users">
              <Table
                instance={usersTable.instance}
                onClickCell={onClickCell}
                isLoadingData={isLoadingUsers}
                pagination
              />
            </Tab>
            {isAdmin && (
              <Tab title={`Invitations (${invitations.length || 0})`}>
                <Table
                  instance={invitationsTable.instance}
                  isLoadingData={isLoadingInvitations}
                  pagination
                />
              </Tab>
            )}
          </Tabs>
        </section>
      </div>
    </div>
  );
}
