import React from "react";
import "./warning-banner.scss";
import { INFORMATION_ICON } from "../../constants/common.constants";

function WarningBanner() {
  return (
    <div className="warning-banner-container">
      <img
        className="warning-banner-container__icon"
        src={INFORMATION_ICON}
        alt=""
      />
      <span className="warning-banner-container__bold">Exempt device </span>
      <span className="warning-banner-container__text">Do not isolate</span>
    </div>
  );
}

export default WarningBanner;
