import {
  CONFIRMATION_TIME_REF,
  EXPIRATION_TIME_REF
} from "../../constants/common.constants";

class IdleManager {
  timeout;

  onTimeout;

  confirmTime;

  onNoConfirmation;

  eventHandler;

  isTracking;

  interval: any;

  constructor({
    timeout,
    onTimeout,
    confirmTime = 0,
    onNoConfirmation = () => {}
  }: any) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.confirmTime = confirmTime;
    this.onNoConfirmation = onNoConfirmation;
    this.eventHandler = this.updateExpirationTime.bind(this);
    this.isTracking = false;
  }

  startTracker() {
    this.track();
    this.updateExpirationTime();
    this.interval = setInterval(() => {
      const expirationTime = parseInt(
        `${localStorage.getItem(EXPIRATION_TIME_REF) || 0}`,
        10
      );
      const confirmationTime = parseInt(
        `${localStorage.getItem(CONFIRMATION_TIME_REF) || 0}`,
        10
      );
      if (expirationTime < Date.now()) {
        if (confirmationTime && confirmationTime < Date.now()) {
          this.cleanUp();
          this.onNoConfirmation();
        } else if (this.isTracking && this.onTimeout) {
          this.stopTrack();
          this.onTimeout();
          localStorage.setItem(
            CONFIRMATION_TIME_REF,
            `${Date.now() + this.confirmTime * 1000}`
          );
        }
      }
    }, 1000);
  }

  updateExpirationTime() {
    localStorage.setItem(
      EXPIRATION_TIME_REF,
      `${Date.now() + this.timeout * 1000}`
    );
  }

  track() {
    this.isTracking = true;
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  stopTrack() {
    this.isTracking = false;
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    clearInterval(this.interval);
    localStorage.removeItem(EXPIRATION_TIME_REF);
    localStorage.removeItem(CONFIRMATION_TIME_REF);
  }
}
export default IdleManager;
