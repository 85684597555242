import React from "react";
import "./skeleton.scss";
import { SKELETON_VARIANT } from "../../constants/common.constants";

export default function Skeleton({ variant = SKELETON_VARIANT.text }) {
  const skeletons: any = {
    event: (
      <div className="event-skeleton">
        <div className="event-skeleton__accordion">
          <Skeleton variant={SKELETON_VARIANT.avatar} />
          <div className="event-skeleton__accordion__header">
            <div className="event-skeleton__accordion__header__title">
              <Skeleton variant={SKELETON_VARIANT.title} />
            </div>
            <div className="event-skeleton__accordion__header__subtitle">
              <Skeleton variant={SKELETON_VARIANT.subtitle} />
            </div>
          </div>
        </div>
        <div className="event-skeleton__content">
          <div className="skeleton event-skeleton__content__line" />
          <div className="event-skeleton__content__data">
            <div className="event-skeleton__content__data__label">
              <Skeleton variant={SKELETON_VARIANT.subtitle} />
            </div>
            <div className="event-skeleton__content__data__value">
              <Skeleton variant={SKELETON_VARIANT.title} />
            </div>
            <Skeleton variant={SKELETON_VARIANT.textArea} />
          </div>
        </div>
      </div>
    ),
    default: <div className={`skeleton skeleton-${variant}`} />
  };
  return skeletons[variant] || skeletons.default;
}
