import { IInvitation } from "../users/users.types";
import { useDelete, useGet, usePost } from "./api";

export function useGetUserInvitations() {
  const getApi = useGet();

  async function run(orgId: string) {
    getApi.setEndpoint(`${process.env.REACT_APP_API_INVITATIONS}/${orgId}`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export default function useSendInvitation() {
  const postApi = usePost();

  async function run(invitation: IInvitation) {
    postApi.setEndpoint(`${process.env.REACT_APP_API_INVITATIONS}`);
    postApi.setBody(invitation);
    return postApi.run(postApi.getOptions());
  }
  return run;
}

export function useRevokeUserInvitation() {
  const api = useDelete();

  async function run(orgId: string, invitationId: string) {
    api.setEndpoint(
      `${process.env.REACT_APP_API_INVITATIONS}/${orgId}/${invitationId}`
    );
    return api.run(api.getOptions());
  }
  return run;
}
