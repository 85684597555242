import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./navBar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import SVG from "../svg/svg";
import AvatarMenu from "./avatarMenu/avatarMenu";
import Permissions from "../../permissions/permissions";
import { DIVIDER_ICON, LOGO_ICON } from "../../constants/common.constants";
import Tooltip, { tooltipTypes } from "../tooltip/tooltip";
import getInitials, { buildUrl } from "../../utils/string.utils";
import {
  INCIDENTS_URL,
  ORGANIZATIONS_DETAILS_URL,
  ORGANIZATIONS_URL,
  SENSORS_URL,
  USERS_URL
} from "../../constants/urls.constants";

export default function NavBar() {
  const ICON_CLASSES = Object.freeze({
    dashboard: "navBarDashboard",
    incidents: "navBarIncidents",
    organizations: "navBarOrganizations",
    users: "navBarUsers",
    sensors: "navBarSensors",
    notifications: "navBarNotifications",
    active: "--active"
  });

  const localUser = useSelector((state: any) => state.user.profile);

  const initials = getInitials(localUser.name);

  const orgId = useSelector((state: any) => state.user.orgId);

  const { Pages } = Permissions();

  const showUsers = Pages.users.canNavigate(localUser.role);

  const [incidentsIconClass, setIncidentsIconClass] = useState<string>(
    ICON_CLASSES.incidents
  );
  const [organizationsIconClass, setOrganizationsIconClass] = useState<string>(
    ICON_CLASSES.organizations
  );
  const [usersIconClass, setUsersIconClass] = useState<string>(
    ICON_CLASSES.users
  );
  const [sensorsIconClass, setSensorsIconClass] = useState<string>(
    ICON_CLASSES.sensors
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    switch (true) {
      case /\/incidents/.test(location.pathname):
        setIncidentsIconClass(
          `has-tooltip ${ICON_CLASSES.incidents} ${ICON_CLASSES.incidents}${ICON_CLASSES.active}`
        );
        setOrganizationsIconClass(`has-tooltip ${ICON_CLASSES.organizations}`);
        setUsersIconClass(`has-tooltip ${ICON_CLASSES.users}`);
        setSensorsIconClass(`has-tooltip ${ICON_CLASSES.sensors}`);
        break;
      case /\/organizations/.test(location.pathname):
        setIncidentsIconClass(`has-tooltip ${ICON_CLASSES.incidents}`);
        setOrganizationsIconClass(
          `has-tooltip ${ICON_CLASSES.organizations} ${ICON_CLASSES.organizations}${ICON_CLASSES.active}`
        );
        setUsersIconClass(`has-tooltip ${ICON_CLASSES.users}`);
        setSensorsIconClass(`has-tooltip ${ICON_CLASSES.sensors}`);
        break;
      case /\/users/.test(location.pathname):
        setIncidentsIconClass(`has-tooltip ${ICON_CLASSES.incidents}`);
        setOrganizationsIconClass(`has-tooltip ${ICON_CLASSES.organizations}`);
        setUsersIconClass(
          `has-tooltip ${ICON_CLASSES.users} ${ICON_CLASSES.users}${ICON_CLASSES.active}`
        );
        setSensorsIconClass(`has-tooltip ${ICON_CLASSES.sensors}`);
        break;
      case /\/sensors/.test(location.pathname):
        setIncidentsIconClass(`has-tooltip ${ICON_CLASSES.incidents}`);
        setOrganizationsIconClass(`has-tooltip ${ICON_CLASSES.organizations}`);
        setUsersIconClass(`has-tooltip ${ICON_CLASSES.users}`);
        setSensorsIconClass(
          `has-tooltip ${ICON_CLASSES.sensors} ${ICON_CLASSES.sensors}${ICON_CLASSES.active}`
        );
        break;
      case /\/settings$/.test(location.pathname):
      case /\/settings\/$/.test(location.pathname):
        setIncidentsIconClass(`has-tooltip ${ICON_CLASSES.incidents}`);
        setOrganizationsIconClass(`has-tooltip ${ICON_CLASSES.organizations}`);
        setUsersIconClass(`has-tooltip ${ICON_CLASSES.users}`);
        setSensorsIconClass(`has-tooltip ${ICON_CLASSES.sensors}`);
        break;
      default:
        break;
    }
  }, [
    location,
    ICON_CLASSES.incidents,
    ICON_CLASSES.organizations,
    ICON_CLASSES.users,
    ICON_CLASSES.sensors,
    ICON_CLASSES.active
  ]);

  const onClickIncidents = () => navigate(buildUrl(INCIDENTS_URL));

  const onClickOrganizations = () => {
    if (Pages.organizations.canNavigate(localUser.role)) {
      navigate(buildUrl(ORGANIZATIONS_URL));
    } else {
      navigate(buildUrl(ORGANIZATIONS_DETAILS_URL, orgId));
    }
  };

  const onClickUsers = () => navigate(buildUrl(USERS_URL));

  const onClickSensors = () => navigate(buildUrl(SENSORS_URL));

  return (
    <section className="navBarContainer">
      <img
        className="navBar__brand-logo"
        onClick={onClickIncidents}
        src={LOGO_ICON}
        alt="Soteria Defense MDR"
      />
      <nav className="navBarActions">
        <div className={incidentsIconClass}>
          <SVG onClick={onClickIncidents} file="incidents.svg" />
          <Tooltip type={tooltipTypes.info} text="Incidents" />
        </div>
        <div className={organizationsIconClass}>
          <SVG onClick={onClickOrganizations} file="organizations.svg" />
          <Tooltip type={tooltipTypes.info} text="Organizations" />
        </div>
        {showUsers && (
          <div className={usersIconClass}>
            <SVG onClick={onClickUsers} file="users.svg" />
            <Tooltip type={tooltipTypes.info} text="Users" />
          </div>
        )}
        <div className={sensorsIconClass}>
          <SVG onClick={onClickSensors} file="sensors.svg" />
          <Tooltip type={tooltipTypes.info} text="Sensors" />
        </div>
        <img className="navBarDivider" src={DIVIDER_ICON} alt="" />
        <AvatarMenu user={initials} />
      </nav>
    </section>
  );
}
