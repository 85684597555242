import {
  MiddlewareArray,
  combineReducers,
  configureStore
} from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import userReducer from "./settings/userSlice";

export const STORAGE_KEY = "soteria-mdr-state";

export const loadState = () => {
  try {
    const serializedData = localStorage.getItem(STORAGE_KEY);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (error: any) {
    console.log("Error loading state from storage.", error.message);
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedData = JSON.stringify(state);
    localStorage.setItem(STORAGE_KEY, serializedData);
  } catch (error: any) {
    console.log("Error saving state to storage.", error.message);
  }
};

const appReducer = combineReducers({
  user: userReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "user/logOut") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: new MiddlewareArray().concat(LogRocket.reduxMiddleware())
});
store.subscribe(() =>
  saveState({
    user: store.getState().user
  })
);

export default store;
