/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import React, { useEffect, useCallback, useContext, useMemo } from "react";
import "./incident-list.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Table from "../components/table/table";
import Header from "../components/header/header";
import Icon from "../components/icon/icon";
import ActionBar from "../components/actionBar/actionBar";
import {
  ACTIONS,
  CHECK,
  DOWNLOAD_ICON,
  EVENTS,
  FILTER_ICON,
  INCIDENTS_ICON,
  STATE,
  SYNC_ICON,
  TABLE,
  VARIANT
} from "../constants/common.constants";
import Select from "../components/select/select";
import IncidentsFilters from "./components/incidentsFilters";
import Pagination from "../components/table/pagination/pagination";
import { closeReasonsMapper } from "../mappers/incidentListMappers";
import Button from "../components/button/button";
import Tooltip, { tooltipTypes } from "../components/tooltip/tooltip";
import IncidentListfiltersContext from "../contexts/incident-list-filters.context";
import IncidentListContext from "../contexts/incident-list.context";
import Permissions from "../permissions/permissions";
import { INCIDENT_URL } from "../constants/urls.constants";
import { buildUrl } from "../utils/string.utils";

export default function Incidents() {
  const {
    table,
    onClickClose,
    closeReasons,
    handlePreviousPage,
    handleNextPage,
    handleGotoPage,
    columns,
    handlers,
    onClickAssign,
    loading,
    load,
    pages,
    rows,
    incidents,
    uncheckAllIncidents,
    areRowsSelected,
    showCloseAction,
    closeSelectedIncidents,
    loadDownloadData,
    loadingDownloadData
  } = useContext(IncidentListContext);
  const { toggleShowFilters, showFilters } = useContext(
    IncidentListfiltersContext
  );

  const localUser = useSelector((state: any) => state.user.profile);
  const { Incidents: permissions } = Permissions();
  const navigate = useNavigate();

  const headerAvailable: boolean = useMemo(
    () => permissions.sections.info.header.canSee(localUser.role),
    [localUser.role, permissions.sections.info.header]
  );

  const assignedAvailable: boolean = useMemo(
    () => permissions.sections.info.assigned.canSee(localUser.role),
    [localUser.role, permissions.sections.info.assigned]
  );

  const handleSync = useCallback(() => {
    if (headerAvailable) {
      uncheckAllIncidents();
    }
    load();
  }, [uncheckAllIncidents, headerAvailable, load]);

  const onClickCell = useCallback(
    (rowIndex: number, columnId: string) => {
      if (
        columnId !== TABLE.INCIDENTS.CELLS.MDR_USERS.check.accessor &&
        columnId !== TABLE.INCIDENTS.CELLS.MDR_USERS.assigned.accessor &&
        columnId !== TABLE.INCIDENTS.CELLS.MDR_USERS.actions.accessor
      ) {
        const { id } = incidents[rowIndex];
        navigate(buildUrl(INCIDENT_URL, id));
      }
    },
    [incidents, navigate]
  );

  const onMouseOverRules = useCallback(
    (rowIndex: number, columnId: string) => {
      if (columnId === TABLE.INCIDENTS.CELLS.MDR_USERS.rules.accessor) {
        const state = {
          type: STATE.tooltip,
          data: ACTIONS.show
        };

        const rules = table.getCellData(rowIndex, columnId);

        if (rules.length > 1) {
          table.setCellState(
            rowIndex,
            TABLE.INCIDENTS.CELLS.MDR_USERS.rules.index,
            state
          );
        }
      }

      return true;
    },
    [table]
  );

  const onMouseOutRules = useCallback(
    (rowIndex: number, columnId: string) => {
      if (columnId === TABLE.INCIDENTS.CELLS.MDR_USERS.rules.accessor) {
        const rules = table.getCellData(rowIndex, columnId);

        if (rules.length > 1) {
          table.setCellState(
            rowIndex,
            TABLE.INCIDENTS.CELLS.MDR_USERS.rules.index,
            {}
          );
        }
      }
      return false;
    },
    [table]
  );

  // const onSearchChange = (event) => handleSearch(event.target.value);

  useEffect(() => {
    if (!headerAvailable) {
      return;
    }

    if (!areRowsSelected) {
      // if(!rowSelected.size){
      columns.setHeaderCheckState(CHECK.unchecked);
      return;
    }

    if (table.instance.isAllPageRowsSelected) {
      columns.setHeaderCheckState(CHECK.checked);
      return;
    }

    columns.setHeaderCheckState(CHECK.indeterminate);
  }, [
    headerAvailable,
    columns,
    table.instance.isAllPageRowsSelected,
    areRowsSelected
  ]);

  useEffect(() => {
    if (!assignedAvailable || !Object.keys(handlers).length) {
      return;
    }

    window.addEventListener(EVENTS.assignIncident, onClickAssign);

    return () => {
      window.removeEventListener(EVENTS.assignIncident, onClickAssign);
    };
  }, [assignedAvailable, onClickAssign, handlers]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => uncheckAllIncidents(), [incidents]);

  return (
    <div className="incidentsContainer">
      <Header
        icon={<Icon image={INCIDENTS_ICON} alt="Incidents" />}
        title="Incidents"
      >
        <ActionBar>
          <div className="has-tooltip incidentsSync">
            <img src={SYNC_ICON} alt="sync" onClick={handleSync} />
            <Tooltip type={tooltipTypes.info} text="Sync" />
          </div>
        </ActionBar>
      </Header>
      <section className="incidentsContainer__actionBar">
        <ActionBar>
          <>
            {showCloseAction && (
              <div className="incidentsContainer__actionBar__bulk-actions">
                <Select
                  value="Close"
                  onChange={(reason: string) =>
                    onClickClose(reason, closeSelectedIncidents)
                  }
                  items={closeReasonsMapper(closeReasons)}
                />
              </div>
            )}
            <div className="has-tooltip incidentsFilter">
              <Button
                onClick={toggleShowFilters}
                variant={VARIANT.secondary}
                active={showFilters}
              >
                <img src={FILTER_ICON} alt="Filters" />
              </Button>
              <Tooltip type={tooltipTypes.info} text="Apply Filters" />
            </div>
            <div className="has-tooltip incidentsDownload">
              <Button
                onClick={loadDownloadData}
                variant={VARIANT.secondary}
                loading={loadingDownloadData}
              >
                <img src={DOWNLOAD_ICON} alt="Download" />
              </Button>
              <Tooltip type={tooltipTypes.info} text="Download" />
            </div>
            {/* <Input
                icon={SEARCH_ICON}
                placeholder="Search"
                isValid
                width={WIDTH.default}
                onChange={onSearchChange}
                value={searchText}
              /> */}
          </>
        </ActionBar>
      </section>
      <IncidentsFilters />
      <section className="incidentsContainer__info">
        <Table
          instance={table.instance}
          onClickCell={onClickCell}
          onMouseOver={onMouseOverRules}
          onMouseOut={onMouseOutRules}
          isLoadingData={loading}
        />
        <Pagination
          pages={pages}
          rows={rows}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
          onGoTo={handleGotoPage}
        />
      </section>
    </div>
  );
}
