import React, { useMemo } from "react";
import "./organization.scss";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import useOrganization from "./useOrganization";

import Header from "../../components/header/header";
import Button from "../../components/button/button";

import Details from "./details";
import OrganizationUsers from "./organizationUsers";
import Configuration from "./configuration";

import {
  VARIANT,
  ORGANIZATIONS_ICON,
  DIVIDER_ICON,
  ROLES
} from "../../constants/common.constants";
import Icon from "../../components/icon/icon";
import ActionBar from "../../components/actionBar/actionBar";

export default function Organization() {
  const {
    validationSchema,
    isCreateMode,
    headerTitle,
    isLoadingData,
    isLoadingCatalog,
    enableUsers,
    usersCount,
    isLoadingUserCount,
    edrTypes,
    residencyRegions,
    timeZones,
    connectionTypes,
    incidentHandlers,
    initialValues,
    onSubmit,
    handleCancel,
    handleManageUsers
  } = useOrganization();
  const localUser = useSelector((state: any) => state.user.profile);

  const variant = useMemo(
    () => (isCreateMode ? VARIANT.primary : VARIANT.secondary),
    [isCreateMode]
  );
  const buttonText = useMemo(
    () => (isCreateMode ? "Create" : "Save"),
    [isCreateMode]
  );

  const isClientUser = useMemo(
    () =>
      localUser.role === ROLES.clientUser ||
      localUser.role === ROLES.providerUser,
    [localUser.role]
  );
  const isMDRUser = useMemo(
    () => localUser.role === ROLES.mdrAdmin || localUser.role === ROLES.mdrUser,
    [localUser.role]
  );

  const detailsClassName = useMemo(
    () =>
      `organizationContainer__details__info${isClientUser ? " isClient" : ""}${
        isCreateMode ? " isCreating" : ""
      }${isMDRUser ? " isMDRUser" : ""}`,
    [isClientUser, isCreateMode, isMDRUser]
  );

  return (
    <div className="organizationContainer">
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => onSubmit(values)}
        validationSchema={validationSchema}
        validateOnMount={!isCreateMode}
        enableReinitialize
      >
        {(formik: any) => (
          <form>
            <Header
              icon={<Icon image={ORGANIZATIONS_ICON} alt="Organization" />}
              title={headerTitle}
              isLoading={isLoadingData}
            >
              <ActionBar>
                <>
                  <Button
                    text={buttonText}
                    onClick={formik.handleSubmit}
                    variant={variant}
                    formId="organizationContainer__details__info"
                    disabled={!formik.isValid}
                    loading={formik.isSubmitting}
                  />
                  <img
                    className="organizationContainer__header__divider"
                    src={DIVIDER_ICON}
                    alt=""
                  />
                  <Button
                    text="Cancel"
                    onClick={handleCancel}
                    variant={VARIANT.secondary}
                  />
                </>
              </ActionBar>
            </Header>
            <div className="organizationContainer__info">
              <section className="organizationContainer__details">
                <h3 className="organizationContainer__details__title">
                  Details
                </h3>
                <div className={detailsClassName}>
                  <Details
                    formik={formik}
                    isLoadingData={isLoadingData}
                    isCreateMode={isCreateMode}
                    isClientUser={isClientUser}
                    isMDRUser={isMDRUser}
                    residencyRegions={residencyRegions}
                    timeZones={timeZones}
                    connectionTypes={connectionTypes}
                    incidentHandlers={incidentHandlers}
                    isLoadingCatalog={isLoadingCatalog}
                  />
                </div>
              </section>
              {!isClientUser && (
                <Configuration
                  formik={formik}
                  isLoadingData={isLoadingData}
                  edrTypes={edrTypes}
                  isLoadingCatalog={isLoadingCatalog}
                />
              )}
              {enableUsers && (
                <OrganizationUsers
                  isLoading={isLoadingUserCount}
                  count={usersCount}
                  onManage={handleManageUsers}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
