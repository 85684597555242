/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo } from "react";
import "./detailLine.scss";
import CodeBlock from "../../components/codeBlock/codeBlock";
import { IAnyPropertyNameAndAnyValue } from "../../types/common.types";
import { EVENT_CHARS_LIMIT } from "../../constants/common.constants";

type IDetailLineProps = {
  data: IAnyPropertyNameAndAnyValue;
  level?: number;
  hideLabels?: boolean;
};

export default function DetailLine({
  data,
  level = 1,
  hideLabels = false
}: IDetailLineProps) {
  const className = useMemo(() => {
    let result = "detailLine";
    if (level !== 1) result += " detailLine-child";
    if (hideLabels) result += " is-array";
    return result;
  }, [hideLabels, level]);

  const getFieldType = useCallback(
    (value: any) => {
      if (typeof value === "number") return <p>{value}</p>;
      if (typeof value === "string")
        return value.length < EVENT_CHARS_LIMIT ? (
          <p>{value}</p>
        ) : (
          <CodeBlock data={value} />
        );
      if (level > 3) return <CodeBlock data={value} />;
      return (
        <DetailLine
          data={value}
          level={level + 1}
          hideLabels={Array.isArray(value)}
        />
      );
    },
    [level]
  );

  return (
    <div className={className}>
      {Object.keys(data).map((item: any, index: number) => (
        <div className={`detailLine__item level-${level}`} key={index}>
          {!hideLabels && (
            <label className="detailLine__item__label">{item}</label>
          )}
          {getFieldType(data[item])}
        </div>
      ))}
    </div>
  );
}
