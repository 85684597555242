/* eslint-disable no-plusplus */
import { WRONG_DATA_INITIALS } from "../constants/common.constants";

export const getInitials = (value: string): string => {
  if (!value) return WRONG_DATA_INITIALS;
  const initials: any = value.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);

  return value
    ? initials?.join("").substring(0, 2).toUpperCase()
    : initials?.substring(0, 2).toUpperCase();
};

function isUpperCase(string: string) {
  return /^[A-Z]+$/.test(string);
}

export const propNameformatter = (propName: string): string => {
  const result = propName.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

  return isUpperCase(result.charAt(1))
    ? result
    : result.charAt(0).toLowerCase() + result.slice(1);
};

export const buildUrl = (baseURL: string, ...args: string[]): string => {
  let argsIndex = 0;
  const splittedURL: string[] = baseURL.split("/").map((slice: string) => {
    if (slice[0] === ":") {
      argsIndex++;
      return args[argsIndex - 1];
    }

    return slice;
  });

  return `/${splittedURL.join("/")}`;
};

export default getInitials;
