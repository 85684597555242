import React from "react";
import "./users.scss";
import { DELETE_ICON, VARIANT } from "../constants/common.constants";
import Button from "../components/button/button";
import Tooltip, { tooltipTypes } from "../components/tooltip/tooltip";
import { IInvitationActionsProps } from "./users.types";

export default function InvitationsActions(props: IInvitationActionsProps) {
  const {
    row: {
      original: { invitation_id, invitee }
    },
    onRevoke
  } = props;

  const onRevokeAction = () => onRevoke(invitation_id, invitee);

  return (
    <div className="usersActionsContainer">
      <div className="has-tooltip usersActionsContainer__delete">
        <Button variant={VARIANT.secondary} onClick={onRevokeAction} iconColor>
          <img src={DELETE_ICON} alt="Delete" />
        </Button>
        <Tooltip type={tooltipTypes.info} text="Revoke invitation" />
      </div>
    </div>
  );
}
