/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import { createSlice } from "@reduxjs/toolkit";

export type IProfile = {
  id: string;
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  phone_number_1: string;
  phone_number_2: string;
  role_id: string;
  role: string;
};

export type IUser = {
  profile: IProfile;
  loggedIn: boolean;
  token: string;
  orgId: string | null;
  orgName: string;
  roles: string[] | null;
};

export const initialState: IUser = {
  profile: {
    id: "",
    email: "",
    name: "",
    first_name: "",
    last_name: "",
    phone_number_1: "",
    phone_number_2: "",
    role_id: "",
    role: ""
  },
  loggedIn: false,
  token: "",
  orgId: null,
  orgName: "",
  roles: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logIn(state, action) {
      state.profile = { ...state.profile, ...action.payload };
      state.loggedIn = true;
    },
    logOut(state) {
      state.loggedIn = false;
    },
    updateProfile(state, action) {
      state.profile = { ...state.profile, ...action.payload };
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setOrgId(state, action) {
      state.orgId = action.payload;
    },
    setRoles(state, action) {
      state.roles = action.payload;
    },
    setOrgName(state, action) {
      state.orgName = action.payload;
    }
  }
});
export const {
  logIn,
  logOut,
  updateProfile,
  setToken,
  setOrgId,
  setRoles,
  setOrgName
} = userSlice.actions;
export default userSlice.reducer;
