import { useCallback, useContext } from "react";
import { MESSAGE, ORGANIZATIONS_ICON } from "../constants/common.constants";
import ModalContext from "../contexts/modal.context";
import SnackbarContext from "../contexts/snackbar.context";

export default function useDeleteOrganization() {
  const { closeModal, setAsyncLoading } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const onDelete = useCallback(
    // eslint-disable-next-line no-unused-vars
    ({ name }: any) => {
      setAsyncLoading(true);
      setTimeout(() => {
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: `Organization ${name} deleted`,
          type: MESSAGE.info,
          icon: ORGANIZATIONS_ICON
        });
      }, 1000);
    },
    [setAsyncLoading, closeModal, showSnackbar]
  );

  return onDelete;
}
