import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useRef
} from "react";
import {
  IShowSnackbar,
  ISnackbarData
} from "../components/snackbar/snackbar.types";
import { ISnackbarContext, ISnackbarQueue } from "./types/snackbar.types";
import { IProvider } from "../types/common.types";

const SnackbarContext = createContext<ISnackbarContext>({} as ISnackbarContext);

export function SnackbarProvider({ children }: IProvider) {
  const [snackbarQueue, setSnackbarQueue] = useState<ISnackbarQueue>({});
  const snackbarQueueRef = useRef<ISnackbarQueue>({});

  const killSnackbar = useCallback((id: number): void => {
    if (!snackbarQueueRef.current[id]) return;
    clearTimeout(snackbarQueueRef.current[id].timeout);
    snackbarQueueRef.current[id].onClose();
    delete snackbarQueueRef.current[id];
    setSnackbarQueue({ ...snackbarQueueRef.current });
  }, []);

  const dispatchSnackbar = useCallback(
    (data: ISnackbarData): void => {
      snackbarQueueRef.current[data.key] = {
        timeout: setTimeout(() => killSnackbar(data.key), data.timeout),
        ...data
      };
      setSnackbarQueue({ ...snackbarQueueRef.current });
    },
    [killSnackbar]
  );

  const showSnackbar = useCallback(
    ({
      text = "",
      onClose = () => {},
      alt = "",
      type = "info",
      icon = "",
      content = null,
      loadingSnackbar = false,
      timeout = 5000
    }: IShowSnackbar) => {
      const key: number = new Date().getTime();
      dispatchSnackbar({
        text,
        onClose,
        alt,
        type,
        icon,
        content,
        loadingSnackbar,
        key,
        timeout
      });
      return key;
    },
    [dispatchSnackbar]
  );

  const returnedValue: ISnackbarContext = useMemo(
    () => ({
      showSnackbar,
      snackbarQueue,
      killSnackbar
    }),
    [showSnackbar, snackbarQueue, killSnackbar]
  );

  return (
    <SnackbarContext.Provider value={returnedValue}>
      {children}
    </SnackbarContext.Provider>
  );
}

export default SnackbarContext;
