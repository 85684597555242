import { useGet } from "./api";

export function useGetSensors() {
  const getApi = useGet();

  async function run(orgId: string, parameters = "") {
    const endpoint = `${process.env.REACT_APP_API_SENSORS}/${orgId}${
      parameters.length ? `?${parameters}` : ""
    }`;
    getApi.setEndpoint(endpoint);
    return getApi.runReturnResponse(getApi.getOptions());
  }
  return run;
}

export function useGetSensorsFilters() {
  const getApi = useGet();

  async function run(orgId: string) {
    getApi.setEndpoint(`${process.env.REACT_APP_API_SENSORS}/${orgId}/filters`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}
