import "./loginPrompt.scss";
import React from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  EMAIL_REGEX,
  LOGIN_PROMPT_ICON,
  VARIANT,
  WIDTH
} from "../constants/common.constants";
import Button from "../components/button/button";
import { ILoginPrompt } from "../contexts/types/login.types";
import InputLogin from "../components/input-login/input-login";

function LoginPrompt({
  sendLoginRequest,
  requestError = false,
  isOrgLoading = false
}: ILoginPrompt) {
  const onSubmit = ({ username }: any) => sendLoginRequest(username);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Email address is required")
      .email("Invalid email address")
      .matches(EMAIL_REGEX, "Invalid email address")
  });

  return (
    <div className="container">
      <div className="prompt">
        <header className="header">
          <img className="logo" alt="soteria-logo" src={LOGIN_PROMPT_ICON} />
          <h1 className="title">Welcome</h1>
          <p className="message">Log in to continue to Soteria Defense MDR</p>
        </header>
        <Formik
          initialValues={{ username: "" }}
          onSubmit={(values: any) => onSubmit(values)}
          validateOnMount
          validationSchema={validationSchema}
        >
          {({
            touched,
            values,
            errors,
            isValid,
            handleSubmit,
            handleChange
          }) => (
            <form className="content" autoComplete="on" onSubmit={handleSubmit}>
              <Field
                name="username"
                type="email"
                className="username"
                autoComplete="username"
                error={
                  errors.username || requestError ? "Invalid email address" : ""
                }
                onChange={handleChange}
                value={values.username}
                width={WIDTH.max}
                touched={touched.username}
                placeholder="Email address"
                component={InputLogin}
              />
              <Button
                variant={VARIANT.primary}
                onClick={handleSubmit}
                disabled={!isValid}
                loading={isOrgLoading}
              >
                Continue
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default LoginPrompt;
