import React, { useCallback, useContext } from "react";
import "./incidentActivityHeader.scss";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  VARIANT,
  ADD_EVENT_FORM_ID,
  MESSAGE,
  SUCCESS_ICON,
  ERROR_ICON
} from "../../constants/common.constants";
import ModalContext from "../../contexts/modal.context";
import AddEvent from "./addEvent/addEvent";
import ActionBar from "../../components/actionBar/actionBar";
import Button from "../../components/button/button";
import { IncidentContext } from "../incident";
import SnackbarContext from "../../contexts/snackbar.context";
import { useAddEvent } from "../../api/events";

export default function IncidentActivityHeader({ onAddEvent }: any) {
  const { id } = useContext(IncidentContext);
  const { showModal, closeModal } = useContext(ModalContext);

  const addEvent = useAddEvent();

  const addIcon = `${process.env.REACT_APP_CDN_IMAGES}/add.svg`;

  const { setAsyncLoading } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const validationSchema = Yup.object().shape({
    event_id: Yup.string().required("This is required")
  });

  const onSubmitAddEvent = useCallback(
    async ({ event_id }: any) => {
      try {
        setAsyncLoading(true);
        const body = {
          event_id
        };
        const event = await addEvent(id, body);
        setAsyncLoading(false);
        onAddEvent(event);
        showSnackbar({
          text: `Event ${event_id} added`,
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
      } catch (error) {
        setAsyncLoading(false);
        console.error(
          `Error adding event ${event_id} to incident ${id}. ${error}`
        );
        showSnackbar({
          text: `Error adding event ${event_id}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
      }
      closeModal();
    },
    [addEvent, closeModal, id, onAddEvent, setAsyncLoading, showSnackbar]
  );

  const onClickAddEvent = useCallback(() => {
    showModal({
      title: "New event",
      actionText: "Create",
      content: (
        <Formik
          initialValues={{ event_id: "" }}
          onSubmit={(values: any) => onSubmitAddEvent(values)}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => <AddEvent formik={formik} />}
        </Formik>
      ),
      formId: ADD_EVENT_FORM_ID
    });
  }, [showModal, validationSchema, onSubmitAddEvent]);

  return (
    <div className="incidentContainer__activity__header">
      <ActionBar>
        <Button variant={VARIANT.primary} onClick={onClickAddEvent}>
          <img src={addIcon} alt="Add" />
        </Button>
      </ActionBar>
    </div>
  );
}
