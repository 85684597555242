import React from "react";
import "./incidentDetails.scss";
import "./incidentDetailsCollapse.scss";
import Icon from "../../components/icon/icon";
import Chip from "../../components/chip/chip";
import { SHAPE, VARIANT } from "../../constants/common.constants";
import Tooltip, { tooltipTypes } from "../../components/tooltip/tooltip";
import { IIncidentDetailsCollapseProps } from "./incidentDetails.types";

export default function IncidentDetailsCollapse(
  props: IIncidentDetailsCollapseProps
) {
  const {
    status,
    category,
    severity,
    onExpand,
    riskIcon,
    severityIcon,
    informationIcon
  } = props;
  const collapseOpenIcon = `${process.env.REACT_APP_CDN_IMAGES}/collapseOpen.svg`;

  const headerClass =
    "has-tooltip incidentContainer__details__header-collapse incidentContainer__details__section--collapse incidentContainer__details__section--border-bottom";
  const headerTooltipText = "Open all details";

  const statusClass =
    "has-tooltip incidentContainer__details__status-collapse incidentContainer__details__section--collapse incidentContainer__details__section--border-bottom";
  const statusTooltipText = `${status} status`;

  const categoryClass =
    "has-tooltip incidentContainer__details__category-collapse incidentContainer__details__section--collapse";
  const categoryTooltipText = category
    ? `${category} category`
    : "Category not defined";

  const severityClass =
    "has-tooltip incidentContainer__details__severity-collapse incidentContainer__details__section--collapse incidentContainer__details__section--border-bottom";
  const severityTooltipText = severity
    ? `${severity} severity`
    : "Severity not defined";

  const moreDetailsClass =
    "has-tooltip incidentContainer__details__more-collapse incidentContainer__details__section--collapse";
  const moreDetailsTooltipText = "Show more details";

  return (
    <>
      <section className={headerClass}>
        <Icon image={collapseOpenIcon} onClick={onExpand} />
        <div className="incidentContainer__details__tooltip">
          <Tooltip type={tooltipTypes.info}>
            <p className="incidentContainer__details__tooltip__text">
              {headerTooltipText}
            </p>
          </Tooltip>
        </div>
      </section>
      <div className="incidentContainer__division__header" />
      <section className={statusClass} onClick={onExpand}>
        <Chip variant={VARIANT.status} status={status} shape={SHAPE.circle} />
        <div className="incidentContainer__details__tooltip">
          <Tooltip type={tooltipTypes.info}>
            <p className="incidentContainer__details__tooltip__text">
              {statusTooltipText}
            </p>
          </Tooltip>
        </div>
      </section>
      <div className="incidentContainer__division__status" />
      <section className={categoryClass} onClick={onExpand}>
        <div className="incidentContainer__details__icon">{riskIcon}</div>
        <div className="incidentContainer__details__tooltip">
          <Tooltip type={tooltipTypes.info}>
            <p className="incidentContainer__details__tooltip__text">
              {categoryTooltipText}
            </p>
          </Tooltip>
        </div>
      </section>
      <div className="incidentContainer__division__category" />
      <section className={severityClass} onClick={onExpand}>
        <div className="incidentContainer__details__icon">{severityIcon}</div>
        <div className="incidentContainer__details__tooltip">
          <Tooltip type={tooltipTypes.info}>
            <p className="incidentContainer__details__tooltip__text">
              {severityTooltipText}
            </p>
          </Tooltip>
        </div>
      </section>
      <div className="incidentContainer__division__severity" />
      <section className={moreDetailsClass} onClick={onExpand}>
        <div className="incidentContainer__details__icon">
          {informationIcon}
        </div>
        <div className="incidentContainer__details__tooltip">
          <Tooltip type={tooltipTypes.info}>
            <p className="incidentContainer__details__tooltip__text">
              {moreDetailsTooltipText}
            </p>
          </Tooltip>
        </div>
      </section>
      <div className="incidentContainer__division__more" />
    </>
  );
}
