import React, { useEffect, useState } from "react";
import useSVG from "./useSVG";
import useIsMounted from "../../hooks/useIsMounted";

type IProps = { file: string; onClick: any };

export default function SVG({ file, onClick }: IProps) {
  const get = useSVG();
  const isMounted = useIsMounted();

  const [data, setData] = useState<any>(null);
  useEffect(() => {
    if (data) {
      return;
    }
    get(file)
      .then(
        (res) =>
          isMounted.current &&
          setData({
            __html: res
          })
      )
      .catch((error) => console.error(error));
  }, [data, get, file, isMounted]);

  return data && <div onClick={onClick} dangerouslySetInnerHTML={data} />;
}
