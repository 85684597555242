import { IUserData } from "../users/users.types";
import { Request, useDelete, useGet, usePatch, usePost } from "./api";

export function useGetUser() {
  const getApi: Request = useGet();

  async function run(userId: string, orgId: string, token?: string) {
    if (token) getApi.setToken(token);
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_USERS}/${orgId}/users/${userId}`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useGetUsers() {
  const getApi: Request = useGet();

  async function run(orgId: string) {
    getApi.setEndpoint(`${process.env.REACT_APP_API_USERS}/${orgId}/users`);
    return getApi.run(getApi.getOptions());
  }
  return run;
}

export function useUpdateUser() {
  const patchApi: Request = usePatch();

  async function run(user: IUserData, orgId: string) {
    patchApi.setEndpoint(
      `${process.env.REACT_APP_API_USERS}/${orgId}/users/${user.id}`
    );
    patchApi.setBody(user);
    return patchApi.run(patchApi.getOptions());
  }
  return run;
}

export function useDeleteUser() {
  const deleteApi: Request = useDelete();

  async function run(userId: string, orgId: string) {
    deleteApi.setEndpoint(
      `${process.env.REACT_APP_API_USERS}/${orgId}/users/${userId}`
    );
    return deleteApi.run(deleteApi.getOptions());
  }
  return run;
}

export function useResetMFA() {
  const deleteApi: Request = useDelete();

  async function run(userId: string, orgId: string) {
    deleteApi.setEndpoint(
      `${process.env.REACT_APP_API_USERS}/${orgId}/users/${userId}/mfa`
    );
    return deleteApi.run(deleteApi.getOptions());
  }
  return run;
}

export function useResetPassword() {
  const postApi: Request = usePost();

  async function run(user_id: string, org_id: string) {
    const body = {
      user_id,
      org_id
    };
    postApi.setEndpoint(`${process.env.REACT_APP_API_RESET_PASSWORD}`);
    postApi.setBody(body);
    return postApi.run(postApi.getOptions());
  }
  return run;
}

export function useGetUsersCount() {
  const getApi: Request = useGet();

  async function run(orgId: string) {
    getApi.setEndpoint(
      `${process.env.REACT_APP_API_USERS}/${orgId}/users/count`
    );
    return getApi.run(getApi.getOptions());
  }
  return run;
}
