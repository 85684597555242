import React from "react";
import "./incidentActivityCommentsBar.scss";
import Icon from "../../components/icon/icon";

export default function IncidentActivityCommentsBar({ onDelete }: any) {
  const deleteIconURL = `${process.env.REACT_APP_CDN_IMAGES}/delete.svg`;

  return (
    <div className="activityCommentsBarContainer__delete">
      <Icon image={deleteIconURL} alt="expand/collapse" onClick={onDelete} />
    </div>
  );
}
