import alphabeticalOrderMapper from "./arrayMappers";
import {
  ISensor,
  ISensorsAvailableFilters,
  ISensorsFilters
} from "../contexts/types/sensors-list.types";

export const sensorsListMapper = (sensorsList: ISensor[]): ISensor[] =>
  sensorsList.map(
    ({
      sensor_id,
      type,
      os,
      os_id,
      organization,
      org_name,
      Hostname,
      internal_ip,
      external_ip,
      tags,
      status,
      installed,
      last_connection
    }) => ({
      id: sensor_id,
      type,
      os,
      os_id,
      organization,
      org_name,
      Hostname,
      internal_ip,
      external_ip,
      tags,
      status,
      installed,
      last_connection
    })
  );

export const sensorsListParametersMapper = (token: string) =>
  token ? `&token=${token}` : "";

export const sensorsFiltersMapper = (
  filters: ISensorsFilters
): ISensorsAvailableFilters => {
  const result: ISensorsAvailableFilters = {
    tags: {},
    status: {}
  };
  if (filters?.tags)
    alphabeticalOrderMapper(filters.tags, "name").forEach(
      ({ option, name }) => {
        if (option) result.tags[option] = name;
      }
    );
  if (filters?.status)
    filters.status
      .filter((item) => item.option)
      .forEach(({ option, name }) => {
        if (option) result.status[option] = name;
      });
  return result;
};

export default sensorsListMapper;
