import React, { useContext } from "react";
import "./sensorsListFilters.scss";
import Filter from "../../components/filter/filter";
import { VARIANT } from "../../constants/common.constants";
import Button from "../../components/button/button";
import SensorsListfiltersContext from "../../contexts/sensors-list-filters.context";

export default function SensorsListFilters() {
  const {
    currentFilters: { os, tags, status },
    availableFilters,
    availableOSs,
    toggleShowFilters,
    resetFilters,
    showFilters,
    setOSFilter,
    setTagsFilter,
    setStatusFilter
  } = useContext(SensorsListfiltersContext);

  const containerClassRoot = "sensorsListfilters__container";

  const containerClassName = `${containerClassRoot} ${containerClassRoot}${
    showFilters ? "--show" : "--hide"
  }`;

  return (
    <section className={containerClassName}>
      <div className="filters">
        <div className="valueFilters">
          <section className="osFilter">
            <Filter
              label="OS"
              value={os.caption}
              filters={availableOSs}
              onChange={(value: string) => setOSFilter(value)}
              clearOption
            />
          </section>
          <section className="tagsFilter">
            <Filter
              label="Tags"
              value={tags.caption}
              filters={availableFilters?.tags}
              onChange={(value: string) => setTagsFilter(value)}
              clearOption
            />
          </section>
          <section className="statusFilter">
            <Filter
              label="Status"
              value={status.caption}
              filters={availableFilters?.status}
              onChange={(value: string) => setStatusFilter(value)}
              clearOption
            />
          </section>
        </div>
      </div>
      <section className="filterActions">
        <Button variant={VARIANT.secondary} onClick={resetFilters}>
          Reset
        </Button>
        <Button variant={VARIANT.primary} onClick={toggleShowFilters}>
          Close
        </Button>
      </section>
    </section>
  );
}
