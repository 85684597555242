/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useContext, useMemo } from "react";
import "./organizations.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../components/header/header";
import ActionBar from "../components/actionBar/actionBar";
import Table from "../components/table/table";
import Menu from "../components/menu/menu";
import {
  ADD_ICON,
  DIVIDER_ICON,
  EDIT_ICON,
  MORE_ICON,
  ORGANIZATIONS_ICON,
  SEARCH_ICON,
  VARIANT,
  WIDTH
} from "../constants/common.constants";
import useDeleteOrganization from "./useDeleteOrganization";
import Permissions from "../permissions/permissions";
import Button from "../components/button/button";
import Input from "../components/input/input";
import Icon from "../components/icon/icon";
import useTableInstance from "../components/table/useTableInstance";
import ModalContext from "../contexts/modal.context";
import Tooltip, { tooltipTypes } from "../components/tooltip/tooltip";
import OrganizationsContext from "../contexts/organizations.context";
import { IOrganizationActionsProps } from "./organization.types";
import {
  NEW_ORGANIZATION_URL,
  ORGANIZATIONS_DETAILS_URL,
  ORGANIZATION_USERS_URL
} from "../constants/urls.constants";
import { buildUrl } from "../utils/string.utils";

function OrganizationsActions({
  row,
  onManageUsers,
  onDelete,
  onEdit
}: IOrganizationActionsProps) {
  const organization = useMemo(
    () => ({
      id: row.original.idm_id,
      name: row.original.name
    }),
    [row.original.idm_id, row.original.name]
  );

  const localUser = useSelector((state: any) => state.user.profile);

  const { Organizations: OrgPermissions } = Permissions();

  const showActions = useMemo(
    () => OrgPermissions.sections.list.canDelete(localUser.role),
    [localUser.role, OrgPermissions.sections.list]
  );

  const menuItems = useMemo(
    () => [
      { id: "manageUsers", value: "Manage users", hide: !showActions },
      { id: "delete", value: "Delete", hide: !showActions }
    ],
    [showActions]
  );

  const menuActions: { [key: string]: any } = useMemo(
    () => ({
      manageUsers: () => onManageUsers(organization),
      delete: () => onDelete(organization)
    }),
    [onManageUsers, onDelete, organization]
  );

  const onClickEdit = () => onEdit(organization.id);

  const onMenuItemSelected = (menuItem: string) => menuActions[menuItem]();

  return (
    <div className="organizationsActionsContainer">
      <div className="has-tooltip organizationsActionsContainer__edit">
        <Button variant={VARIANT.secondary} onClick={onClickEdit}>
          <img src={EDIT_ICON} alt="Edit" />
        </Button>
        <Tooltip type={tooltipTypes.info} text="Edit" top />
      </div>
      {showActions && (
        <img
          className="organizationsActionsContainer__divider"
          src={DIVIDER_ICON}
          alt=""
        />
      )}
      <section className="has-tooltip organizationsActionsContainer__more">
        <Menu items={menuItems} clickAction={onMenuItemSelected}>
          <>
            <Button variant={VARIANT.secondary}>
              <img
                className="organizationsActionsContainer__more__icon"
                src={MORE_ICON}
                alt="Menu"
              />
            </Button>
            <Tooltip type={tooltipTypes.info} text="More" top />
          </>
        </Menu>
      </section>
    </div>
  );
}

export default function Organizations() {
  const { showModal } = useContext(ModalContext);

  const { organizationsList, loading } = useContext(OrganizationsContext);

  const navigate = useNavigate();

  const localUser = useSelector((state: any) => state.user.profile);

  const { Organizations: OrgPermissions } = Permissions();

  const showAdd = OrgPermissions.sections.actionBar.canCreate(localUser.role);

  const buildCell = (row: any, value: string) => {
    const url = buildUrl(ORGANIZATIONS_DETAILS_URL, row.original.idm_id);
    return (
      <a target="_blank" href={url} rel="noreferrer" className="noDecoration">
        {value}
      </a>
    );
  };

  const onEdit = useCallback(
    (id: string) => {
      navigate(buildUrl(ORGANIZATIONS_DETAILS_URL, id));
    },
    [navigate]
  );

  const onManageUsers = useCallback(
    (organization: any) => {
      navigate(buildUrl(ORGANIZATION_USERS_URL, organization.id));
    },
    [navigate]
  );

  const onDelete = useDeleteOrganization();

  const deleteOrganization = useCallback(
    (organization: any) =>
      showModal({
        title: "Delete organization",
        clickAction: () => onDelete(organization),
        actionText: "Yes, delete",
        content: (
          <p>
            Are you sure you want to delete the organization {organization.name}
            ?
          </p>
        )
      }),
    [onDelete, showModal]
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the tableRows,
        visible: "true",
        Cell: ({ row, value }: any) => buildCell(row, value)
      },
      {
        Header: "Endpoints",
        accessor: "sensor_count",
        visible: "true",
        Cell: ({ row, value }: any) => buildCell(row, value)
      },
      {
        Header: "Actions",
        accessor: "actions",
        visible: "onDemand",
        Cell: ({ row }) => (
          <OrganizationsActions
            row={row}
            onEdit={onEdit}
            onManageUsers={onManageUsers}
            onDelete={deleteOrganization}
          />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableRows = useMemo(() => organizationsList, [organizationsList]);

  const table = useTableInstance(tableColumns, tableRows);

  const onSearchChange = (event: any) => {
    table.updateGlobalFilter(event.target.value);
  };

  const onCreate = () => navigate(buildUrl(NEW_ORGANIZATION_URL));

  const onMouseOver = () => true;

  const onMouseOut = () => false;

  const onClickCell = (rowIndex: number, columnId: string) =>
    columnId !== "actions"
      ? onEdit(table.instance.rows[rowIndex].original.idm_id)
      : true;

  return (
    <div className="organizationsContainer">
      <Header
        icon={<Icon image={ORGANIZATIONS_ICON} alt="Organizations" />}
        title="Organizations"
      />
      <section className="organizationsContainer__actionBar">
        <ActionBar>
          <>
            <Input
              icon={SEARCH_ICON}
              placeholder="Search"
              width={WIDTH.default}
              onChange={onSearchChange}
            />
            {showAdd && (
              <>
                <img
                  className="organizationsContainer__actionBar__actionBarContainer__divider"
                  src={DIVIDER_ICON}
                  alt=""
                />
                <Button onClick={onCreate} variant={VARIANT.primary}>
                  <img src={ADD_ICON} alt="Create" />
                </Button>
              </>
            )}
          </>
        </ActionBar>
      </section>
      <section className="organizationsContainer__list">
        <Table
          instance={table.instance}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClickCell={onClickCell}
          isLoadingData={loading}
          pagination
        />
      </section>
    </div>
  );
}
