import React from "react";
import "./button.scss";
import { VARIANT } from "../../constants/common.constants";
import Loader from "../loader/loader";

export type IProps = {
  variant?: string;
  formId?: string;
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  children?: any;
  text?: string;
  active?: boolean;
  iconColor?: boolean;
};

export default function Button(props: IProps) {
  const {
    variant = "primary",
    formId,
    onClick,
    disabled,
    loading,
    children,
    text,
    active,
    iconColor
  } = props;

  const className = `button button__${VARIANT[variant]}${
    iconColor ? " button--iconColor" : ""
  }`;

  const type = formId ? "submit" : "button";

  return (
    <button
      className={`${className}${active ? " active" : ""}${
        loading ? " loading" : ""
      }`}
      type={type}
      form={formId}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {text}
          {children}
        </>
      )}
    </button>
  );
}
