import React from "react";
import "./icon.scss";
import Avatar from "../avatar/avatar";

type IProps = {
  right?: boolean;
  onClick?: any;
  image?: string;
  alt?: string;
  disabled?: boolean;
  href?: string;
};

export default function Icon({
  right,
  onClick,
  image,
  alt,
  disabled,
  href
}: IProps) {
  const classRoot = "iconContainer";

  let className = classRoot;
  className += `${right ? ` ${classRoot}--right` : ""}${
    disabled ? " disabled" : ""
  }`;

  const onClickAction = (event: any) => !disabled && onClick && onClick(event);

  if (href) {
    return (
      <a target="_blank" href={href} rel="noreferrer">
        <img className={className} src={image} alt={alt} />
      </a>
    );
  }

  if (image) {
    return (
      <img
        className={className}
        src={image}
        alt={alt}
        onClick={onClickAction}
      />
    );
  }
  return <Avatar text={alt} />;
}
